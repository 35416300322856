import { useTranslation } from "../../../../common/customHooks";
import {
  Input,
  InputSelect,
  Label,
  SelectCheckBoxes,
  SelectSquares,
  TextArea,
  TextLink,
} from "../../../../components";
import { ICreateShipmentGeneralInformation } from "../../../../interfaces";

const CreateShipmentGeneralInformation = ({
  state,
  onChangeState,
  onAddSenderCustomer,
}: ICreateShipmentGeneralInformation) => {
  const { tr } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-12">
          <Input
            label={tr("myRefNumber")}
            placeholder="CS099_000019"
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, referenceNumber: value })
            }
            value={state.referenceNumber}
          />
          <SelectSquares
            className="mt-4 mb-3"
            label={tr("transportationMode")}
            isObligatoryLabel
            onSelect={() => {}}
            options={[{ title: "Air" }, { title: "Sea" }]}
            selectedIndex={0}
          />
          <SelectCheckBoxes
            label={tr("shipmentType")}
            isObligatoryLabel
            onSelect={() => {}}
            options={["Full unit loading", "Groupage loading"]}
            selectedIndex={0}
          />
        </div>
        <div className="col-md-6 col-12">
          <InputSelect
            options={[]}
            isMulti={false}
            onChange={() => {}}
            placeholder={tr("selectInterCom")}
            label={tr("intercom")}
            isObligatoryLabel
          />
          <div className="my-4">
            <div className="mb-1 row">
              <div className="col-md-6 col-12 ds-mb-xs-1">
                <Label text={tr("location")} isObligatory />
              </div>
              <div className="col-md-6 col-12">
                <div className="d-flex">
                  <TextLink
                    icon={
                      <i className="bi bi-plus-circle-fill ds-primary-color pb-2" />
                    }
                    isLink={false}
                    text={tr("addSenderCustomer")}
                    onClick={onAddSenderCustomer}
                  />
                </div>
              </div>
            </div>
            <InputSelect
              options={[]}
              isMulti={false}
              onChange={() => {}}
              placeholder={tr("selectLocation")}
            />
          </div>
          <TextArea
            label={tr("instruction")}
            isQuill
            onChange={(value) =>
              onChangeState({ ...state, instruction: value })
            }
            value={state.instruction}
          />
        </div>
      </div>
    </div>
  );
};
export default CreateShipmentGeneralInformation;
