import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../common/customHooks";
import { Button } from "../../components";

const NotFoundTemplate = () => {
  const { tr } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-100 h-100 text-center ds-not-found-layout">
      <div className="ds-not-found-404">404</div>
      <div style={{ lineHeight: "0px" }}>
        <div className="ds-not-found-oops">Ooops</div>
        <div className="ds-not-found-pnf">{tr("pageNotFound")}</div>
      </div>
      <Button
        className="ds-not-found-btn"
        onClick={() => navigate("/")}
        text={tr("goBack")}
      />
    </div>
  );
};

export default NotFoundTemplate;
