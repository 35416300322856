import { IColumnsDataMapping } from "../../../interfaces";

const ColumnsDataMapping = ({
  className,
  style,
  columns,
}: IColumnsDataMapping): JSX.Element => {
  return (
    <div style={style} className={`row ds-font-poppins ${className || ""}`}>
      {columns.map((column, index) => (
        <div key={index} className="col-6">
          {column.columnBodies.map((body, index) => (
            <div key={index} className="flex mb-1">
              <div style={{ minWidth: "12px" }}>{body.icon}</div>
              <div className="ms-1">
                <div style={{ fontSize: "12px" }} className="ds-text-color">
                  {body.title}
                </div>
                <div
                  style={{ fontSize: "12px" }}
                  className="font-bold ds-black-color"
                >
                  {body.response}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default ColumnsDataMapping;
