import { AxiosInstance, AxiosResponse } from "axios";
import Config from "../../common/config/Config";
import { getApiAxios } from "../../common/functions/GetApiAxios";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import { TokenEnum } from "../../enums";
import { ICommonPaginationAPI } from "../../interfaces";

class RequestAPI {
  private _api: AxiosInstance;

  constructor() {
    this._api = getApiAxios({
      baseUrl: Config.getInstance().API_URL + "/request",
      headers: handleHeaders(TokenEnum.accessToken),
      isRefreshToken: true,
    });
  }

  getRequestPackagesPagination(
    id: number,
    { pageNumber, perPage }: ICommonPaginationAPI
  ): Promise<AxiosResponse> {
    return this._api.get(
      `packages?id=${id}&pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  getRequestCancelsPagination({
    pageNumber,
    perPage,
    requestId,
  }: ICommonPaginationAPI & { requestId: number }): Promise<AxiosResponse> {
    return this._api.get(
      `get-cancels-pagination?pageNumber=${pageNumber}&perPage=${perPage}&id_shipment=${requestId}`
    );
  }

  getRequestFromTo(id: number, accessToken?: string): Promise<AxiosResponse> {
    return this._api.get(`from-to-location?id=${id}`, {
      headers: accessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined,
    });
  }

  getRequestsPagination({
    pageNumber,
    perPage,
  }: ICommonPaginationAPI): Promise<AxiosResponse> {
    return this._api.get(
      `get-pagination?pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  searchRequests(payload: {
    perPage: number;
    searchedValue: string;
  }): Promise<AxiosResponse> {
    return this._api.get(
      `search/?searchedValue=${payload.searchedValue}&perPage=${payload.perPage}`
    );
  }

  getRequestDocumentsPagination({
    perPage,
    pageNumber,
    id,
  }: ICommonPaginationAPI & { id: number }): Promise<AxiosResponse> {
    return this._api.get(
      `documents?id=${id}&pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  getRequestGeneralInfo(id: number): Promise<AxiosResponse> {
    return this._api.get(`general-info/${id}`);
  }

  getRequestFromToDetails(id: number): Promise<AxiosResponse> {
    return this._api.get(`from-to/${id}`);
  }

  getRequestServices(id: number): Promise<AxiosResponse> {
    return this._api.get(`get-request-services/${id}`);
  }

  getRequestById(id: number): Promise<AxiosResponse> {
    return this._api.get(`get-request-by-id/${id}`);
  }
}
export default RequestAPI;
