import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { IShipmetInfoModel } from "../../../interfaces";
import { useMemo } from "react";
import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";

const ShipmentDetailsTable = ({ state }: { state: IShipmetInfoModel }) => {
  const { departure, arrival } = state;
  const { tr } = useTranslation();

  const data = useMemo(
    () =>
      Object.values({
        departure,
        arrival,
      }).map(({ name, address, email, phone }: any, index) => ({
        index: [tr("departure"), tr("arrival")][index],
        name,
        address,
        email,
        phone,
      })),
    [state, tr]
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getShipmentInfoDataColums(tr),
    [tr]
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
  });

  return <MaterialReactTable table={table} />;
};
export default ShipmentDetailsTable;
