import { useEffect, useState } from "react";
import { HttpStatusCode } from "axios";
import { PrivateAppContext } from "../../contexts";
import { IPageProps, IPrivateAppDataProvider } from "../../interfaces";
import { INIT_PRIVATE_APP_DATA_STATE } from "../../consts";
import { useAuthentification } from "../../common/customHooks";
import { DataFetchAPI } from "../../common/data/DataFetchApi";
import { UserAPI } from "../../apis";

const PrivateAppProvider = ({ children }: IPageProps): JSX.Element => {
  const auth = useAuthentification();
  const userApi = new UserAPI();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [state, setState] = useState<IPrivateAppDataProvider | undefined>(
    INIT_PRIVATE_APP_DATA_STATE
  );

  useEffect(() => {
    handleFetchDataAPI();
  }, [auth.accessToken,auth.refreshToken,auth.userId]);

  const handleFetchDataAPI = async () => {
    try {
      const response = await userApi.getMyInfo();
      if (response.status === HttpStatusCode.Ok) {
        setState(DataFetchAPI.fetchPrivateAppData(response.data));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrivateAppContext.Provider value={{ isLoading, state }}>
      {children}
    </PrivateAppContext.Provider>
  );
};
export default PrivateAppProvider;
