import { Validation } from "../../common";
import { useTranslation } from "../../common/customHooks";
import { AuthForm, Input } from "../../components";
import { EAuthType, EInputTypes } from "../../enums";
import { IForgotPasswordTemplate } from "../../interfaces";
import { AuthLayout } from "../../layouts";

const ForgotPasswordTemplate = ({
  email,
  type,
  isLoading,
  onChangeEmail,
  onSubmit,
}: IForgotPasswordTemplate): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <AuthLayout type={type}>
      <AuthForm
        onSubmit={onSubmit}
        isDisabledSubmitButton={isLoading || !Validation.emailValidation(email)}
        type={type}
      >
        {type === EAuthType.forgotPassword && (
          <Input
            className="mt-4 mb-2"
            label={tr("emailAdress")}
            type={EInputTypes.email}
            placeholder={tr("enterYouEmail")}
            isObligatoryLabel
            isValidate
            onChange={(value) =>
              typeof value === "string" && onChangeEmail(value)
            }
            value={email}
          />
        )}
      </AuthForm>
    </AuthLayout>
  );
};
export default ForgotPasswordTemplate;
