import { useTranslation } from "../../../common/customHooks";
import { ISenderCustomerInfo } from "../../../interfaces";
import { TitleResponse } from "../../molecules";

const SenderCustomerInfo = ({
  className,
  style,
  ...props
}: ISenderCustomerInfo): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className={`${className || ""} w-100`}>
      <div className="mb-2">
        <div className="ds-rfq-from-to-si-title w-100 mb-1">
          {tr("senderInformation")}
        </div>
        <TitleResponse
          className="mb-1"
          title={tr("firstName")}
          response={props.firstName}
        />
        <TitleResponse
          className="mb-1"
          title={tr("lastName")}
          response={props.lastName}
        />
      </div>
      <div className="mb-2">
        <div className="ds-rfq-from-to-si-title w-100 mb-1">
          {tr("senderAdress")}
        </div>
        <TitleResponse
          className="mb-1"
          title={tr("adress")}
          response={props.adress}
        />
      </div>
      <div className="mb-2">
        <div className="ds-rfq-from-to-si-title w-100 mb-1">
          {tr("senderContact")}
        </div>
        <TitleResponse
          className="mb-1"
          title={tr("email")}
          response={props.email}
        />
        {props.phoneNumber && (
          <TitleResponse
            className="mb-1"
            title={tr("phone")}
            response={props.phoneNumber}
          />
        )}
      </div>
    </div>
  );
};
export default SenderCustomerInfo;
