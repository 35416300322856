import { AxiosInstance, AxiosResponse } from "axios";
import { getApiAxios } from "../../common/functions/GetApiAxios";
import { Config } from "../../common";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import { TokenEnum } from "../../enums";
import { ICommonPaginationAPI } from "../../interfaces";

class QuotationAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = getApiAxios({
      baseUrl: Config.getInstance().API_URL + "/quotation",
      headers: handleHeaders(TokenEnum.accessToken),
      isRefreshToken: true,
    });
  }

  getQuotationsPagination({
    pageNumber,
    perPage,
  }: ICommonPaginationAPI): Promise<AxiosResponse> {
    return this._api.get(
      `get-pagination?pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  searchQuotations(payload: {
    perPage: number;
    searchedValue: string;
  }): Promise<AxiosResponse> {
    return this._api.get(
      `search/?searchedValue=${payload.searchedValue}&perPage=${payload.perPage}`
    );
  }

  getQuotationInfo(id: number): Promise<AxiosResponse> {
    return this._api.get(`info/${id}`);
  }

}
export default QuotationAPI;
