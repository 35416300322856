import Avatar from "react-avatar";
import AvatarEditor from "react-avatar-edit";
import { useState } from "react";
import { Button, ClearSaveButtons } from "../../molecules";
import { IProfileManageAvatar } from "../../../interfaces";
import { PageTitle } from "../../atoms";
import { useDrawer, useTranslation } from "../../../common/customHooks";

const ProfileManageAvatar = ({
  className,
  style,
  src,
  name,
  onDelete,
  onChange,
  label,
}: IProfileManageAvatar): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const [preview, setPreview] = useState<string>("");
  const handleOpenDrawer = () => {
    drawer.open({
      title: tr("editAvatar"),
      children: (
        <div className="container-fluid">
          <div className="d-flex justify-center pt-5">
            <AvatarEditor
              width={390}
              height={295}
              onCrop={(preview) => setPreview(preview)}
              src={src}
              label={tr("chooseAvatarImage")}
            />
          </div>
        </div>
      ),
      options: {
        footer: (
          <ClearSaveButtons
            onSave={() => onChange(preview)}
            isClear
            onClear={() => {
              setPreview("");
              drawer.close();
            }}
          />
        ),
      },
    });
  };
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <PageTitle
          titleStyle={{ fontSize: "16px" }}
          className="mb-3"
          title={label}
        />
      )}
      <div className="ds-manage-profile-avatar">
        <div className="ds-manage-profile-avatar-avatar">
          <Avatar
            size="88"
            onClick={handleOpenDrawer}
            src={src}
            name={name}
            round
          />
        </div>
        <div className="w-100 ps-3">
          <div style={{ lineHeight: "80px" }} className="w-100">
            <Button
              className="ds-manage-profile-button-upload-profile-img"
              onClick={handleOpenDrawer}
              text={tr("uploadNewPicture")}
            />
            <span
              className="ps-4 ds-delete-profile-img cursor-pointer"
              onClick={onDelete}
            >
              {tr("delete")}
            </span>
            <div className="ds-image-support-text">{tr("weSupportImages")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileManageAvatar;
