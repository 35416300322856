import { useEffect, useState } from "react";
import { ShipmentListTemplate } from "../../templates";
import { IFieldOption, IShipmentListState } from "../../interfaces";
import { INIT_SHIPMENT_LIST_STATE } from "../../consts";
import { ShipmentAPI } from "../../apis";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../common/data/DataFetchApi";

const ShipmentList = () => {
  const shipmentApi = new ShipmentAPI();
  const [selectedShipmentId, setSelectedShipmentId] = useState<
    number | undefined
  >(undefined);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchFields, setSearchFields] = useState<Array<IFieldOption>>([]);
  const [state, setState] = useState<IShipmentListState>(
    INIT_SHIPMENT_LIST_STATE
  );

  useEffect(() => {
    handleFecthSearchDataAPI();
  }, [searchedValue]);

  useEffect(() => {
    if (state.shipmentItems.length > 0 && !selectedShipmentId) {
      setSelectedShipmentId(state.shipmentItems[0]?.id);
    }
  }, [state.shipmentItems]);

  useEffect(() => {
    handleFetchDataAPI();
  }, [state.pageNumber]);

  const handleFecthSearchDataAPI = async () => {
    try {
      const response = await shipmentApi.searchShipments({
        searchedValue,
        perPage: 10,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setSearchFields(
          data.map((item: any) => {
            const { id, shipment_number } = item;
            return { label: shipment_number, value: id };
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchDataAPI = async () => {
    try {
      const response = await shipmentApi.geShipmentsPagination({ ...state });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setState({
          ...state,
          paginationLength: data?.last_page,
          shipmentItems: state.shipmentItems?.concat(
            (data?.data as Array<any>)?.map((data) =>
              DataFetchAPI.fetchShipmentModel(data)
            )
          ),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ShipmentListTemplate
      selectedShipmentId={selectedShipmentId}
      state={state}
      searchFields={searchFields}
      searchedValue={searchedValue}
      onChangeState={setState}
      onSelectShipment={(id) => setSelectedShipmentId(id)}
      onChangeSearchedValue={(value) => setSearchedValue(value)}
    />
  );
};
export default ShipmentList;
