import { Navigate } from "react-router-dom";
import { useState } from "react";
import { ITextLink } from "../../../interfaces";

const TextLink = ({
  className,
  style,
  text,
  isLink,
  href,
  onClick,
  icon,
}: ITextLink): JSX.Element => {
  const [isNavigate, setIsNavigate] = useState(false);
  return (
    <div
      style={{ cursor: "pointer", ...style }}
      className={`${className || ""} ${icon && "d-flex"}`}
      onClick={() => {
        isLink ? setIsNavigate(true) : onClick && onClick();
      }}
    >
      {icon}
      <span className={`ds-text-link ${icon && "ps-2"}`}>{text}</span>
      {isNavigate && isLink && <Navigate to={href || "/"} />}
    </div>
  );
};
export default TextLink;
