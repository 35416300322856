import { Tab, Tabs } from "react-bootstrap";
import { useDrawer, useTranslation } from "../../common/customHooks";
import {
  Button,
  PageTitle,
  ShipmentItem,
  ShipmentTrackingDetails,
} from "../../components";
import { IShowShipmentTemplate } from "../../interfaces";
import { Data } from "../../common";
import { useState } from "react";
import {
  ShowShCancel,
  ShowShClaim,
  ShowShDocuments,
  ShowShPackage,
  ShowShService,
  ShowShShipments,
} from "./forms";
const ShowShipmentTemplate = (props: IShowShipmentTemplate) => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const { id, state } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-between mb-2">
        <PageTitle title={tr("showShipment")} />
        <Button
          className="ds-sl-button mx-2"
          icon={<i className="bi bi-geo-fill" />}
          onClick={() => {
            drawer.open({
              title: `${tr("referenceId")}: ${state?.refrenceId}`,
              children: <ShipmentTrackingDetails id={id!} />,
            });
          }}
          text={tr("tracking")}
        />
      </div>
      <div
        style={{ borderRadius: "10px", minHeight: "78vh", overflow: "hidden" }}
        className="ds-bg-white p-3"
      >
        {state && (
          <ShipmentItem
            className="mb-2"
            isDisabled
            isSelected={false}
            {...state}
          />
        )}
        <Tabs
          defaultActiveKey={selectedTabIndex}
          className="mb-2"
          onSelect={(key) => setSelectedTabIndex(parseInt(key!))}
        >
          {Data.getShowShipmentsTabsData(tr).map((tabName, index) => (
            <Tab key={index} eventKey={index} title={tabName} />
          ))}
        </Tabs>
        {
          [
            <ShowShShipments {...props} />,
            <ShowShPackage {...props} />,
            <ShowShService {...props} />,
            <ShowShDocuments {...props} />,
            <ShowShClaim
              {...props}
              isCanceledShipment={props.state?.isCanceled === true}
            />,
            <ShowShCancel
              {...props}
              isCanceledShipment={props.state?.isCanceled === true}
            />,
          ][selectedTabIndex]
        }
      </div>
    </div>
  );
};
export default ShowShipmentTemplate;
