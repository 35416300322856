import {
  useCreateSimulation,
  useTranslation,
} from "../../../../common/customHooks";
import {
  Button,
  Checkbox,
  CreateTemperature,
  DangerousCard,
  InputSelect,
  Label,
  ToggledCard,
} from "../../../../components";
import { ICreateShipmentService } from "../../../../interfaces";

const CreateShipmentService = (
  payload: ICreateShipmentService
): JSX.Element => {
  const { tr } = useTranslation();
  const { state, onChangeState, onAddDangerousGoods } = payload;
  const { temperature } = state;
  const constTemperature = {
    selectedTemp: temperature?.selectedTemp || "",
    minTemp: temperature?.minTemp || 0,
    maxTemp: temperature?.maxTemp || 0,
    isActiveCooling: temperature?.isActiveCooling || false,
    options: temperature?.options || [],
  };
  const createSimulation = useCreateSimulation();
  const firstServiceOptions = createSimulation.getFirstServiceOptions(payload);
  const secondServiceOptions =
    createSimulation.getSecondServiceOptions(payload);
  const additionalServices = createSimulation.getAdditionalService(payload);
  return (
    <div className="w-100 h-100">
      <div className="row">
        <div className="col-12 col-md-6 ds-mb-xs-3">
          <ToggledCard className="mb-2" title={tr("servicesOption")}>
            <div className="row">
              <div className="col-12 col-md-6">
                {firstServiceOptions.map((option, index) => (
                  <div key={index} className="w-100 mb-1">
                    <Checkbox
                      isChecked={option.isChecked}
                      onChange={option.onChange}
                      text={option.text}
                    />
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-6">
                {secondServiceOptions.map((option, index) => (
                  <div key={index} className="w-100 mb-1">
                    <Checkbox
                      isChecked={option.isChecked}
                      onChange={option.onChange}
                      text={option.text}
                    />
                  </div>
                ))}
              </div>
            </div>
          </ToggledCard>
          <ToggledCard className="mb-2" title={tr("additionalServices")}>
            <div className="row">
              <div className="col-12 col-md-6">
                <Label className="mb-2" isObligatory text={tr("origin")} />
                {additionalServices.origin.map((option, index) => (
                  <div key={index} className="w-100 mb-1">
                    <Checkbox
                      isChecked={option.isChecked}
                      onChange={option.onChange}
                      text={option.text}
                    />
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-6">
                <Label className="mb-2" isObligatory text={tr("destination")} />
                {additionalServices.destination.map((option, index) => (
                  <div key={index} className="w-100 mb-1">
                    <Checkbox
                      isChecked={option.isChecked}
                      onChange={option.onChange}
                      text={option.text}
                    />
                  </div>
                ))}
              </div>
            </div>
          </ToggledCard>
          <ToggledCard title={tr("addAdditionalService")}>
            <div className="row">
              <div className="col-12 col-md-4">
                <InputSelect
                  isMulti={false}
                  label={tr("category")}
                  isObligatoryLabel
                  options={state.categoryOptions}
                  onChange={(event: any) =>
                    onChangeState({ ...state, selectedCategory: event?.value })
                  }
                />
              </div>
              <div className="col-12 col-md-4">
                <InputSelect
                  isMulti={false}
                  label={tr("services")}
                  isObligatoryLabel
                  options={state.servicesOptions}
                  onChange={(event: any) =>
                    onChangeState({ ...state, selectedService: event?.value })
                  }
                />
              </div>
              <div style={{ lineHeight: "95px" }} className="col-12 col-md-3">
                <Button
                  style={{ height: "40px" }}
                  onClick={() => {}}
                  icon={<i className="bi bi-plus-lg ds-white-color" />}
                  text={tr("add")}
                />
              </div>
            </div>
          </ToggledCard>
        </div>
        <div className="col-12 col-md-6">
          <div className="w-100 mb-2">
            {state.isTemperatureControlled && (
              <ToggledCard title={tr("temperature")} isBlocked>
                <CreateTemperature
                  isActiveCooling={temperature?.isActiveCooling === true}
                  onChangeActiveCooling={(checked) =>
                    onChangeState({
                      ...state,
                      temperature: {
                        ...constTemperature,
                        isActiveCooling: checked,
                      },
                    })
                  }
                  minTemp={temperature?.minTemp || 0}
                  maxTemp={temperature?.maxTemp || 0}
                  onChangeMaxTemp={(value) =>
                    onChangeState({
                      ...state,
                      temperature: { ...constTemperature, maxTemp: value },
                    })
                  }
                  onChangeMinTemp={(value) =>
                    onChangeState({
                      ...state,
                      temperature: { ...constTemperature, minTemp: value },
                    })
                  }
                  onChangeTemp={(event) =>
                    onChangeState({
                      ...state,
                      temperature: {
                        ...constTemperature,
                        selectedTemp: event?.value,
                      },
                    })
                  }
                  temperatureOptions={constTemperature?.options}
                />
              </ToggledCard>
            )}
          </div>
          {state.isDangerousGoods && (
            <ToggledCard
              title={tr("dangerousGoods")}
              isAdd
              isBlocked
              onAdd={onAddDangerousGoods}
            >
              {state.dangerousGoods.map((value, index) => (
                <DangerousCard
                  key={index}
                  index={index}
                  numValue={value.dgUnNum}
                  onChangeNumValue={() => {}}
                  onChangeSelect={() => {}}
                  onDelete={() => {}}
                  selectOptions={state.dangerousGoodsOptions}
                  selectedValue={value.dgClass}
                />
              ))}
            </ToggledCard>
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateShipmentService;
