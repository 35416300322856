import { IDrawerHeader } from "../../../interfaces";
import { CancleIcon } from "../../icons";

const DrawerHeader = ({
  className,
  title,
  titleClassName,
  subTitle,
  onCancle,
  style,
}: IDrawerHeader): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <div
            className={`ds-drawer-header-title ${titleClassName || ""} ${
              subTitle && "mb-2"
            }`}
          >
            <span>{title}</span>
          </div>
          {subTitle && (
            <div className="ds-drawer-header-subTitle">
              <span>{subTitle}</span>
            </div>
          )}
        </div>
        <CancleIcon onCancle={onCancle} />
      </div>
      <hr className="ds-drawer-header-line" />
    </div>
  );
};
export default DrawerHeader;
