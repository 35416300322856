import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "../../../../common/customHooks";
import { SenderCustomerInfo, ToggledCard } from "../../../../components";
import { IRfqFromToState } from "../../../../interfaces";
import { Data } from "../../../../common";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import { RequestAPI } from "../../../../apis";
import { HttpStatusCode } from "axios";

const RequestFromTo = ({ id }: { id?: number }): JSX.Element => {
  const { tr } = useTranslation();
  const requestApi = new RequestAPI();
  const [state, setState] = useState<IRfqFromToState>();

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await requestApi.getRequestFromToDetails(id);
      if (response.status === HttpStatusCode.Ok) {
        const {
          sender,
          billTo,
          agent,
          receiver,
          delivery,
          readiness,
          pickup_deposit,
          boarding,
          delivery_date,
          pickup,
        } = response.data;
        console.log(response.data, "data");

        setState({
          ...state,
          sender: sender && DataFetchAPI.fetchDataFromToModel(sender),
          billTo: billTo && DataFetchAPI.fetchDataFromToModel(billTo),
          agent: agent && DataFetchAPI.fetchDataFromToModel(agent),
          originPickup: pickup && DataFetchAPI.fetchDataFromToModel(pickup),
          destinationDelivery:
            delivery && DataFetchAPI.fetchDataFromToModel(delivery),
          recipient: receiver && DataFetchAPI.fetchDataFromToModel(receiver),
          moreInfo: {
            readinessDate: new Date(readiness),
            boardingDate: new Date(boarding),
            deliveryDate: new Date(delivery_date),
            pickupDepositDate: new Date(pickup_deposit),
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard
      style={{ height: "95%" }}
      bodyStyle={{ height: "90%", overflow: "auto" }}
      title={tr("fromTo")}
      isBlocked
    >
      {Object.values({
        ...{
          sender: state?.sender,
          recipient: state?.recipient,
          originPickup: state?.originPickup,
          destinationDelivery: state?.destinationDelivery,
          agent: state?.agent,
        },
      }).map((data: any, index) => (
        <Fragment key={index}>
          {data && (
            <ToggledCard
              key={index}
              className="mb-2"
              title={Data.getRfqFromToToggledCardTitlesData(tr)[index]}
              {...data}
            >
              <SenderCustomerInfo {...data} />
            </ToggledCard>
          )}
        </Fragment>
      ))}
      <ToggledCard className="mb-2" title={tr("moreInformation")}>
        <div className="row">
          {Object.values({ ...state?.moreInfo }).map((date, index) => (
            <div key={index} className="col-md-3 col-12">
              <div className="mb-1 ds-rfq-from-to-si-title">
                {Data.getRfqFromToMoreInfoTitlesData(tr)[index]}
              </div>
              <div>{date !== null && `${Data.getFormdatedDate(date)} | ${Data.getTimeStringFromDate(date)}`} </div>
            </div>
          ))}
        </div>
      </ToggledCard>
    </ToggledCard>
  );
};
export default RequestFromTo;
