import { useEffect, useState } from "react";
import { QuotationAPI } from "../../apis";
import { QuotationListTemplate } from "../../templates";
import { IQuotationListState } from "../../interfaces";
import { INIT_QUOTATION_LIST_STATE } from "../../consts";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../common/data/DataFetchApi";

const QuotationList = (): JSX.Element => {
  const quotationApi = new QuotationAPI();
  const [state, setState] = useState<IQuotationListState>(
    INIT_QUOTATION_LIST_STATE
  );

  useEffect(() => {
    handleFetchDataAPI();
  }, [state.pageNumber]);

  const handleFetchDataAPI = async () => {
    try {
      const response = await quotationApi.getQuotationsPagination({ ...state });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setState({
          ...state,
          paginationLength: data?.last_page,
          quotations: (data?.data as Array<any>).map((data) =>
            DataFetchAPI.fetchQuotationItemModel(data)
          ),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return <QuotationListTemplate state={state} />;
};
export default QuotationList;
