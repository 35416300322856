import { useTranslation } from "../../../../../../common/customHooks";
import { DatePicker, Input, TitleResponse } from "../../../../../../components";
import { EInputTypes } from "../../../../../../enums";
import { ICreateQuotationTemplate } from "../../../../../../interfaces";

const CqCustomerInformation = ({
  state,
  onChangeState,
}: ICreateQuotationTemplate): JSX.Element => {
  const {
    depositDate,
    pickupEstimatedDate,
    quotationDate,
    quotationValidaty,
    transiteTime,
  } = state.customerInfo;
  const { tr } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <div className="mb-2">
          {/* <TitleResponse
            title={tr("quotationDate")}
            childrenResponse={
              <DatePicker
                onChange={(value) =>
                  (value instanceof Date || value === null) &&
                  onChangeState({
                    ...state,
                    customerInfo: {
                      ...state.customerInfo,
                      quotationDate: value,
                    },
                  })
                }
                value={quotationDate}
              />
            }
          /> */}
        </div>
        <div className="mb-2">
          {/* <TitleResponse
            title={tr("quotationValidaty")}
            childrenResponse={
              <DatePicker
                onChange={(value) =>
                  (value instanceof Date || value === null) &&
                  onChangeState({
                    ...state,
                    customerInfo: {
                      ...state.customerInfo,
                      quotationValidaty: value,
                    },
                  })
                }
                value={quotationValidaty}
              />
            }
          /> */}
        </div>
        {/* <TitleResponse
          title={tr("transitTime")}
          childrenResponse={
            <Input
              type={EInputTypes.number}
              onChange={(value) =>
                typeof value === "number" &&
                onChangeState({
                  ...state,
                  customerInfo: {
                    ...state.customerInfo,
                    transiteTime: value,
                  },
                })
              }
              value={transiteTime}
            />
          }
        /> */}
      </div>
      <div className="col-md-6 col-12">
        <div className="mb-2">
          {/* <TitleResponse
            title={tr("pickupEstimatedDate")}
            childrenResponse={
              <DatePicker
                onChange={(value) =>
                  (value instanceof Date || value === null) &&
                  onChangeState({
                    ...state,
                    customerInfo: {
                      ...state.customerInfo,
                      pickupEstimatedDate: value,
                    },
                  })
                }
                value={pickupEstimatedDate}
              />
            }
          /> */}
        </div>
        {/* <TitleResponse
          title={tr("depositDate")}
          childrenResponse={
            <DatePicker
              onChange={(value) =>
                (value instanceof Date || value === null) &&
                onChangeState({
                  ...state,
                  customerInfo: {
                    ...state.customerInfo,
                    depositDate: value,
                  },
                })
              }
              value={depositDate}
            />
          }
        /> */}
      </div>
    </div>
  );
};
export default CqCustomerInformation;
