import { ICommonComponent } from "../../../interfaces";

const UperArrowIcon = ({ className, style }: ICommonComponent): JSX.Element => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle cx="7" cy="7" r="7" fill="#45D6BE" fillOpacity="0.2" />
      <path
        d="M7.49951 3.703C7.435 3.64003 7.35894 3.59012 7.27551 3.556C7.18918 3.51922 7.09634 3.50018 7.00251 3.5H6.93251C6.86758 3.5032 6.80367 3.5174 6.74351 3.542C6.6955 3.56072 6.65064 3.58669 6.61051 3.619C6.57856 3.63757 6.54813 3.65863 6.51951 3.682L4.41951 5.684C4.28491 5.8121 4.20671 5.98842 4.20212 6.17418C4.19752 6.35993 4.26691 6.5399 4.39501 6.6745C4.52311 6.8091 4.69943 6.88729 4.88518 6.89189C5.07094 6.89648 5.25091 6.8271 5.38551 6.699L6.30251 5.838V9.8C6.30251 9.98565 6.37626 10.1637 6.50753 10.295C6.63881 10.4262 6.81686 10.5 7.00251 10.5C7.18816 10.5 7.36621 10.4262 7.49748 10.295C7.62876 10.1637 7.70251 9.98565 7.70251 9.8V5.887L8.60551 6.797C8.67058 6.86261 8.748 6.91469 8.8333 6.95022C8.9186 6.98576 9.0101 7.00406 9.10251 7.00406C9.19491 7.00406 9.28641 6.98576 9.37171 6.95022C9.45701 6.91469 9.53443 6.86261 9.59951 6.797C9.66512 6.73193 9.71719 6.65451 9.75273 6.5692C9.78827 6.4839 9.80656 6.39241 9.80656 6.3C9.80656 6.20759 9.78827 6.1161 9.75273 6.0308C9.71719 5.94549 9.66512 5.86807 9.59951 5.803L7.49951 3.703Z"
        fill="#3AB4A0"
      />
    </svg>
  );
};
export default UperArrowIcon;
