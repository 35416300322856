import { IStatisticalCard } from "../../../interfaces";
import { LowerArrowIcon, UperArrowIcon } from "../../icons";
import CountUp from "react-countup";

const StatisticalCard = ({
  className,
  style,
  icon,
  statisticalNumber,
  statisticalName,
  percentage,
  type,
  isCurrency,
}: IStatisticalCard): JSX.Element => {
  return (
    <div
      style={style}
      className={`w-100 ds-statistical-card-layout py-2 ${className || ""}`}
    >
      <div className="row">
        <div className="col-3">
          <div
            style={{ fontSize: "33px" }}
            className="d-flex justify-center ps-3 ds-grey-blue-color"
          >
            {icon}
          </div>
        </div>
        <div className="col-9">
          <div className="d-flex">
            <div
              className="font-bold ds-black-color"
              style={{ fontSize: "20px" }}
            >
              {isCurrency && <span>$</span>}
              <CountUp end={statisticalNumber} />
            </div>
            <div
              className={`ds-statistical-percentage ps-2 d-flex ${
                type === "uper" ? "ds-primary-color" : "ds-warning-color"
              }`}
            >
              <div className="mt-2">
                {type === "uper" ? <UperArrowIcon /> : <LowerArrowIcon />}
              </div>
              <div className="mx-1">
                <CountUp end={percentage} />%
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="ds-statistical-name">{statisticalName}</div>
            <i
              style={{ fontSize: "10px" }}
              className="bi bi-info-circle ps-2 pt-1 ds-primary-color"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatisticalCard;
