import { useState } from "react";
import { TrackingTemplate } from "../../templates";

const Tracking = (): JSX.Element => {
  const [searchedTrackingNumber, setSearchedTrackingNumber] =
    useState<string>("");
  return (
    <TrackingTemplate
      searchedTrackingNumber={searchedTrackingNumber}
      onChangeSearchedTrackingNumber={(value) =>
        setSearchedTrackingNumber(value)
      }
    />
  );
};
export default Tracking;
