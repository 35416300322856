import { IDeleteIcon } from "../../../interfaces";

const DeleteIcon = ({ className, onDelete }: IDeleteIcon): JSX.Element => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      className={className}
      onClick={onDelete}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="delete-svgrepo-com 1">
        <path
          id="Vector"
          d="M3.83634 13.5219L2.66634 5.33325H13.333L12.163 13.5219C12.1176 13.8396 11.9591 14.1303 11.7166 14.3406C11.4741 14.5509 11.164 14.6666 10.843 14.6666H5.15634C4.83539 14.6666 4.5252 14.5509 4.28273 14.3406C4.04025 14.1303 3.88176 13.8396 3.83634 13.5219ZM13.9997 2.66659H10.6663V1.99992C10.6663 1.82311 10.5961 1.65354 10.4711 1.52851C10.3461 1.40349 10.1765 1.33325 9.99967 1.33325H5.99967C5.82286 1.33325 5.65329 1.40349 5.52827 1.52851C5.40325 1.65354 5.33301 1.82311 5.33301 1.99992V2.66659H1.99967C1.82286 2.66659 1.65329 2.73682 1.52827 2.86185C1.40325 2.98687 1.33301 3.15644 1.33301 3.33325C1.33301 3.51006 1.40325 3.67963 1.52827 3.80466C1.65329 3.92968 1.82286 3.99992 1.99967 3.99992H13.9997C14.1765 3.99992 14.3461 3.92968 14.4711 3.80466C14.5961 3.67963 14.6663 3.51006 14.6663 3.33325C14.6663 3.15644 14.5961 2.98687 14.4711 2.86185C14.3461 2.73682 14.1765 2.66659 13.9997 2.66659Z"
          fill="#849BB3"
        />
      </g>
    </svg>
  );
};
export default DeleteIcon;
