const GoogleIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M17.6938 9.69119C17.6938 9.12594 17.6474 8.55831 17.5501 8.00256H9.66507V11.2029H14.1799C14.0868 11.715 13.8907 12.2029 13.6035 12.6371C13.3163 13.0712 12.944 13.4426 12.5091 13.7287V15.8068H15.2036C16.7853 14.3581 17.6938 12.2206 17.6938 9.69119Z"
        fill="#4285F4"
      />
      <path
        d="M9.66624 17.8125C11.9201 17.8125 13.8213 17.0763 15.2059 15.8056L12.5127 13.7287C11.7634 14.2358 10.7956 14.5231 9.66862 14.5231C7.48837 14.5231 5.63943 13.0601 4.97562 11.0936H2.19687V13.2347C2.89702 14.6136 3.96575 15.7715 5.28433 16.5796C6.60292 17.3878 8.11972 17.8145 9.66624 17.8125Z"
        fill="#34A853"
      />
      <path
        d="M4.97206 11.0936C4.62188 10.0617 4.62188 8.94308 4.97206 7.91114V5.77008H2.19568C1.61101 6.92736 1.3064 8.20581 1.3064 9.50239C1.3064 10.799 1.61101 12.0774 2.19568 13.2347L4.97206 11.0936Z"
        fill="#FBBC04"
      />
      <path
        d="M9.66624 4.47686C10.8569 4.45756 12.0077 4.90512 12.8725 5.72374L15.2594 3.34993C13.7424 1.93687 11.7392 1.16282 9.66624 1.18868C8.11929 1.18672 6.60212 1.6139 5.28345 2.42271C3.96478 3.23152 2.89631 4.39026 2.19687 5.77005L4.97087 7.90874C5.63112 5.93986 7.48362 4.47686 9.66387 4.47686H9.66624Z"
        fill="#EA4335"
      />
    </svg>
  );
};
export default GoogleIcon;
