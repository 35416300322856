import Drawer from "react-modern-drawer";
import { IDrawer } from "../../../interfaces";
import { DrawerHeader } from "../../molecules";

const CustomDrawer = ({
  className,
  style,
  isOpen,
  onClose,
  direction,
  isEnableOverlay,
  headerTitle,
  headerSubTitle,
  children,
  size,
  footer,
}: IDrawer): JSX.Element => {
  const windowWidth = window.innerWidth;
  const commonSize =
    windowWidth <= 768 ? "auto" : windowWidth <= 992 ? "65%" : "50%";
  const commonDirection = windowWidth <= 768 ? "bottom" : "right";
  return (
    <Drawer
      className={className}
      style={style}
      open={isOpen}
      onClose={onClose}
      direction={direction || commonDirection}
      enableOverlay={isEnableOverlay}
      size={size || commonSize}
    >
      <div className="ds-drawer">
        <DrawerHeader
          onCancle={onClose}
          title={headerTitle}
          subTitle={headerSubTitle}
        />
        <div
          className={`container-fluid py-3 ds-drawer-layout w-100 h-100 ${
            footer
              ? "ds-drawer-with-footer-layout"
              : "ds-drawer-without-footer-layout"
          }`}
          style={{ overflow: "auto" }}
        >
          {children}
        </div>
        {footer && <div style={{ maxHeight: "15vh" }}>{footer}</div>}
      </div>
    </Drawer>
  );
};
export default CustomDrawer;
