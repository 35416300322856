const WordIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M8.16079 0L2.2041 6.28944V23H20.7951V0H8.16079ZM7.63467 2.6445V5.4313H4.99601L7.63467 2.6445ZM19.3576 21.5632H3.64165V6.86884H9.07294V1.43755H19.3576V21.5632Z"
        fill="#849BB3"
      />
      <path
        d="M9.851 11.0997C9.94368 11.1332 10.0247 11.1807 10.0955 11.2434C10.1699 11.3127 10.2385 11.3989 10.2983 11.4996C10.3676 11.6163 10.4355 11.7513 10.4939 11.8878L10.9463 13.175L9.72039 16.6981L8.18287 12.3176C8.08436 12.0461 8.01138 11.8221 7.96687 11.6462C7.90922 11.4273 7.90485 11.3485 7.90485 11.3288C7.90485 11.2544 7.93112 11.1989 7.99752 11.142C8.06757 11.08 8.19527 11.0479 8.36676 11.0479H8.79729V10.2605H4.88965V11.0479H5.15964C5.37856 11.0479 5.55223 11.0792 5.66169 11.1391C5.76677 11.1967 5.84923 11.2836 5.91417 11.4069C5.94263 11.4594 6.02947 11.6718 6.28049 12.3701L9.04904 20.1051H9.67076L11.5418 14.8249L13.4237 20.1051H14.0739L16.6746 12.5868C16.9096 11.8703 17.0241 11.6076 17.0752 11.5149C17.1307 11.4156 17.1883 11.3339 17.2445 11.2697C17.2919 11.2157 17.3445 11.1712 17.397 11.1391C17.451 11.107 17.5094 11.0843 17.5751 11.0698C17.6495 11.0544 17.7393 11.0464 17.8385 11.0464H18.1085V10.259H14.8532V11.0464H15.1218C15.3786 11.0464 15.5844 11.0814 15.7347 11.15C15.8252 11.1923 15.858 11.2427 15.858 11.3478C15.858 11.4463 15.8208 11.7148 15.5786 12.4146L14.0987 16.6966L12.6137 12.4796C12.3751 11.8133 12.3262 11.5112 12.3262 11.374C12.3262 11.2281 12.3729 11.1668 12.4101 11.1332C12.4437 11.1004 12.5371 11.0479 12.7808 11.0479H13.2916V10.2605H9.20885V11.0479H9.47812C9.62771 11.0493 9.75468 11.0654 9.851 11.0997Z"
        fill="#849BB3"
      />
    </svg>
  );
};
export default WordIcon;
