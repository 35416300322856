import { ICheckbox } from "../../../interfaces";
import { Label } from "../../atoms";

const Checkbox = ({
  className,
  labelStyle,
  style,
  label,
  isObligatoryLabel,
  isChecked,
  text,
  checkboxStyle,
  onChange,
}: ICheckbox): JSX.Element => {
  return (
    <div style={style} className={className}>
      {label && (
        <Label
          className="mb-2"
          style={labelStyle}
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      <div className="d-flex w-100 h-100">
        <input
          className="ds-checkbox mt-1"
          style={{ cursor: "pointer", ...checkboxStyle }}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
        />
        {text && (
          <div className="ps-2">
            <span className="ds-checkbox-text">{text}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default Checkbox;
