import { useTranslation } from "../../../common/customHooks";
import { IClearSaveButtons } from "../../../interfaces";

const ClearSaveButtons = ({
  isClear,
  onSave,
  onClear,
  clearText,
  saveText,
  isDisabled,
  saveBtnClassName,
  clearBtnClassName,
  style,
}: IClearSaveButtons): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className="d-flex justify-center md:justify-end w-100 py-2">
      <div className="row">
        {isClear && (
          <div className="col-6">
            <button
              className={`ds-clear-button ${clearBtnClassName || ""}`}
              onClick={onClear}
            >
              {clearText || tr("clear")}
            </button>
          </div>
        )}
        <div className={isClear ? "col-6" : "col-12"}>
          <button
            className={`ds-save-button ${saveBtnClassName || ""} ${
              isDisabled ? "ds-button-disabled " : ""
            }`}
            onClick={onSave}
            disabled={isDisabled}
          >
            {saveText || tr("save")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClearSaveButtons;
