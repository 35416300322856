import { useContext } from "react";
import { JoyrideContext } from "../../../contexts";

const useJoyride = () => {
  const context = useContext(JoyrideContext);
  if (!context) {
    throw new Error("useJoyride must be used within a JoyrideProvider");
  }
  return context;
};
export default useJoyride;
