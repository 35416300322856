import React from "react";

const CustomToggledDropItem = React.forwardRef<
  HTMLDivElement,
  {
    children?: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
    item: React.ReactNode;
    isRounded?: boolean;
  }
>(({ children, onClick, item }, ref) => (
  <div className="cursor-pointer" ref={ref} onClick={onClick}>
    {item}
    {children}
  </div>
));
export default CustomToggledDropItem;
