import {
  InputPhoneNumber,
  ProfileManageAvatar,
  Input,
  InputSelect,
  Button,
} from "../../../../../../components";
import { IMofGeneral } from "../../../../../../interfaces";
import { EInputTypes } from "../../../../../../enums";
import { useTranslation } from "../../../../../../common/customHooks";

const MofGeneralCompany = ({
  state,
  onChangeState,
  onDeleteCompanyImage,
  onChangeCompanyImage,
  onUpdateCompany,
}: IMofGeneral): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 h-100">
      <ProfileManageAvatar
        className="mb-3"
        label={tr("companyPicture")}
        name={state.legalName}
        onChange={onChangeCompanyImage}
        onDelete={onDeleteCompanyImage}
      />
      <hr className="mb-3" />
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyLegalName")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, legalName: value })
            }
            value={state.legalName}
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyCommercialName")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, commercialName: value })
            }
            value={state.commercialName}
            isValidate
          />
        </div>
      </div>
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyForm")}
            isObligatoryLabel
            value={state.form}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, form: value })
            }
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyCapital")}
            isObligatoryLabel
            value={state.capital}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, capital: value })
            }
            isValidate
          />
        </div>
      </div>
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyRegistrationNumber")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, registrationNumber: value })
            }
            value={state.registrationNumber}
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyRegister")}
            isObligatoryLabel
            value={state.register}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, register: value })
            }
            isValidate
          />
        </div>
      </div>
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyVatCode")}
            isObligatoryLabel
            value={state.vatCode}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, vatCode: value })
            }
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyeoriCode")}
            isObligatoryLabel
            value={state.eoriCode}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, eoriCode: value })
            }
            isValidate
          />
        </div>
      </div>
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            label={tr("companyActivityCode")}
            isObligatoryLabel
            value={state.activityCode}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, activityCode: value })
            }
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <InputSelect
            label={tr("companyCountry")}
            placeholder={tr("selectCountry")}
            isObligatoryLabel
            isMulti={false}
            onChange={(event: any) =>
              onChangeState({ ...state, selectedCountry: event?.value })
            }
            options={state.companyCountryOptions}
            isValidate
          />
        </div>
      </div>
      <div className="row ds-mb-md-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <Input
            type={EInputTypes.email}
            label={tr("companyEmail")}
            isObligatoryLabel
            value={state.email}
            placeholder={tr("exampleEmail")}
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, email: value })
            }
            isValidate
          />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <InputPhoneNumber
            label={tr("companyPhone")}
            isObligatoryLabel
            value={state.phoneNumber}
            onChange={(value) =>
              onChangeState({ ...state, phoneNumber: value })
            }
          />
        </div>
      </div>
      <Button
        className="mb-3"
        onClick={onUpdateCompany}
        text={tr("saveChange")}
      />
    </div>
  );
};
export default MofGeneralCompany;
