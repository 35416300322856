import { ITextInfo } from "../../../interfaces";

const TextInfo = ({ title, className, style }: ITextInfo): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} ds-text-info`}>
      <span className="ds-text-info-label">{title}</span>
    </div>
  );
};
export default TextInfo;
