import { useNavigate } from "react-router-dom";
import { Button, Card, Input } from "../../components";
import { TrackingLayout } from "../../layouts";
import { useTranslation } from "../../common/customHooks";
import { frameImg } from "../../assets";
import { ITrackingTemplate } from "../../interfaces";

const TrackingTemplate = ({
  searchedTrackingNumber,
  onChangeSearchedTrackingNumber,
}: ITrackingTemplate): JSX.Element => {
  const navigate = useNavigate();
  const { tr } = useTranslation();
  return (
    <TrackingLayout>
      <Card
        header={
          <Button
            className="ds-bg-grey ds-black-color ms-3"
            icon={<i className="bi bi-arrow-left ds-primary-color" />}
            style={{ height: "30px", width: "90px" }}
            text={tr("back")}
            onClick={() => navigate("/")}
          />
        }
        headerClassName="py-3"
        bodyClassName="pb-5"
      >
        <div className="row px-3">
          <div className="col-md-6 col-12 ds-font-poppins p-5">
            <div className="ds-tracking-title mb-1">
              {tr("trackingAndTracing")}
            </div>
            <div className="ds-tracking-subtitle mb-2">
              {tr("statusOfYourShipments")}
            </div>
            <div className="ds-tracking-button-input-container">
              <Input
                placeholder={tr("enterYourShipmentNumber")}
                onChange={(value) =>
                  onChangeSearchedTrackingNumber(value.toString().toUpperCase())
                }
                value={searchedTrackingNumber}
              />
              <div className="ds-tracking-button-container">
                <Button
                  className="ds-tracking-button"
                  style={{ height: "40px", borderRadius: "40px" }}
                  text={tr("trackNow")}
                  onClick={() =>
                    navigate(`/tracking/${searchedTrackingNumber}`)
                  }
                  isDisabled={searchedTrackingNumber === ""}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="d-flex justify-center">
              <img alt="world-img" src={frameImg} />
            </div>
          </div>
        </div>
      </Card>
    </TrackingLayout>
  );
};
export default TrackingTemplate;
