import { AxiosInstance, AxiosResponse } from "axios";
import Config from "../../common/config/Config";
import { getApiAxios } from "../../common/functions/GetApiAxios";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import { TokenEnum } from "../../enums";
import { ICommonPaginationAPI, IManageClaimCancelState } from "../../interfaces";

class ShipmentAPI {
  private _api: AxiosInstance;

  constructor() {
    this._api = getApiAxios({
      baseUrl: Config.getInstance().API_URL + "/shipment",
      headers: handleHeaders(TokenEnum.accessToken),
      isRefreshToken: true,
    });
  }

  getShipmentPackagesPagination(
    id: number,
    { pageNumber, perPage }: ICommonPaginationAPI
  ): Promise<AxiosResponse> {
    return this._api.get(
      `packages?id=${id}&pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  getShipmentFromTo(id: number, accessToken?: string): Promise<AxiosResponse> {
    return this._api.get(`from-to?id=${id}`, {
      headers: accessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined,
    });
  }

  geShipmentsPagination({
    pageNumber,
    perPage,
  }: ICommonPaginationAPI): Promise<AxiosResponse> {
    return this._api.get(
      `get-pagination?pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  getShipmentById(id: number): Promise<AxiosResponse> {
    return this._api.get(`get-shipment-by-id/${id}`);
  }

  getShipmentInfo(id: number): Promise<AxiosResponse> {
    return this._api.get(`detail/${id}`);
  }

  getShipmentDocumentsPagination({
    perPage,
    pageNumber,
    id,
  }: ICommonPaginationAPI & { id: number }): Promise<AxiosResponse> {
    return this._api.get(
      `documents?id=${id}&pageNumber=${pageNumber}&perPage=${perPage}`
    );
  }

  getShipmentServices(id: number): Promise<AxiosResponse> {
    return this._api.get(`get-shipment-services/${id}`);
  }

  searchShipments(payload: {
    perPage: number;
    searchedValue: string;
  }): Promise<AxiosResponse> {
    return this._api.get(
      `search/?searchedValue=${payload.searchedValue}&perPage=${payload.perPage}`
    );
  }

  getShipmentTracking(id: number): Promise<AxiosResponse> {
    return this._api.get(`tracking/${id}`);
  }

  getShipmentClaimsPagination({
    pageNumber,
    perPage,
    id,
  }: ICommonPaginationAPI & { id: number }): Promise<AxiosResponse> {
    return this._api.get(
      `get-claims-pagination?pageNumber=${pageNumber}&perPage=${perPage}&id_shipment=${id}`
    );
  }

  createShipmentClaim({
    reasonId,
    requestedAmount,
    details,
    request,
    attachedFile,
    incidentDateHour,
    id,
  }: IManageClaimCancelState & { id: number }): Promise<AxiosResponse> {
    const formData = new FormData();
    if (attachedFile) {
      formData.append("file", attachedFile);
    }
    formData.append("reason", reasonId!.toString());
    formData.append("claim_amount_requested", requestedAmount!.toString());
    formData.append("claim_description", details!);
    formData.append("claim_request", request!);
    formData.append("id_shipment", id?.toString());
    formData.append(
      "date_incident",
      incidentDateHour!.toISOString().split("T")[0]
    );

    return this._api.post("add-claim", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  getShipmentCancelsPagination({
    pageNumber,
    perPage,
    shipmentId,
  }: ICommonPaginationAPI & { shipmentId: number }): Promise<AxiosResponse> {
    return this._api.get(
      `get-cancels-pagination?pageNumber=${pageNumber}&perPage=${perPage}&id_shipment=${shipmentId}`
    );
  }

  createShipmentCancel({
    details,
    reasonId,
    attachedFile,
    id,
    requestedAmount,
  }: IManageClaimCancelState & { id: number }): Promise<AxiosResponse> {
    const formData = new FormData();
    if (attachedFile) {
      formData.append("file", attachedFile);
    }
    formData.append("id_shipment", id?.toString());
    formData.append("cancel_raison", reasonId!.toString());
    formData.append("cancel_amount_requested", requestedAmount!.toString());
    formData.append("cancel_description", details!);
    return this._api.post(`add-cancel`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
export default ShipmentAPI;
