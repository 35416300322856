import { IPageTitle } from "../../../interfaces";

const PageTitle = ({
  className,
  titleClassName,
  subTitleClassName,
  title,
  subTitle,
  isCentered,
  titleStyle,
  subTitleStyle,
  style,
}: IPageTitle): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      <div
        className={`w-100 ${
          isCentered && " d-flex justify-center text-center"
        } `}
      >
        <span
          style={titleStyle}
          className={`${titleClassName || ""} ds-page-title`}
        >
          {title}
        </span>
      </div>
      {subTitle && (
        <div
          className={`w-100 mt-1 ${
            isCentered && " d-flex justify-center text-center"
          } `}
        >
          <span
            style={subTitleStyle}
            className={`${subTitleClassName || ""} ds-page-sub-title`}
          >
            {subTitle}
          </span>
        </div>
      )}
    </div>
  );
};

export default PageTitle;
