enum EInputTypes {
  text = "text",
  password = "password",
  number = "number",
  email = "email",
}
enum EButtonType {
  primary = "primary",
  secondary = "secondary",
}
enum ESidebarIcons {
  dashboard = "dashboard",
  settings = "settings",
  shipment = "shipment",
}
export { EInputTypes, EButtonType, ESidebarIcons };
