const AppleIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1102 2.71394C11.73 1.9957 12.1477 0.995333 12.0334 0C11.1403 0.034 10.0599 0.570475 9.41958 1.28787C8.84456 1.92451 8.34261 2.94176 8.47755 3.91755C9.47373 3.9915 10.4905 3.43302 11.1102 2.71394ZM13.3441 9.03125C13.3691 11.6042 15.6992 12.46 15.725 12.4711C15.7061 12.5314 15.3528 13.6906 14.4976 14.8891C13.7576 15.9244 12.99 16.9555 11.7807 16.9776C10.5928 16.9988 10.2103 16.3027 8.85144 16.3027C7.4934 16.3027 7.0688 16.9554 5.94455 16.9988C4.77733 17.0404 3.88773 15.8787 3.14253 14.8468C1.61774 12.7363 0.453099 8.88257 2.01742 6.28162C2.79442 4.99048 4.18254 4.17162 5.69013 4.15122C6.83587 4.12997 7.918 4.88999 8.61851 4.88999C9.31902 4.88999 10.6341 3.97625 12.0162 4.11055C12.5946 4.1335 14.2191 4.33403 15.2617 5.79685C15.1775 5.847 13.3235 6.88078 13.3441 9.03125Z"
        fill="black"
      />
    </svg>
  );
};
export default AppleIcon;
