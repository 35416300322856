import PublicRoute from "./publicRoute/PublicRoute";
import PrivateRoute from "./privateRoute/PrivateRoute";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import { ERegisterType } from "../enums";
import { useAuthentification } from "../common/customHooks";
import {
  Login,
  Dashboard,
  Register,
  ForgotPassword,
  ResetPassword,
  CreateSimulation,
  NotFound,
  SimulationList,
  SimulationResults,
  ManageProfile,
  ManageOffice,
  RequestList,
  ShipmentList,
  ShowShipment,
  CreateQuotation,
  Tracking,
  TrackingDetails,
  ShipmentRequestMapTracking,
  QuotationList,
} from "../pages";
import ManageUsers from "../pages/manageUsers/ManageUsers";

const AppRoute = (): JSX.Element => {
  const auth = useAuthentification();
  function PrivateRouteRender(props: any) {
    return auth?.accessToken === undefined &&
      auth?.refreshToken === undefined &&
      auth?.userId === undefined ? (
      <Navigate to="/login" />
    ) : (
      props.children
    );
  }
  function PublicRouteRender(props: any) {
    return !auth?.accessToken && !auth?.refreshToken && !auth?.userId ? (
      props.children
    ) : (
      <Navigate to="/" />
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <PublicRouteRender>
              <PublicRoute />
            </PublicRouteRender>
          }
        >
          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={<Register type={ERegisterType.customer} />}
          />
          <Route
            path="/register-carrier"
            element={<Register type={ERegisterType.carrier} />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/tracking/:trackNumber" element={<TrackingDetails />} />
          <Route
            path="/shipment-map-tracking/:id/:accessToken"
            element={<ShipmentRequestMapTracking type="shipment" />}
          />
          <Route
            path="/request-map-tracking/:id/:accessToken"
            element={<ShipmentRequestMapTracking type="request" />}
          />
        </Route>
        <Route
          element={
            <PrivateRouteRender>
              <PrivateRoute auth={auth} />
            </PrivateRouteRender>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-simulation" element={<CreateSimulation />} />
          <Route path="/simulation-list" element={<SimulationList />} />
          <Route
            path="/simulation-results/:id"
            element={<SimulationResults />}
          />
          <Route path="/quotation-list" element={<QuotationList />} />
          <Route path="/request-list" element={<RequestList />} />
          <Route path="/shipment-list" element={<ShipmentList />} />
          <Route path="/show-shipment/:id" element={<ShowShipment />} />
          <Route path="/create-quotation/:id" element={<CreateQuotation />} />
          <Route path="/manage-profile" element={<ManageProfile />} />
          <Route path="/manage-office" element={<ManageOffice />} />
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
