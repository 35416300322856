import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "../../common/customHooks";
import { Button, Card, PageTitle } from "../../components";
import { EButtonType } from "../../enums";
import { Data } from "../../common";
import { useState } from "react";
import { CreateQuotationForm } from "./forms";
import { ICreateQuotationTemplate } from "../../interfaces";

const CreateQuotationTemplate = (
  props: ICreateQuotationTemplate
): JSX.Element => {
  const { tr } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const forms: Array<React.ReactNode> = [<CreateQuotationForm {...props} />];
  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-between mb-2">
        <PageTitle title={tr("createQuotation")} />
        <div className="d-flex">
          <Button
            style={{ height: "40px", backgroundColor: "transparent" }}
            className="mx-1 ds-primary-color"
            type={EButtonType.secondary}
            text={tr("quotationSummary")}
          />
          <Button
            style={{ height: "40px" }}
            icon={<i className="bi bi-folder-fill" />}
            text={tr("saveQuotation")}
          />
        </div>
      </div>
      <Card
        isScroll
        style={{ height: "78vh" }}
        header={
          <Tabs
            defaultActiveKey={selectedTabIndex}
            className="mb-3"
            onSelect={(key) => setSelectedTabIndex(parseInt(key!))}
          >
            {Data.getCreateQuotationTabsData(tr).map((tab, index) => (
              <Tab key={index} eventKey={index} title={tab} />
            ))}
          </Tabs>
        }
        bodyClassName="pt-0"
      >
        {forms[selectedTabIndex]}
      </Card>
    </div>
  );
};
export default CreateQuotationTemplate;
