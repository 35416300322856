import { useEffect, useState } from "react";
import { Data } from "../../../../common";
import { useTranslation } from "../../../../common/customHooks";
import { TitleResponse, ToggledCard } from "../../../../components";
import { IRequestGeneralInfoState } from "../../../../interfaces";
import { RequestAPI } from "../../../../apis";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import { HttpStatusCode } from "axios";

const RequestGeneralInformation = ({ id }: { id?: number }): JSX.Element => {
  const { tr } = useTranslation();
  const requestApi = new RequestAPI();
  const [state, setState] = useState<IRequestGeneralInfoState>();

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await requestApi.getRequestGeneralInfo(id);
      if (response.status === HttpStatusCode.Ok) {
        setState(DataFetchAPI.fetchRequestGeneralInfoState(response.data));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard title={tr("generalInformation")} isBlocked>
      <div className="row mb-3">
        {Object.values({ ...state }).map((value, index) => (
          <div key={index} className="mb-2">
            <TitleResponse
              className="w-100"
              titleClassName="ds-rfq-gi-title"
              responseClassName="ds-rfq-response-title"
              title={Data.getRfqGeneralInfoData(tr)[index]}
              response={value}
            />
          </div>
        ))}
      </div>
    </ToggledCard>
  );
};
export default RequestGeneralInformation;
