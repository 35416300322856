import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../../atoms";
import { useTranslation } from "../../../common/customHooks";
import { IInfinityScroll } from "../../../interfaces";

const InfinityScroll = ({
  className,
  style,
  children,
  onNext,
  paginationLength,
  pageNumber,
}: IInfinityScroll): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <InfiniteScroll
      style={{overflowX:"hidden"}}
      className={className}
      dataLength={paginationLength}
      next={onNext}
      refreshFunction={() => {}}
      hasMore={pageNumber <= paginationLength}
      loader={
        <div className="flex justify-center py-5">
          <Spinner />
        </div>
      }
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>{tr("noMoreData")}</b>
        </p>
      }
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
    >
      {children}
    </InfiniteScroll>
  );
};
export default InfinityScroll;
