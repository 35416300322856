import { useEffect, useState } from "react";
import { ICommonComponent, IMapPosition } from "../../../interfaces";
import { ShipmentAPI } from "../../../apis";
import { HttpStatusCode } from "axios";
import { MapTrackingFromTo } from "../../molecules";

const ShipmentDetailsTrackingMap = ({
  className,
  style,
  id,
}: ICommonComponent & { id: number }): JSX.Element => {
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<{
    from?: IMapPosition;
    to?: IMapPosition;
  }>({ from: undefined, to: undefined });

  useEffect(() => {
    handleFetchDataTrackingShipmentAPI();
  }, [id]);

  const handleFetchDataTrackingShipmentAPI = async () => {
    try {
      const response = await shipmentApi.getShipmentFromTo(id);
      if (response.status === HttpStatusCode.Ok) {
        const { from, to } = response.data;
        setState({
          from: {
            lat: parseFloat(from?.latitude),
            lng: parseFloat(from?.longitude),
          },
          to: {
            lat: parseFloat(to?.latitude),
            lng: parseFloat(to?.longitude),
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div
      className={`${className || ""} p-3 ds-sl-map-box h-100`}
      style={{ minHeight: "300px", ...style }}
    >
      <MapTrackingFromTo className="ds-sl-map-box" {...state} />
    </div>
  );
};

export default ShipmentDetailsTrackingMap;
