import Avatar from "react-avatar";
import { Link, useLocation } from "react-router-dom";
import { INavUserDropItems } from "../../../interfaces";
import { usePrivateAppData, useTranslation } from "../../../common/customHooks";
const NavUserDropItems = ({
  className,
  style,
  links,
  onLogOut,
}: INavUserDropItems): JSX.Element => {
  const { tr } = useTranslation();
  const location = useLocation();
  const context = usePrivateAppData();
  const userFullName =
    context?.state?.firstName + " " + context?.state?.lastName;
  return (
    <div style={style} className={`${className || ""} p-3 ds-nav-user-card`}>
      <div className="mb-2">
        <Link to="manage-profile">
          <div className="row">
            <div className="col-2 pt-2">
              <Avatar
                size="30"
                src={context?.state?.avatarSrc}
                name={userFullName}
                round
              />
            </div>
            <div className="col-10">
              <div className="ds-nav-user-fullName-title">{userFullName}</div>
              <div className="ds-nav-user-email">{context?.state?.email}</div>
            </div>
          </div>
        </Link>
      </div>
      <hr className="mb-2 ds-bg-secondary" />
      <div style={{ maxHeight: "60vh", overflow: "auto" }} className="w-100">
        {links.map((link, index) => (
          <Link
            key={index}
            className={`d-flex cursor-pointer ps-3 ds-nav-user-link ${
              link.href === location.pathname && "ds-primary-color"
            }`}
            to={link.href}
          >
            {link.icon}
            <div className="ps-3">{link.label}</div>
          </Link>
        ))}
        <div
          className="d-flex cursor-pointer ps-3 ds-nav-user-link ds-danger-text"
          onClick={onLogOut}
        >
          <i className="bi bi-box-arrow-right" />
          <div className="ps-3">{tr("logOut")}</div>
        </div>
      </div>
    </div>
  );
};
export default NavUserDropItems;
