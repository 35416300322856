import { useLanguages } from "../../../common/customHooks";
import { EPrevNextType } from "../../../enums";
import { IPrevNextButton } from "../../../interfaces";

const PrevNextButton = (props: IPrevNextButton): JSX.Element => {
  const { className, type, text, onClick, isDisabled, style } = props;
  const { isRtl } = useLanguages();

  const PrevNextIcon = ({ type, className, style }: IPrevNextButton) => {
    const commonClassName = `bi ${className || ""} ${
      type === EPrevNextType.next ? "bi-chevron-right" : "bi-chevron-left"
    }`;
    return <i style={style} className={commonClassName} />;
  };
  
  return (
    <button
      style={style}
      className={`${className || ""} ds-prev-next-button ${
        type === EPrevNextType.prev ? "ds-bg-disabled" : "ds-bg-second-blue"
      }`}
      disabled={isDisabled}
      onClick={onClick}
    >
      <div className="w-100 d-flex justify-center">
        {type === EPrevNextType.prev && (
          <PrevNextIcon
            {...props}
            className="mt-1"
            style={{ lineHeight: "12px" }}
            type={isRtl ? EPrevNextType.next : type}
          />
        )}
        <span className="mx-2">{text}</span>
        {type === EPrevNextType.next && (
          <PrevNextIcon
            {...props}
            className="mt-1"
            style={{ lineHeight: "12px" }}
            type={isRtl ? EPrevNextType.prev : type}
          />
        )}
      </div>
    </button>
  );
};
export default PrevNextButton;
