enum Elang {
  fr = "fr",
  en = "en",
  ar = "ar",
}
enum ETheme {
  light = "light",
  dark = "dark",
}
enum EPrevNextType {
  prev = "prev",
  next = "next",
}
enum EFileType {
  img = "image",
  word = "word",
  pdf = "pdf",
  excel = "excel",
}
enum ESignWithType {
  google = "google",
  apple = "apple",
}
enum EAuthType {
  login = "login",
  register = "register",
  forgotPassword = "forgotPassword",
  resetPassword = "resetPassword",
  emailSentSuccessfully = "emailSentSuccessfully",
  resetTokenExpired = "resetTokenExpired",
}
enum ERegisterType {
  customer = "customer",
  carrier = "carrier",
}

enum ECreateSenderType {
  full = "full",
  adress = "",
  contact = "",
}
enum EDeviceType {
  pc = "pc",
  phone = "phone",
  tablette = "tablette",
}
enum EUserRole {
  customer = "customer",
  carrier = "carrier",
}
export {
  Elang,
  EPrevNextType,
  EFileType,
  ESignWithType,
  EAuthType,
  ERegisterType,
  ETheme,
  ECreateSenderType,
  EDeviceType,
  EUserRole,
};
