import { Badge } from "react-bootstrap";
import { INotificationBadge } from "../../../interfaces";
import { useLanguages } from "../../../common/customHooks";

const NotificationBadge = ({ className, style, count }: INotificationBadge) => {
  const { isRtl } = useLanguages();
  return (
    <div
      className={`${className || ""} ds-black-color`}
      style={{ position: "relative", cursor: "pointer", ...style }}
    >
      <i style={{ fontSize: "20px" }} className="bi bi-bell" />
      {count > 0 && (
        <Badge
          bg="danger"
          style={{ fontSize: "10px", left: isRtl ? "20px" : "" }}
          className="position-absolute top-0 translate-middle"
        >
          {count}
        </Badge>
      )}
    </div>
  );
};
export default NotificationBadge;
