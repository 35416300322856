const ImgIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.75 1.56055H1.25C0.563125 1.56055 0 2.12367 0 2.81055V17.1893C0 17.8762 0.5625 18.4393 1.25 18.4393H18.75C19.4369 18.4393 20 17.8762 20 17.1893V2.81055C20 2.12367 19.4369 1.56055 18.75 1.56055ZM18.75 17.1893H1.25V13.5068L6.26688 8.64492L11.4388 13.8105C11.6519 14.0693 12.0312 14.0362 12.3006 13.8324L14.5831 11.7662L18.7 15.9924C18.7156 16.008 18.7331 16.0199 18.75 16.033V17.1893ZM18.75 14.2537L15.0775 10.4955C14.8544 10.2755 14.5056 10.2537 14.2581 10.4418L11.9031 12.493L6.72813 7.3743C6.6175 7.24055 6.45812 7.1593 6.285 7.14742C6.11312 7.14117 5.94313 7.19742 5.81687 7.31617L1.24875 11.7612V2.80992H18.7487V14.253L18.75 14.2537ZM14.375 7.81492C15.0638 7.81492 15.6219 7.25617 15.6219 6.56805C15.6219 5.87992 15.0644 5.32117 14.375 5.32117C13.6856 5.32117 13.1281 5.87992 13.1281 6.56805C13.1281 7.25617 13.6856 7.81492 14.375 7.81492Z"
        fill="#849BB3"
      />
    </svg>
  );
};
export default ImgIcon;
