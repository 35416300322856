import TimePicker from "react-time-picker";
import { Label } from "../../atoms";
import { ITimePicker } from "../../../interfaces";

const CustomTimePicker = ({
  className,
  style,
  labelStyle,
  isObligatoryLabel,
  label,
  value,
  onChange,
}: ITimePicker): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          className="mb-2"
          style={labelStyle}
          isObligatory={isObligatoryLabel === true}
          text={label}
        />
      )}
      <TimePicker className="w-100" onChange={onChange} value={value} />
    </div>
  );
};
export default CustomTimePicker;
