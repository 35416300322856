import { useEffect, useState } from "react";
import { MapTrackingFromTo } from "../../../../components";
import { IMapPosition, IMapTrackingState } from "../../../../interfaces";
import { RequestAPI } from "../../../../apis";
import { HttpStatusCode } from "axios";

const ShowRequestOnMap = ({ id }: { id?: number }) => {
  const requestApi = new RequestAPI();
  const [state, setState] = useState<IMapTrackingState>();

  useEffect(() => {
    if (id) {
      handleFetchAPILocationData(id);
    }
  }, [id]);

  const handleFetchAPILocationData = async (id: number) => {
    try {
      const response = await requestApi.getRequestFromTo(id);
      if (response.status === HttpStatusCode.Ok) {
        const { from, to } = response.data;
        setState({
          from: {
            lat: parseFloat(from?.latitude),
            lng: parseFloat(from?.longitude),
          },
          to: {
            lat: parseFloat(to?.latitude),
            lng: parseFloat(to?.longitude),
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return <MapTrackingFromTo {...state} />;
};
export default ShowRequestOnMap;
