import { useTranslation } from "../../common/customHooks";
import { AuthForm, Input } from "../../components";
import { EAuthType, EInputTypes } from "../../enums";
import { IResetPasswordTemplate } from "../../interfaces";
import { AuthLayout } from "../../layouts";

const ResetPasswordTemplate = ({
  state,
  onChangeState,
  onSubmit,
}: IResetPasswordTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const { type } = state;
  return (
    <AuthLayout type={type}>
      <AuthForm onSubmit={onSubmit} type={type}>
        {type === EAuthType.resetPassword && (
          <>
            <Input
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, password: value })
              }
              value={state.password}
              isValidate
              type={EInputTypes.password}
              label={tr("password")}
              isObligatoryLabel
            />
            <Input
              className="my-2"
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, confirmedPassword: value })
              }
              isValidate
              validatonOption={{
                confirmedPassword: { password: state.password },
              }}
              value={state.confirmedPassword}
              type={EInputTypes.password}
              label={tr("confirmedPassword")}
              isObligatoryLabel
            />
          </>
        )}
      </AuthForm>
    </AuthLayout>
  );
};
export default ResetPasswordTemplate;
