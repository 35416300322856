import AppRoute from "./routes/AppRoutes";
import { Provider } from "react-redux";
import { persistor, store } from "./redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import {
  FullScreenProvider,
  DrawerProvider,
  LangProvider,
  ModalProvider,
  ThemeProvider,
} from "./providers";
import { pdfjs } from "react-pdf";
import { Toast } from "./components";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LangProvider>
          <ThemeProvider>
            <FullScreenProvider>
              <DrawerProvider>
                <ModalProvider>
                  <Toast />
                  <AppRoute />
                </ModalProvider>
              </DrawerProvider>
            </FullScreenProvider>
          </ThemeProvider>
        </LangProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
