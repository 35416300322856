import { useTranslation } from "../../../common/customHooks";
import { EAuthType } from "../../../enums";
import { IAuthFormFooter } from "../../../interfaces";
import TextLink from "../textLink/TextLink";

const AuthFormFooter = ({
  className,
  type,
  style,
}: IAuthFormFooter): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className={`${className || ""} w-100 h-100`}>
      {type === EAuthType.login || type === EAuthType.register ? (
        <div className={`${type === EAuthType.register && "d-flex"} px-2`}>
          <div className="ds-auth-form-footer pt-1">
            {type === EAuthType.login
              ? tr("youDontHaveAccount")
              : tr("youHaveAccount")}
          </div>
          <div
            className={`d-flex pb-1 flex-wrap ${
              type === EAuthType.register ? "ps-1" : ""
            }`}
          >
            <TextLink
              isLink
              text={
                type === EAuthType.login
                  ? tr("registerASCustomer")
                  : tr("login")
              }
              href={type === EAuthType.login ? "/register" : "/login"}
            />
            {type === EAuthType.login && (
              <>
                <div className="px-1">{tr("or").toLowerCase()}</div>
                <TextLink
                  isLink
                  text={tr("registerAsCarrier")}
                  href="/register-carrier"
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-center">
          <TextLink isLink text="Return to login" href="/login" />
        </div>
      )}
    </div>
  );
};
export default AuthFormFooter;
