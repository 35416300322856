import {
  Button,
  Input,
  InputPhoneNumber,
  InputSelect,
  ProfileManageAvatar,
} from "../../../../components";
import { IEditProfile } from "../../../../interfaces";
import { EInputTypes } from "../../../../enums";
import { useTranslation } from "../../../../common/customHooks";

const EditProfile = ({
  state,
  onChangeState,
  onSubmit,
}: IEditProfile): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 h-100">
      <ProfileManageAvatar
        className="mb-3"
        label={tr("profilePicture")}
        src={state.image}
        name={`${state.lastName} ${state.firstName}`}
        onChange={() => {}}
        onDelete={() => {}}
      />
      <hr className="mb-3" />
      <div className="row mb-3">
        <div className="col-md-3 col-12">
          <Input
            label={tr("userName")}
            isObligatoryLabel
            isValidate
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, userName: value })
            }
            value={state.userName}
          />
        </div>
        <div className="col-md-3 col-12">
          <InputSelect
            label={tr("userLevel")}
            isObligatoryLabel
            isMulti={false}
            onChange={(e: any) =>
              onChangeState({ ...state, userLevel: e?.value })
            }
            options={[]}
          />
        </div>
        <div className="col-md-3 col-12">
          <InputSelect
            label={tr("userType")}
            isObligatoryLabel
            isMulti={false}
            onChange={(e: any) =>
              onChangeState({ ...state, userType: e?.value })
            }
            options={[]}
          />
        </div>
      </div>
      <hr className="mb-3" />
      <div className="row mb-3">
        <div className="col-md-6 col-12">
          <div className="row ds-md-mb-3">
            <div className="col-md-6 col-12">
              <Input
                label={tr("firstName")}
                isObligatoryLabel
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({ ...state, firstName: value })
                }
                value={state.firstName}
                isValidate
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                label={tr("lastName")}
                isObligatoryLabel
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({ ...state, lastName: value })
                }
                value={state.lastName}
                isValidate
              />
            </div>
          </div>
          <div className="row ds-md-mb-3">
            <div className="col-md-6 col-12">
              <InputSelect
                label={tr("gender")}
                isObligatoryLabel
                isMulti
                onChange={() => {}}
                options={[]}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputSelect
                label={tr("function")}
                isObligatoryLabel
                isMulti
                onChange={() => {}}
                options={[]}
              />
            </div>
          </div>
          <div className="row ds-md-mb-3">
            <div className="col-md-6 col-12">
              <InputPhoneNumber
                label={tr("fixPhone")}
                isObligatoryLabel
                onChange={(value) =>
                  onChangeState({ ...state, fixePhone: value })
                }
                isValidate
                value={state.fixePhone}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputPhoneNumber
                label={tr("mobilePhone")}
                isObligatoryLabel
                onChange={(value) =>
                  onChangeState({ ...state, fixePhone: value })
                }
                isValidate
                value={state.fixePhone}
              />
            </div>
          </div>
          <div className="row ds-md-mb-3">
            <div className="col-md-6 col-12">
              <InputPhoneNumber
                label={tr("whatsappPhone")}
                isObligatoryLabel
                onChange={(value) =>
                  onChangeState({ ...state, whatsappNumber: value })
                }
                isValidate
                value={state.whatsappNumber}
              />
            </div>
            <div className="col-md-6 col-12">
              <Input
                type={EInputTypes.email}
                isValidate
                label={tr("email")}
                isObligatoryLabel
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({ ...state, email: value })
                }
                value={state.email}
              />
            </div>
          </div>
        </div>
      </div>
      <Button className="mb-3" onClick={onSubmit} text={tr("saveChange")} />
    </div>
  );
};
export default EditProfile;
