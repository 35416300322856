import { useEffect, useState } from "react";
import { useTranslation } from "../../../../common/customHooks";
import { ToggledCard } from "../../../../components";
import PackageNestedTable from "../../../../components/organisms/packageNestedTable/PackageNestedTable";
import { INIT_PACKAGES_STATE } from "../../../../consts";
import { IPackagesState } from "../../../../interfaces";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import { ShipmentAPI } from "../../../../apis";

const ShowShPackage = ({ id }: { id?: number }): JSX.Element => {
  const { tr } = useTranslation();
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<IPackagesState>(INIT_PACKAGES_STATE);

  useEffect(() => {
    if (id) {
      handleFetchApiData(id);
    }
  }, [id, state.pageNumber]);

  const handleFetchApiData = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentPackagesPagination(id, {
        ...state,
      });

      if (response.status === HttpStatusCode.Ok) {
        const { last_page, data } = response.data;
        setState({
          ...state,
          paginationLength: last_page,
          packages: DataFetchAPI.fetchShipmentPackagesData(data),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard
      title={tr("package")}
      bodyStyle={{ padding: 0, overflow: "hidden" }}
      isBlocked
    >
      <PackageNestedTable packages={state.packages} />
    </ToggledCard>
  );
};
export default ShowShPackage;
