import { useState } from "react";
import { ManageOfficeTemplate } from "../../templates";
import {
  IMofCommonProfileState,
  IMofGeneralAdressState,
  IMofGeneralState,
} from "../../interfaces";
import {
  INIT_MF_GENERAL_ADRESS,
  INIT_MOF_COMMON_STATE,
  INIT_MOF_GENERAL_STATE,
} from "../../consts";

const ManageOffice = (): JSX.Element => {
  const [commonState, setCommonState] = useState<IMofCommonProfileState>(
    INIT_MOF_COMMON_STATE
  );
  const [generalState, setGeneralState] = useState<IMofGeneralState>(
    INIT_MOF_GENERAL_STATE
  );
  const [generalAdressState, setGeneralAdressState] =
    useState<IMofGeneralAdressState>(INIT_MF_GENERAL_ADRESS);
  const handleDeleteCompanyImage = () => {};
  const handleChangeCompanyImage = (img: string) => {};
  const handleUpdateCompany = () => {};
  const handleCreateEditCompanyAdress = () => [];
  return (
    <ManageOfficeTemplate
      commonState={commonState}
      onChangeCommonState={setCommonState}
      generalState={generalState}
      onChangeGeneralState={setGeneralState}
      onDeleteCompanyImage={handleDeleteCompanyImage}
      onChangeCompanyImage={handleChangeCompanyImage}
      onUpdateCompany={handleUpdateCompany}
      generalAdressState={generalAdressState}
      onChangeAdressState={setGeneralAdressState}
      onCreateEditAdress={handleCreateEditCompanyAdress}
    />
  );
};
export default ManageOffice;
