import { arFlag, enFlag, frFlag } from "../../assets";
import {
  DashboardIcon,
  SettingIcon,
  ShipmentIcon,
  ShipmentStatus,
} from "../../components";
import { EAuthType, ESidebarIcons, EUserRole, Elang } from "../../enums";
import {
  ICreateQuotationState,
  IDataColumn,
  IFieldOption,
  IGetAuthTitleSubTitle,
  ILanguageDropItem,
  INavUserLinks,
  IQuotationModel,
  IShipmentItem,
  IShipmentItemState,
  IShipmetInfoModel,
  ISidebarNavbarDataItem,
  ITrackingDetailsState,
} from "../../interfaces";
import { TDocumentState, TTranslateFunction } from "../../types";

class Data {
  static getAuthTitleSubTitle(
    tr: TTranslateFunction,
    authType: EAuthType
  ): IGetAuthTitleSubTitle {
    switch (authType) {
      case EAuthType.login: {
        return { title: tr("login"), subTitle: tr("startTheAdventure") };
      }
      case EAuthType.register: {
        return { title: tr("signUp"), subTitle: tr("createYourAccount") };
      }
      case EAuthType.forgotPassword: {
        return {
          title: tr("forgotPassword"),
        };
      }
      case EAuthType.resetPassword: {
        return {
          title: tr("resetPassword"),
          subTitle: tr("resetPasswordSubTitle"),
        };
      }
      case EAuthType.emailSentSuccessfully: {
        return {
          title: tr("resetEmailSent"),
          subTitle: tr("weHaveSentResetEmail"),
        };
      }
      case EAuthType.resetTokenExpired: {
        return {
          title: tr("oops"),
          subTitle: tr("passwordTokenExpired"),
        };
      }
    }
  }
  static getAuthButtonText(
    tr: TTranslateFunction,
    authType: EAuthType
  ): string {
    let text = "";
    switch (authType) {
      case EAuthType.register: {
        return tr("registerFree");
      }
      case EAuthType.login: {
        return tr("login");
      }
      case EAuthType.forgotPassword: {
        return tr("resetPassword");
      }
      case EAuthType.resetPassword: {
        return tr("changePassword");
      }
      case EAuthType.resetTokenExpired: {
        return tr("forgotPassword");
      }
    }
    return text;
  }

  static getAuthLayoutClasssName(type: EAuthType): string {
    let className = "ds-auth-card-layout-global";
    if (type === EAuthType.register) {
      className = "ds-auth-card-layout-register";
    }
    return className;
  }

  static getFooterItems(
    tr: TTranslateFunction
  ): Array<{ label: string; href: string }> {
    return [
      { label: tr("confidentiality"), href: "/confidentiality" },
      { label: tr("conditions"), href: "/conditions" },
      { label: tr("cookies"), href: "/cookies" },
    ];
  }
  static getCreaterShipmentStepperData(tr: TTranslateFunction): Array<string> {
    return [
      tr("generalInformation"),
      tr("fromTo"),
      tr("service"),
      tr("package"),
      tr("document"),
    ];
  }

  static getDocumentsListRowsData(
    document: TDocumentState,
    onViewDocumentFile: (doc: TDocumentState) => void
  ) {
    return {
      documentType: document.type,
      documentName: document.name,
      uploadDate: Data.getFormdatedDate(document.uploadDate),
      issueDate: Data.getFormdatedDate(document.issueDate),
      documentFile: (
        <div className="ds-circle-icon-layout d-flex justify-center">
          <i
            className="bi bi-eye cursor-pointer ds-grey-blue-color"
            onClick={() => onViewDocumentFile(document)}
          />
        </div>
      ),
    };
  }

  static getDocumentListColumns(
    tr: TTranslateFunction,
    isEnableActions?: boolean
  ) {
    const columns = [
      {
        accessorKey: "documentType",
        header: tr("documentType"),
        size: 150,
      },
      {
        accessorKey: "documentName",
        header: tr("documentName"),
        size: 150,
      },
      {
        accessorKey: "uploadDate",
        header: tr("uploadDate"),
        size: 150,
      },
      {
        accessorKey: "issueDate",
        header: tr("issueDate"),
        size: 150,
      },
      {
        accessorKey: "documentFile",
        header: tr("documentFile"),
        size: 150,
      },
    ];
    return isEnableActions
      ? columns.concat([
          {
            accessorKey: "actions",
            header: tr("actions"),
            size: 150,
          },
        ])
      : columns;
  }
  static getSimulationListColumns(tr: TTranslateFunction): Array<IFieldOption> {
    return [
      { value: "tracking", label: tr("tracking") },
      { value: "date", label: tr("date") },
      { value: "pickUpDate", label: tr("pickUpDate") },
      { value: "sender", label: tr("sender") },
      { value: "senderCompany", label: tr("senderCompany") },
      { value: "origineDeparture", label: tr("origineDeparture") },
      { value: "receiver", label: tr("receiver") },
      { value: "receiverCompany", label: tr("receiverCompany") },
      { value: "arrival", label: tr("arrival") },
      { value: "actions", label: tr("actions") },
    ];
  }
  static getSimulationResultsColumns(
    tr: TTranslateFunction
  ): Array<IFieldOption> {
    return [
      { value: "companyName", label: tr("companyName") },
      { value: "service", label: tr("service") },
      { value: "deliveryTime", label: tr("deliveryTime") },
      { value: "serviceAmount", label: tr("serviceAmount") },
      { value: "Statut", label: tr("statut") },
      { value: "additionalService", label: tr("additionalService") },
      { value: "totalAmount", label: tr("totalAmount") },
    ];
  }
  static getFileUploaderType(): Array<string> {
    return ["JPG", "PNG", "GIF"];
  }
  static getSidebarNavBarDataItems(
    tr: TTranslateFunction,
    userRole?: EUserRole
  ): Array<ISidebarNavbarDataItem> {
    const commonRoutes = [
      { label: tr("simpleRequestList"), to: "/request-list" },
      { label: tr("simpleQuotationList"), to: "/quotation-list" },
      { label: tr("simpleShipmentList"), to: "/shipment-list" },
    ];
    return userRole
      ? [
          {
            icon: ESidebarIcons.dashboard,
            label: tr("dashboard"),
            to: "/",
          },
          {
            icon: ESidebarIcons.shipment,
            label: tr("bookingShipment"),
            subItems:
              userRole === EUserRole.customer
                ? [
                    {
                      label: tr("simpleCreateSimulation"),
                      to: "/create-simulation",
                    },
                    {
                      label: tr("simpleSimulationList"),
                      to: "/simulation-list",
                    },
                  ].concat(commonRoutes)
                : commonRoutes,
          },
          {
            icon: ESidebarIcons.settings,
            label: tr("settings"),
            subItems: [
              {
                label: tr("simpleManageOffice"),
                to: "/manage-office",
              },
              {
                label: tr("manageUserProfile"),
                to: "/manage-profile",
              },
              {
                label: tr("manageOtherUsers"),
                to: "/manage-users",
              },
            ],
          },
        ]
      : [];
  }
  static getPagesPathNames() {
    return {
      home: ["/", "/home"],
      simulation: [
        "/create-simulation",
        "/simulation-list",
        "/request-list",
        "/quotation-list",
        "/shipment-list",
      ],
      settings: ["/manage-office", "/manage-profile", "/manage-users"],
    };
  }
  static getFormdatedDate(date: Date): string {
    return `${date.getFullYear()}/${date.getMonth()}/${date.getDay()}`;
  }
  static getNavUserLinks(tr: TTranslateFunction): Array<INavUserLinks> {
    return [
      {
        href: "/user-settings",
        icon: <i className="bi bi-gear" />,
        label: tr("userSettings"),
      },
      {
        href: "/preferences",
        icon: <i className="bi bi-sliders" />,
        label: tr("prefereneces"),
      },
      {
        href: "/security",
        icon: <i className="bi bi-shield-exclamation" />,
        label: tr("security"),
      },
      {
        href: "/notifications",
        icon: <i className="bi bi-bell" />,
        label: tr("notifications"),
      },
    ];
  }
  static getLanguagesDropDataItems(
    tr: TTranslateFunction
  ): Array<ILanguageDropItem> {
    return [
      { flagSrc: enFlag, label: tr("english"), type: Elang.en },
      { flagSrc: frFlag, label: tr("french"), type: Elang.fr },
      { flagSrc: arFlag, label: tr("arabic"), type: Elang.ar },
    ];
  }

  static getManageTabsData(tr: TTranslateFunction): Array<string> {
    return [
      tr("editProfile"),
      tr("password"),
      tr("preference"),
      tr("notification"),
      tr("activities"),
      tr("documents"),
    ];
  }
  static getManageOfficeTabsData(tr: TTranslateFunction): Array<string> {
    return [tr("general"), tr("preference"), tr("security"), tr("settings")];
  }
  static getRequestForQuotationTabsData(tr: TTranslateFunction): Array<string> {
    return [
      tr("generalInformation"),
      tr("fromTo"),
      tr("service"),
      tr("package"),
      tr("documents"),
      tr("cancel"),
    ];
  }
  static getDeviceLastConnexionText(
    lastActivity: Date,
    tr: TTranslateFunction
  ): string {
    const now = new Date();
    const timeDifference = now.getTime() - lastActivity.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (
      daysDifference === 0 &&
      hoursDifference === 0 &&
      minutesDifference <= 1
    ) {
      return tr("lastActivityJustNow");
    } else {
      return `${tr("lastActive")}: ${daysDifference} ${tr(
        "days"
      ).toLocaleLowerCase()}, ${hoursDifference} ${tr(
        "hours"
      ).toLowerCase()}, ${minutesDifference} ${tr(
        "minutes"
      ).toLowerCase()} ${tr("ago").toLowerCase()}`;
    }
  }

  static getMofGeneralVerticalNavItems(tr: TTranslateFunction): Array<string> {
    return [
      tr("company"),
      tr("branche"),
      tr("partner"),
      tr("adresses"),
      tr("contact"),
    ];
  }
  static getRfqFromToToggledCardTitlesData(
    tr: TTranslateFunction
  ): Array<string> {
    return [
      tr("sender"),
      tr("recipient"),
      tr("originPickupDeposit"),
      tr("destinationDelivery"),
      tr("agent"),
    ];
  }
  static getRfqFromToMoreInfoTitlesData(tr: TTranslateFunction): Array<string> {
    return [
      tr("readiness"),
      tr("pickUpDeposit"),
      tr("boarding"),
      tr("delivery"),
    ];
  }

  static getRfqPackageColumns(tr: TTranslateFunction): Array<string> {
    return [tr("packageName"), tr("packageType"), tr("item")];
  }
  static getRfqPackageItemsTitles(tr: TTranslateFunction): Array<string> {
    return [
      tr("description"),
      tr("shCode"),
      tr("section"),
      tr("chapter"),
      tr("quantity"),
      tr("weight"),
      tr("height"),
      tr("length"),
      tr("declaredValue"),
      tr("insuredValue"),
      tr("currency"),
    ];
  }
  static getSidebarIcons(
    iconType: ESidebarIcons | undefined,
    pathname: string
  ): React.ReactNode {
    const pagePatheNames = this.getPagesPathNames();
    switch (iconType) {
      case ESidebarIcons.dashboard: {
        return (
          <DashboardIcon
            className={
              pagePatheNames.home.includes(pathname)
                ? "ds-sidebar-existing-item"
                : ""
            }
          />
        );
      }
      case ESidebarIcons.shipment: {
        return (
          <ShipmentIcon
            className={
              pagePatheNames.simulation.includes(pathname)
                ? "ds-sidebar-existing-item"
                : ""
            }
          />
        );
      }
      case ESidebarIcons.settings: {
        return (
          <SettingIcon
            className={
              pagePatheNames.settings.includes(pathname)
                ? "ds-sidebar-existing-item"
                : ""
            }
          />
        );
      }
    }
    return null;
  }
  static getWeekDays(tr: TTranslateFunction): Array<string> {
    return [
      tr("monday"),
      tr("tuesday"),
      tr("wednesday"),
      tr("thursday"),
      tr("friday"),
      tr("saturday"),
    ];
  }
  static getCompanyOfficeAdress(tr: TTranslateFunction): Array<IFieldOption> {
    return [
      { label: tr("adressesList"), value: "" },
      { label: tr("adressesType"), value: "" },
      { label: tr("adresses"), value: "" },
      { label: tr("phone"), value: "" },
      { label: tr("openningDay"), value: "" },
      { label: tr("actions"), value: "" },
    ];
  }
  static getDashboardRecentQuotationDataColumns(tr: TTranslateFunction) {
    return [
      { accessorKey: "companyName", header: tr("companyName"), size: 150 },
      { accessorKey: "deliveryTime", header: tr("deliveryTime"), size: 150 },
      { accessorKey: "amount", header: tr("amount"), size: 150 },
    ];
  }
  static getDashboardShipmentListDataColumns(tr: TTranslateFunction) {
    return [
      { accessorKey: "trackingId", header: tr("trackingId"), size: 150 },
      { accessorKey: "pickupDate", header: tr("pickupDate"), size: 150 },
      { accessorKey: "sender", header: tr("sender"), size: 150 },
      {
        accessorKey: "origineDeparture",
        header: tr("origineDeparture"),
        size: 150,
      },
      { accessorKey: "arrival", header: tr("arrival"), size: 150 },
      { accessorKey: "status", header: tr("status"), size: 150 },
    ];
  }

  static getShipmentListRowsData(shipment: IShipmentItemState) {
    const { refrenceId, departureDate, departure, arrival, status } = shipment;
    return {
      trackingId: <a className="ds-primary-color">{refrenceId}</a>,
      pickupDate: departureDate ? Data.getFormdatedDate(departureDate) : "",
      sender: "?",
      origineDeparture: departure,
      arrival,
      status: status ? <ShipmentStatus {...status} /> : "",
    };
  }
  static getProfileAppStatusClassName(percentage: number): string {
    let className = "ds-danger-color";
    if (percentage >= 50) {
      className = "ds-warning-color";
    }
    return className;
  }
  static getProfileLineStatusClassName(percentage: number): string {
    let className = "ds-bg-danger";
    if (percentage >= 50) {
      className = "ds-bg-warning";
    }
    return className;
  }
  static getShowShipmentsTabsData(tr: TTranslateFunction): Array<string> {
    return [
      tr("shipment"),
      tr("package"),
      tr("services"),
      tr("documents"),
      tr("claim"),
      tr("cancel"),
    ];
  }
  static getCreateQuotationTabsData(tr: TTranslateFunction): Array<string> {
    return [tr("createQuotation"), tr("uploadQuotation")];
  }
  static getCreateQuotationToggledLinesData(
    tr: TTranslateFunction
  ): Array<{ label: string; isAddBtn: boolean; addButtonText?: string }> {
    return [
      { label: tr("carrierInformation"), isAddBtn: false },
      { label: tr("customerInformation"), isAddBtn: false },
      {
        label: tr("packages"),
        isAddBtn: true,
        addButtonText: tr("addPackages"),
      },
      {
        label: tr("documents"),
        isAddBtn: true,
        addButtonText: tr("addDocuments"),
      },
      {
        label: tr("services"),
        isAddBtn: true,
        addButtonText: tr("addServices"),
      },
      { label: tr("payments"), isAddBtn: false },
    ];
  }
  static getCreateQuotationPriceData(tr: TTranslateFunction): Array<string> {
    return [tr("totalAmountExcl"), tr("totalTax")];
  }
  static getQuotationSummaryData(
    tr: TTranslateFunction,
    state: ICreateQuotationState
  ): Array<{ label: string; data: string | number }> {
    return [
      { label: tr("requestN"), data: "" },
      { label: tr("quotationN"), data: state.carrierInfo.quotationNumber },
      { label: tr("quotationDate"), data: "" },
      { label: tr("quotationValidity"), data: "" },
      { label: tr("transitTime"), data: "" },
      { label: tr("mode"), data: "" },
    ];
  }
  static getTimeStringFromDate(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  static getTrackingSenderDataColumns(
    tr: TTranslateFunction,
    state: ITrackingDetailsState
  ): Array<IDataColumn> {
    const { sender } = state;
    return [
      {
        columnBodies: [
          {
            icon: <i className="bi bi-geo-alt-fill" />,
            title: tr("originCountry"),
            response: sender!.originCountry,
          },
          {
            icon: <i className="bi bi-calendar-event" />,
            title: tr("shippingDate"),
            response: sender!.shippingDate,
          },
          { title: tr("contactName"), response: sender!.contactName },
          {
            title: tr("shippingQuantity"),
            response: sender!.shippingQuantity.toString(),
          },
        ],
      },
      {
        columnBodies: [
          {
            icon: <i className="bi bi-geo-alt-fill" />,
            title: tr("originCity"),
            response: sender!.originCity,
          },
          {
            icon: <i className="bi bi-clock" />,
            title: tr("shippingTime"),
            response: sender!.shippingTime,
          },
          { title: tr("contactAdress"), response: sender!.contactAdress },
          {
            title: tr("totalWeight"),
            response: sender!.totalWeight.toString(),
          },
        ],
      },
    ];
  }
  static getTrackingReceiverDataColumns(
    tr: TTranslateFunction,
    state: ITrackingDetailsState
  ): Array<IDataColumn> {
    const { receiver } = state;
    return [
      {
        columnBodies: [
          {
            icon: <i className="bi bi-geo-alt-fill" />,
            title: tr("destinationCountry"),
            response: receiver!.destinationCountry,
          },
          {
            icon: <i className="bi bi-calendar-event" />,
            title: tr("shippingTime"),
            response: receiver?.shippingTime
              ? this.getTimeStringFromDate(receiver?.shippingTime)
              : "",
          },
          { title: tr("contactName"), response: receiver?.contactName ?? "" },
        ],
      },
      {
        columnBodies: [
          {
            icon: <i className="bi bi-geo-alt-fill" />,
            title: tr("destinationCity"),
            response: receiver!.destinationCity,
          },
          {
            icon: <i className="bi bi-clock" />,
            title: tr("estimatedDeliveryDate"),
            response: receiver!.estimatedDeliveryDate,
          },
          { title: tr("contactAdress"), response: receiver!.contactAdress },
        ],
      },
    ];
  }
  static getRfqGeneralInfoData(tr: TTranslateFunction): Array<string> {
    return [
      tr("referenceNumber"),
      tr("transportMode"),
      tr("shipmentType"),
      tr("intercom"),
      tr("location"),
      tr("instruction"),
    ];
  }
  static getShowShipmentTransactionDataColumns(
    tr: TTranslateFunction
  ): Array<IFieldOption> {
    return [
      { label: tr("date"), value: "date" },
      { label: tr("amount"), value: "amount" },
      { label: tr("paymentMethod"), value: "paymentMethod" },
      { label: tr("status"), value: "status" },
    ];
  }

  static getDashboardJoyrideData(tr: TTranslateFunction) {
    return [
      { target: ".dash-user-status", content: tr("dashUserStatDescription") },
      {
        target: ".dash-total-shipment",
        content: tr("dashTotalShipmentDescription"),
      },
      {
        target: ".dash-delivered-shipments",
        content: tr("dashDeliveredShipmentsDescription"),
      },
      {
        target: ".dash-rejected-shipments",
        content: tr("dashShippingSpendDescription"),
      },
      {
        target: ".dash-shipping-spend",
        content: tr("dashShippingSpendDescription"),
      },
      { target: ".dash-chart", content: tr("dashChartDescription") },
      {
        target: ".dash-recent-quotation",
        content: tr("dashRecentQuotationDescription"),
      },
      {
        target: ".dash-shipment-list",
        content: tr("dashShipmentListDescription"),
      },
    ];
  }

  static getShipmentInfoDataColums(tr: TTranslateFunction) {
    return [
      {
        accessorKey: "index",
        header: "",
        size: 150,
      },
      {
        accessorKey: "name",
        header: tr("name"),
        size: 150,
      },
      {
        accessorKey: "address",
        header: tr("address"),
        size: 150,
      },
      {
        accessorKey: "email",
        header: tr("email"),
        size: 150,
      },
      {
        accessorKey: "phone",
        header: tr("phone"),
        size: 150,
      },
    ];
  }

  static getShipmentInfoBookingData(
    tr: TTranslateFunction,
    state: IShipmetInfoModel
  ) {
    return Object.values(state).map(
      ({ name, address, email, phone }, index) => ({
        index: [
          tr("shipper"),
          tr("consignee"),
          tr("firstNotify"),
          tr("secondNotify"),
          tr("agent"),
        ][index],
        name,
        address,
        email,
        phone,
      })
    );
  }
  static getShipmentInfoDetailsData(
    tr: TTranslateFunction,
    state: IShipmetInfoModel
  ) {
    return Object.values({
      departure: state?.departure,
      arrival: state?.arrival,
    }).map(({ name, address, email, phone }: any, index) => ({
      index: [tr("departure"), tr("arrival")][index],
      name,
      address,
      email,
      phone,
    }));
  }

  static getQuotationListColumnsData(tr: TTranslateFunction) {
    return [
      {
        accessorKey: "requestDate",
        header: tr("requestDate"),
        size: 150,
      },
      {
        accessorKey: "requestNumber",
        header: tr("requestNumber"),
        size: 150,
      },
      {
        accessorKey: "customer",
        header: tr("customer"),
        size: 200,
      },
      {
        accessorKey: "carrier",
        header: tr("carrier"),
        size: 150,
      },
      {
        accessorKey: "quotationNumber",
        header: tr("quotationNumber"),
        size: 150,
      },
      {
        accessorKey: "quotationDate",
        header: tr("quotationDate"),
        size: 150,
      },
      {
        accessorKey: "totalAmount",
        header: tr("totalAmount"),
        size: 150,
      },
      {
        accessorKey: "status",
        header: tr("status"),
        size: 150,
      },
      {
        accessorKey: "action",
        header: tr("action"),
        size: 150,
      },
    ];
  }

  static getQuotationListRowsData(
    quotation: IQuotationModel,
    onShowQuotation: (id: number) => void
  ) {
    const {
      id,
      requestDate,
      requestNumber,
      customer,
      carrier,
      quotationNumber,
      quotationDate,
      price,
      status,
    } = quotation;
    return {
      requestDate: requestDate ? Data.getFormdatedDate(requestDate) : "",
      requestNumber,
      customer,
      carrier,
      quotationNumber,
      quotationDate: Data.getFormdatedDate(quotationDate),
      totalAmount: price,
      status: <ShipmentStatus {...status} />,
      action: (
        <div className="ds-circle-icon-layout d-flex justify-center">
          <i
            className="bi bi-eye cursor-pointer ds-grey-blue-color"
            onClick={() => {
              if (id) {
                onShowQuotation(id);
              }
            }}
          />
        </div>
      ),
    };
  }
}
export default Data;
