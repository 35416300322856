import { useTranslation } from "../../../common/customHooks";
import { IHeaderTitleSeeAll } from "../../../interfaces";
import Button from "../button/Button";

const HeaderTitleSeeAll = ({
  className,
  style,
  title,
  onClick,
}: IHeaderTitleSeeAll) => {
  const { tr } = useTranslation();
  return (
    <div
      style={style}
      className={`d-flex justify-between px-3 ${className || ""}`}
    >
      <div
        style={{ fontSize: "13px", fontWeight: "bold", lineHeight: "28px" }}
        className="ds-font-poppins ds-black-color"
      >
        {title}
      </div>
      <Button
        style={{
          maxWidth: "82px",
          fontSize: "12px",
          height: "27px",
          fontWeight: "bold",
        }}
        className="ds-bg-secondary ds-black-color"
        icon={<i className="bi bi-chevron-right" />}
        isRtl
        onClick={onClick}
        text={tr("seeAll")}
      />
    </div>
  );
};
export default HeaderTitleSeeAll;
