import { useState } from "react";
import {
  Button,
  DaysTimePicker,
  InputSearch,
  PageTitle,
} from "../../../../../../components";
import {
  IDaysTimePickerState,
  IMofGeneralAdress,
} from "../../../../../../interfaces";
import { INIT_DAYS_TIME_PICKER_STATE } from "../../../../../../consts";
import { Data } from "../../../../../../common";
import { useModal, useTranslation } from "../../../../../../common/customHooks";

const MofGeneralAdress = ({
  generalAdressState,
  onChangeAdressState,
  onCreateEditAdress,
}: IMofGeneralAdress): JSX.Element => {
  const windowWidth = window.innerWidth;
  const { tr } = useTranslation();
  const modal = useModal();
  const [daysState, setDaysState] = useState<IDaysTimePickerState>(
    INIT_DAYS_TIME_PICKER_STATE
  );
  const handleSubmit = () => {
    onCreateEditAdress(daysState);
    setDaysState(INIT_DAYS_TIME_PICKER_STATE);
  };
  const handleCancel = () => {
    setDaysState(INIT_DAYS_TIME_PICKER_STATE);
  };
  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-between mb-2">
        <PageTitle
          titleStyle={{ fontSize: "16px" }}
          titleClassName="ds-mof-adrs-title"
          title={tr("adressesList")}
        />
        <Button
          className="ds-mof-add-adrs-btn"
          onClick={() =>
            modal.open({
              children: (
                <div className="ds-mof-modal-days-layout row">
                  <div className="col-md-8 col-12 p-3">
                    <DaysTimePicker
                      value={daysState}
                      onChange={(state) => setDaysState(state)}
                    />
                  </div>
                </div>
              ),
              options: {
                title: tr("editAdressCompany"),
                isFooterSubmit: true,
                onSubmit: handleSubmit,
                onCancel: handleCancel,
              },
            })
          }
          text={windowWidth > 768 ? tr("addAnotherAdress") : tr("add")}
          icon={<i className="bi bi-plus-lg ds-white-color" />}
        />
      </div>
      <div className="row mb-3">
        <div className="col-md-4 col-12">
          <InputSearch
            onClear={() =>
              onChangeAdressState({
                ...generalAdressState,
                searchedValue: "",
              })
            }
            onSelect={(value) =>
              onChangeAdressState({
                ...generalAdressState,
                searchedValue: value.name,
              })
            }
            searchFields={generalAdressState.searchFiels}
          />
        </div>
      </div>
      {/* <Table
        headerItemClassName="ds-bg-grey"
        columns={Data.getCompanyOfficeAdress(tr)}
        data={[]}
      /> */}
    </div>
  );
};
export default MofGeneralAdress;
