import { Tab, Tabs } from "react-bootstrap";
import { Card } from "../../components";
import { IManageOfficeTemplate } from "../../interfaces";
import { Data } from "../../common";
import { MofGeneral } from "./forms";
import { PageLayout } from "../../layouts";
import { useTranslation } from "../../common/customHooks";

const ManageOfficeTemplate = ({
  commonState,
  onChangeCommonState,
  generalState,
  onChangeGeneralState,
  onDeleteCompanyImage,
  onChangeCompanyImage,
  onUpdateCompany,
  generalAdressState,
  onChangeAdressState,
  onCreateEditAdress,
}: IManageOfficeTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const mofData: Array<React.ReactNode> = [
    <MofGeneral
      state={generalState}
      onChangeState={onChangeGeneralState}
      onDeleteCompanyImage={onDeleteCompanyImage}
      onChangeCompanyImage={onChangeCompanyImage}
      onUpdateCompany={onUpdateCompany}
      generalAdressState={generalAdressState}
      onChangeAdressState={onChangeAdressState}
      onCreateEditAdress={onCreateEditAdress}
    />,
  ];
  return (
    <PageLayout title={tr("manageOffice")}>
      <Card
        isScroll
        style={{ height: "92%" }}
        bodyClassName="pt-0 ds-ps-md-5"
        headerClassName="pb-0"
        header={
          <Tabs
            defaultActiveKey={commonState.selectedTabIndex}
            className="mb-3"
            onSelect={(key) =>
              onChangeCommonState({ selectedTabIndex: parseInt(key!) })
            }
          >
            {Data.getManageOfficeTabsData(tr).map((tabName, index) => (
              <Tab key={index} eventKey={index} title={tabName} />
            ))}
          </Tabs>
        }
      >
        {mofData[commonState.selectedTabIndex]}
      </Card>
    </PageLayout>
  );
};
export default ManageOfficeTemplate;
