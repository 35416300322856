import { useTranslation } from "../../../../common/customHooks";
import {
  Button,
  Checkbox,
  InputSelect,
  PageTitle,
} from "../../../../components";
import { IPreference } from "../../../../interfaces";

const Preference = ({
  state,
  onChangeState,
  onSubmit,
}: IPreference): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 h-100">
      <PageTitle
        titleStyle={{ fontSize: "16px" }}
        className="mb-4"
        title={tr("preference")}
      />
      <div className="row mb-4">
        <div className="col-12 col-md-3">
          <InputSelect
            className="mb-1"
            label={tr("languages")}
            isObligatoryLabel
            isMulti={false}
            onChange={(event: any) =>
              onChangeState({ ...state, selectedLanguage: event?.value })
            }
            options={state.languagesOptions}
          />
          <InputSelect
            label={tr("timeZone")}
            isObligatoryLabel
            isMulti={false}
            onChange={(event: any) =>
              onChangeState({ ...state, selectedTimeZone: event?.value })
            }
            options={state.timeZoneOptions}
          />
        </div>
      </div>
      <Checkbox
        className="mb-3"
        isChecked
        onChange={(checked) =>
          onChangeState({ ...state, isNewsLetter: checked })
        }
        text={tr("newsLetterSubscriber")}
      />
      <Button className="mb-3" onClick={onSubmit} text={tr("saveChange")} />
    </div>
  );
};
export default Preference;
