import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
import { ITrackingDetailState } from "../../../interfaces";
import { useEffect, useState } from "react";
import { ShipmentAPI } from "../../../apis";
import { HttpStatusCode } from "axios";

const ShipmentTrackingDetails = ({ id }: { id: number }) => {
  const { tr } = useTranslation();
  const shipmentApi = new ShipmentAPI();
  const [trackingDetails, setTrackingDetails] = useState<
    Array<ITrackingDetailState>
  >([]);

  useEffect(() => {
    handleFetchDataAPI();
  }, [id]);

  const handleFetchDataAPI = async () => {
    try {
      const response = await shipmentApi.getShipmentTracking(id);
      if (response.status === HttpStatusCode.Ok) {
        const { trackings } = response.data;
        setTrackingDetails(
          (trackings as Array<any>)?.map(
            ({ date, tracking_city, tracking_country, status }) => ({
              adress: `${tracking_city}, ${tracking_country}`,
              date: new Date(date),
              status,
            })
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 col-12">
        {trackingDetails?.length >0 && trackingDetails.map((track, index) => (
          <div key={index} className="d-flex ds-font-poppins px-3 mb-3">
            <div
              style={{
                minWidth: "70px",
                height: "25px",
                padding: "0px 5px",
                lineHeight: "25px",
                border: "1px solid #696974",
                borderRadius: "5px",
              }}
              className={` text-center ds-font-poppins ds-black-color`}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "11px",
                }}
              >
                {track.status}
              </div>
            </div>
            <div style={{ fontSize: "13px" }} className="ms-3 w-100">
              {track.date !== null && (
                <>
                  <div className="ds-black-color">
                    {`${Data.getFormdatedDate(
                      track.date
                    )} ${Data.getTimeStringFromDate(track.date)}`}
                  </div>
                  <div className="ds-text-color">
                    {tr("adress")}: {track.adress}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ShipmentTrackingDetails;
