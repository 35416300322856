import { useNavigate } from "react-router-dom";
import { Data } from "../../common";
import { useTranslation } from "../../common/customHooks";
import {
  Button,
  Card,
  ColumnsDataMapping,
  RectangleCard,
  ShipmentStatus,
} from "../../components";
import { EButtonType } from "../../enums";
import { ITrackingDetailsTemplate } from "../../interfaces";
import { TrackingLayout } from "../../layouts";

const TrackingDetailsTemplate = ({
  trackingNumber,
  isNotFound,
  state,
  onPrintShipment,
}: ITrackingDetailsTemplate): JSX.Element => {
  const navigate = useNavigate();
  const { tr } = useTranslation();
  const { receiver, sender, shippingHistory } = state;

  return (
    <TrackingLayout>
      <Card
        isScroll
        style={{ height: "80vh" }}
        header={
          !isNotFound && (
            <div className="d-flex justify-between ds-font-poppins px-2">
              <div className="flex">
                <div
                  style={{ fontSize: "25px" }}
                  className="font-medium"
                >{`${tr("trackShipment")} :`}</div>
                <div style={{ fontSize: "25px" }} className="font-bold ms-2">
                  {trackingNumber}
                </div>
                {state.common?.status && (
                  <div className="ms-3 py-2">
                    <ShipmentStatus {...state.common.status} />
                  </div>
                )}
              </div>
              <Button
                style={{ height: "30px" }}
                icon={
                  <i
                    style={{ fontSize: "18px" }}
                    className="bi bi-printer-fill ds-white-color"
                  />
                }
                text={tr("printShipment")}
                onClick={onPrintShipment}
              />
            </div>
          )
        }
        headerClassName="py-3"
      >
        {!isNotFound ? (
          <div className="row">
            <div className="col-md-7 col-12">
              {sender && (
                <RectangleCard
                  className="mb-2"
                  bodyClassName="px-3"
                  title={tr("sender")}
                >
                  <ColumnsDataMapping
                    columns={Data.getTrackingSenderDataColumns(tr, state)}
                  />
                </RectangleCard>
              )}
              {receiver && (
                <RectangleCard className="mb-2" title={tr("receiver")}>
                  <ColumnsDataMapping
                    columns={Data.getTrackingReceiverDataColumns(tr, state)}
                  />
                </RectangleCard>
              )}
            </div>
            <div className="col-md-5 col-12">
              {shippingHistory && shippingHistory.length > 0 && (
                <RectangleCard className="mb-2" title={tr("shippingHistory")}>
                  {shippingHistory.map((history, index) => (
                    <div key={index} className="flex ds-font-poppins">
                      <div>
                        <div className="font-bold mb-1">
                          {history.date !== null &&
                            Data.getFormdatedDate(history.date)}
                        </div>
                        <div className="font-medium">
                          {history.time !== null &&
                            Data.getTimeStringFromDate(history.time)}
                        </div>
                      </div>
                      <div className="ms-2 font-normal">
                        {history.shippingStatus}
                      </div>
                    </div>
                  ))}
                </RectangleCard>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <div>
              <div
                style={{ fontSize: "96px" }}
                className="font-bold text-center"
              >
                {tr("oops") + " !"}
              </div>
              <div className="font-medium mb-4" style={{ fontSize: "25px" }}>
                {tr("trackingNumber")}
                <span className="px-2  ds-warning-color">
                  {trackingNumber?.toUpperCase()}
                </span>
                {tr("notFound")}
              </div>
              <div className="flex justify-center">
                <Button
                  style={{ height: "30px" }}
                  text={tr("tryAgain")}
                  onClick={() => navigate("/tracking")}
                />
                <Button
                  className="ms-2"
                  style={{ height: "30px" }}
                  type={EButtonType.secondary}
                  text={tr("returnToStart")}
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        )}
      </Card>
    </TrackingLayout>
  );
};
export default TrackingDetailsTemplate;
