import { useNavigate } from "react-router-dom";
import { HeaderTitleSeeAll } from "../../../../components";
import {
  IDashboardShipmentList,
  IShipmentItemState,
} from "../../../../interfaces";
import { Data } from "../../../../common";
import { useTranslation } from "../../../../common/customHooks";
import { useEffect, useMemo, useState } from "react";
import { HttpStatusCode } from "axios";
import { ShipmentAPI } from "../../../../apis";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const ShipmentList = ({ className, style }: IDashboardShipmentList) => {
  const { tr } = useTranslation();
  const navigate = useNavigate();
  const shipmentApi = new ShipmentAPI();
  const [shipments, setShipments] = useState<Array<IShipmentItemState>>([]);

  const data = useMemo(
    () => shipments.map((shipment) => Data.getShipmentListRowsData(shipment)),
    [shipments]
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getDashboardShipmentListDataColumns(tr),
    [tr]
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
  });

  useEffect(() => {
    handleFetchDataAPI();
  }, []);

  const handleFetchDataAPI = async () => {
    try {
      const response = await shipmentApi.geShipmentsPagination({
        pageNumber: 1,
        perPage: 4,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setShipments(
          (data?.data as Array<any>)?.map((data) =>
            DataFetchAPI.fetchShipmentModel(data)
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{ borderRadius: "10px", overflow: "hidden", ...style }}
      className={`w-100 ds-bg-white py-2 ${className || ""}`}
    >
      <HeaderTitleSeeAll
        className="p-1 mb-2"
        onClick={() => navigate("/shipment-list")}
        title={tr("shipmentList")}
      />
      <MaterialReactTable table={table} />
    </div>
  );
};
export default ShipmentList;
