import { useState } from "react";
import {
  IModalContextProps,
  IModalProvider,
  IOpenModalProvider,
  IPageProps,
} from "../../interfaces";
import { ModalContext } from "../../contexts";
import { INIT_MODAL_PROVIDER_STATE } from "../../consts";
import { Modal } from "../../components";

const ModalProvider = ({ children }: IPageProps): JSX.Element => {
  const [state, setState] = useState<IModalProvider>(INIT_MODAL_PROVIDER_STATE);

  const handleOpen = (payload: IOpenModalProvider) =>
    setState({ ...payload, isOpen: true });
  const handleClose = () => setState(INIT_MODAL_PROVIDER_STATE);

  const props: IModalContextProps = {
    open: handleOpen,
    close: handleClose,
  };

  return (
    <ModalContext.Provider value={props}>
      <Modal isOpen={state.isOpen} onClose={handleClose} {...state.options}>
        {state.children}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
export default ModalProvider;
