import { IInputSelect } from "../../../interfaces";
import { Label } from "../../atoms";
import Select from "react-select";

const InputSelect = ({
  className,
  style,
  label,
  isObligatoryLabel,
  isMulti,
  options,
  value,
  onChange,
  placeholder,
}: IInputSelect): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          className="mb-2"
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      <Select
        isMulti={isMulti}
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={(e: any) => onChange(isMulti ? e : [e])}
      />
    </div>
  );
};
export default InputSelect;
