import Checkbox from "../checkbox/Checkbox";
import { useState, useEffect } from "react";
import { TimePicker } from "..";
import { IDayTimePicker } from "../../../interfaces";
import { INIT_DAY_TIME_PICKER_STATE } from "../../../consts";
import { useTranslation } from "../../../common/customHooks";
import { TDayTimePickerState } from "../../../types";

const DayTimePicker = ({
  className,
  style,
  day,
  value,
  onChange,
}: IDayTimePicker): JSX.Element => {
  const { tr } = useTranslation();
  const commonTime = "00:00";
  const [state, setState] = useState<TDayTimePickerState>({
    ...INIT_DAY_TIME_PICKER_STATE,
    ...value,
  });
  useEffect(() => {
    onChange && onChange(state);
  }, [state]);

  return (
    <div
      style={style}
      className={`${className || ""} w-100 d-flex justify-between flex-wrap`}
    >
      <div className="d-flex">
        <Checkbox
          isChecked={state.isSelected}
          onChange={(checked) => {
            setState({ ...state, isSelected: checked });
          }}
        />
        <div
          style={{ fontSize: "14px", lineHeight: "28px", fontWeight: "bold" }}
          className="ds-font-poppins ds-black-color ps-2"
        >
          {day}
        </div>
      </div>
      <div className="d-flex justify-between">
        <TimePicker
          onChange={(value) => {
            setState({ ...state, startTime: value });
          }}
          value={state.startTime || commonTime}
        />
        <div
          style={{ fontSize: "12px", fontWeight: "300", lineHeight: "38px" }}
          className="px-2 ds-font-poppins ds-black-color"
        >
          {tr("until")}
        </div>
        <TimePicker
          onChange={(value) => {
            setState({ ...state, endTime: value });
          }}
          value={state.endTime || commonTime}
        />
      </div>
    </div>
  );
};
export default DayTimePicker;
