import { useState } from "react";
import { IResetPasswordState } from "../../interfaces";
import { ResetPasswordTemplate } from "../../templates";
import { INIT_RESET_PASSWORD_STATE } from "../../consts";
import { useNavigate } from "react-router-dom";
import { EAuthType } from "../../enums";

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [resetPasswordState, setResetPasswordState] =
    useState<IResetPasswordState>(INIT_RESET_PASSWORD_STATE);
  const handleSubmit = async () => {
    if (resetPasswordState.type === EAuthType.resetTokenExpired) {
      navigate("/forgot-password");
    }
  };
  return (
    <ResetPasswordTemplate
      state={resetPasswordState}
      onChangeState={setResetPasswordState}
      onSubmit={handleSubmit}
    />
  );
};
export default ResetPassword;
