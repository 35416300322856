import { HttpStatusCode } from "axios";
import { ShipmentAPI } from "../../../../apis";
import { useTranslation } from "../../../../common/customHooks";
import {
  ShipmentBookingTable,
  ShipmentDetailsTable,
  ShipmentDetailsTrackingMap,
  ToggledCard,
} from "../../../../components";
import { useEffect, useState } from "react";
import { IShipmetInfoModel } from "../../../../interfaces";

const ShowShShipments = ({ id }: any): JSX.Element => {
  const { tr } = useTranslation();
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<IShipmetInfoModel>();

  useEffect(() => {
    if (id) {
      handleFetchApiData(id);
    }
  }, [id]);

  const handleFetchApiData = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentInfo(id);
      if (response.status === HttpStatusCode.Ok) {
        const {
          shipper,
          consignee,
          notify1,
          notify2,
          agent,
          departure,
          arrival,
        } = response.data;
        setState({
          shipper,
          consignee,
          firstNotify: notify1,
          secondNotify: notify2,
          agent,
          departure,
          arrival,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="row">
      <div className="col-md-8 col-12">
        <ToggledCard
          className="mb-4"
          bodyStyle={{ padding: 0, overflow: "hidden" }}
          title={tr("booking")}
          isBlocked
        >
          {state && <ShipmentBookingTable state={state} />}
        </ToggledCard>
        <ToggledCard
          className="mb-4"
          bodyStyle={{ padding: 0, overflow: "hidden" }}
          title={tr("shipmentDetails")}
          isBlocked
        >
          {state && <ShipmentDetailsTable state={state} />}
        </ToggledCard>
      </div>
      <div
        className="col-md-4 col-12"
        style={{ minHeight: "50px", maxHeight: "50vh" }}
      >
        <ShipmentDetailsTrackingMap id={id} />
      </div>
    </div>
  );
};
export default ShowShShipments;
