import { IShipmentStatus } from "../../../interfaces";

const ShipmentStatus = ({
  className,
  style,
  backgroundColor,
  color,
  text,
}: IShipmentStatus): JSX.Element => {
  return (
    <div
      style={{
        color,
        backgroundColor,
        ...style,
      }}
      className={`text-center ds-shit-status px-2 ${className}`}
    >
      {text}
    </div>
  );
};
export default ShipmentStatus;
