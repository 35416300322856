import { useTranslation } from "../../common/customHooks";
import { StatisticalCard, UserAppStatus } from "../../components";
import { IHomeTemplate } from "../../interfaces";
import { PageLayout } from "../../layouts";
import { DashboardChart, RecentQuotation, ShipmentList } from "./forms";

const DashboardTemplate = ({ userName }: IHomeTemplate): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <PageLayout title={`${tr("hello")} ${userName} !`}>
      <UserAppStatus
        className="mb-3 user-app-status dash-user-status"
        percentage={60}
      />
      <div className="row">
        <div className="col-md-6 col-lg-3 col-12 ds-mb-md-2 ds-mb-xs-2">
          <StatisticalCard
            className="dash-total-shipment"
            icon={<i className="bi bi-box-seam-fill" />}
            statisticalNumber={54}
            statisticalName={tr("totalShipments")}
            percentage={20}
            type="uper"
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12 ds-mb-md-2 ds-mb-xs-2">
          <StatisticalCard
            className="dash-delivered-shipments"
            icon={<i className="bi bi-clipboard-check-fill" />}
            statisticalNumber={25}
            statisticalName={tr("deliveredShipments")}
            percentage={25}
            type="lower"
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12 ds-mb-md-2 ds-mb-xs-2">
          <StatisticalCard
            className="dash-rejected-shipments"
            icon={<i className="bi bi-x-octagon-fill" />}
            statisticalNumber={25}
            statisticalName={tr("rejected")}
            percentage={25}
            type="lower"
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12 ds-mb-md-2 ds-mb-xs-2">
          <StatisticalCard
            className="dash-shipping-spend"
            icon={<i className="bi bi-coin" />}
            statisticalNumber={25}
            statisticalName={tr("shippingSpend")}
            percentage={25}
            type="uper"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <DashboardChart className="dash-chart" />
        </div>
        <div className="col-md-6 col-12 ds-mb-xs-2">
          <RecentQuotation className="dash-recent-quotation" />
        </div>
      </div>
      <ShipmentList className="dash-shipment-list" />
    </PageLayout>
  );
};
export default DashboardTemplate;
