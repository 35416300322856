import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { IShipmetInfoModel } from "../../../interfaces";
import { useMemo } from "react";
import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";

const ShipmentBookingTable = ({ state }: { state: IShipmetInfoModel }) => {
  const { shipper, consignee, firstNotify, secondNotify, agent } = state;
  const { tr } = useTranslation();

  const data = useMemo(
    () =>
      Object.values({
        shipper,
        consignee,
        firstNotify,
        secondNotify,
        agent,
      }).map(({ name, address, email, phone }: any, index) => ({
        index: [
          tr("shipper"),
          tr("consignee"),
          tr("firstNotify"),
          tr("secondNotify"),
          tr("agent"),
        ][index],
        name,
        address,
        email,
        phone,
      })),
    [state, tr]
  );
  
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getShipmentInfoDataColums(tr),
    [tr]
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
  });

  return <MaterialReactTable table={table} />;
};
export default ShipmentBookingTable;
