import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/main.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Profiler
      id="App"
      onRender={(id, phase, actualDuration) =>
        console.log(
          `Component ID: ${id} | Phase: ${phase} | Duration: ${actualDuration} ms`
        )
      }
    >
      <App />
    </Profiler>
  </React.StrictMode>
);
