import {
  Button,
  InfinityScroll,
  PageTitle,
  RequestCard,
  SearchFilter,
} from "../../components";
import { IRequestForQuotationTemplate } from "../../interfaces";
import { useDrawer, useTranslation } from "../../common/customHooks";
import { useNavigate } from "react-router-dom";
import { ShowRequestCard, ShowRequestOnMap } from "./forms";

const RequestListTemplate = ({
  state,
  selectedRequestId,
  searchFields,
  onChangeState,
  onChangeSearchedValue,
  onSelectRequest,
}: IRequestForQuotationTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const navigate = useNavigate();
  const drawer = useDrawer();
  return (
    <>
      <div className="w-100 d-flex justify-between mb-2">
        <PageTitle title={tr("requestList")} />
        <div className="d-none d-md-block">
          <div className="d-flex">
            <Button
              className="ds-sl-button mx-2"
              style={{
                cursor: !selectedRequestId ? "not-allowed" : "pointer",
              }}
              isDisabled={!selectedRequestId}
              icon={<i className="bi bi-geo-fill" />}
              onClick={() =>
                drawer.open({
                  title: tr("showOnMap"),
                  children: <ShowRequestOnMap id={selectedRequestId} />,
                })
              }
              text={tr("showOnMap")}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "92%" }} className="row">
        <div className="col-12 col-md-5 h-100 ds-mb-xs-2">
          <SearchFilter
            className="mb-2"
            onChangeSearchedValue={onChangeSearchedValue}
            onSelect={({ id }) => navigate(`/show-request/${id}`)}
            searchFields={searchFields}
          />
          <InfinityScroll
            className="ds-shipment-list-infity-scroll-container"
            onNext={() =>
              onChangeState({ ...state, pageNumber: state.pageNumber + 1 })
            }
            {...state}
          >
            {state.requests.map((request, index) => (
              <RequestCard
                key={index}
                className="mb-2"
                {...request}
                isSelected={state.requests[index]?.id === selectedRequestId}
                onClick={() => {
                  const id = state.requests[index].id;
                  if (id) {
                    onSelectRequest(id);
                  }
                }}
              />
            ))}
          </InfinityScroll>
        </div>
        <div className="col-md-7 col-12 d-none d-md-block">
          {selectedRequestId && <ShowRequestCard id={selectedRequestId} />}
        </div>
      </div>
    </>
  );
};
export default RequestListTemplate;
