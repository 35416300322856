import { useState } from "react";
import { Data } from "../../../../common";
import { VerticalNavItems } from "../../../../components";
import { IMofGeneral } from "../../../../interfaces";
import { MofGeneralAdress, MofGeneralCompany } from "./forms";
import { useTranslation } from "../../../../common/customHooks";

const MofGeneral = (props: IMofGeneral) => {
  const { tr } = useTranslation();
  const [selectedNavIndex, setSelectedNavIndex] = useState<number>(0);
  const forms: Array<React.ReactNode> = [
    <MofGeneralCompany {...props} />,
    <></>,
    <></>,
    <MofGeneralAdress {...props} />,
  ];
  return (
    <div className="w-100 h-100">
      <div className="row">
        <div className="d-none d-md-block col-md-3 col-12">
          <VerticalNavItems
            items={Data.getMofGeneralVerticalNavItems(tr)}
            selectedIndex={selectedNavIndex}
            onSelect={(index) => setSelectedNavIndex(index)}
          />
        </div>
        <div className="col-md-9 col-12 px-2">
          <div className="container-fluid ds-mo-general-content">
            {forms[selectedNavIndex]}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MofGeneral;
