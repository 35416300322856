import { useState } from "react";
import { IForgotPasswordState } from "../../interfaces";
import { ForgotPasswordTemplate } from "../../templates";
import { INIT_FORGOT_PASSWORD_STATE } from "../../consts";
import { EAuthType } from "../../enums";
import { AuthAPI } from "../../apis";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "../../common/customHooks";

const ForgotPassword = (): JSX.Element => {
  const { tr } = useTranslation();
  const authApi = new AuthAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forgotPasswordState, setForgotPasswordState] =
    useState<IForgotPasswordState>(INIT_FORGOT_PASSWORD_STATE);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await authApi.forgotPassword(forgotPasswordState.email);
      if (response.status === HttpStatusCode.Ok) {
        toast.success(tr("emailSentSuccessfuly"));
        setForgotPasswordState({
          ...forgotPasswordState,
          type: EAuthType.emailSentSuccessfully,
        });
      }
    } catch (err: any) {
      console.error(err);
      toast.error(
        err.response && err.response.status === HttpStatusCode.NotFound
          ? tr("emailNotFound")
          : tr("internalServerError")
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ForgotPasswordTemplate
      isLoading={isLoading}
      email={forgotPasswordState.email}
      type={forgotPasswordState.type}
      onChangeEmail={(value) =>
        setForgotPasswordState({ ...forgotPasswordState, email: value })
      }
      onSubmit={handleSubmit}
    />
  );
};
export default ForgotPassword;
