import { useState } from "react";
import { IAuthState } from "../../interfaces";
import { INIT_AUTH_STATE } from "../../consts";
import { LoginTemplate } from "../../templates";
import { AuthAPI } from "../../apis";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { useAuthentification, useTranslation } from "../../common/customHooks";

const Login = (): JSX.Element => {
  const { tr } = useTranslation();
  const auth = useAuthentification();
  const authApi = new AuthAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authState, setAuthState] = useState<IAuthState>(INIT_AUTH_STATE);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await authApi.login(authState);
      if (response.status === HttpStatusCode.Ok) {
        const { authorisation, userId } = response.data;
        const { accessToken, refreshToken } = authorisation;
        auth.setState(accessToken, refreshToken, userId);
      }
    } catch (err: any) {
      console.error(err);
      toast.error(
        err?.response?.status === HttpStatusCode.Unauthorized
          ? tr("invalidEmailOrPassword")
          : tr("internalServerError")
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginTemplate
      isLoading={isLoading}
      authState={authState}
      onSubmit={handleSubmit}
      onChangeState={setAuthState}
    />
  );
};
export default Login;
