class Config {
  SOCKET_URL = "";
  API_URL = "https://api.colisnet.dev/api";
  PRIMARY_COLOR = "#3AB4A0";
  SECONDARY_COLOR = "#ECF4FC";

  private static instance: Config;
  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }
}

export default Config;
