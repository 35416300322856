import { Link } from "react-router-dom";
import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";

const LayoutFooter = (): JSX.Element => {
  const { tr } = useTranslation();
  const items = Data.getFooterItems(tr);
  return (
    <footer className="ds-bg-secondary ds-footer">
      <ul className="d-flex justify-center menu py-2">
        {items.map((item, index) => (
          <div key={index} className="d-flex">
            <Link to={item.href} className="menu-item px-1">
              {item.label}
            </Link>
            {index !== items.length - 1 && <li>|</li>}
          </div>
        ))}
      </ul>
    </footer>
  );
};
export default LayoutFooter;
