import { LangContext } from "../../contexts";
import { IPageProps } from "../../interfaces";
import { useLanguages } from "../../common/customHooks";
export default function LangProvider(props: IPageProps): JSX.Element {
  const lang = useLanguages();
  const { isRtl } = lang;
  const type = lang.getLangData();
  return (
    <LangContext.Provider value={type}>
      <div
        style={{ height: "100vh" }}
        className="w-100"
        dir={isRtl ? "rtl" : "ltr"}
      >
        {props.children}
      </div>
    </LangContext.Provider>
  );
}
