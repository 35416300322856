import { AxiosResponse } from "axios";
import { Config } from "../../common";
import { IAuthState } from "../../interfaces/states";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import { TokenEnum } from "../../enums";
import { getApiAxios } from "../../common/functions/GetApiAxios";

class AuthAPI {
  private _api = getApiAxios({
    baseUrl: Config.getInstance().API_URL + "/auth",
    headers: handleHeaders(TokenEnum.noToken),
    isRefreshToken: false,
  });

  async login(payload: IAuthState): Promise<AxiosResponse> {
    return this._api.post("/login", {
      email: payload.email,
      password: payload.password,
    });
  }

  async forgotPassword(email: string): Promise<AxiosResponse> {
    return this._api.post("/forgot-password", {
      email,
    });
  }

  async refreshToken(): Promise<AxiosResponse> {
    return this._api.get("refresh", {
      headers: handleHeaders(TokenEnum.refreshToken),
    });
  }
}
export default AuthAPI;
