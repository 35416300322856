import { useTranslation } from "../../../../../../common/customHooks";
import { DatePicker, Input, TitleResponse } from "../../../../../../components";
import { ICreateQuotationTemplate } from "../../../../../../interfaces";

const CqCarrierInformation = ({
  state,
  onChangeState,
}: ICreateQuotationTemplate) => {
  const {
    quotationNumber,
    quotationReference,
    etd,
    eta,
    intercom,
    from,
    to,
    place,
  } = state.carrierInfo;
  const { tr } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <div className="mb-2">
          {/* <TitleResponse
            title={tr("quotationNumber")}
            childrenResponse={
              <Input
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({
                    ...state,
                    carrierInfo: {
                      ...state.carrierInfo,
                      quotationNumber: value,
                    },
                  })
                }
                value={quotationNumber}
              />
            }
          />*/}
        </div>
        <div className="mb-2">
          {/* <TitleResponse
            title={tr("quotationReference")}
            childrenResponse={
              <Input
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({
                    ...state,
                    carrierInfo: {
                      ...state.carrierInfo,
                      quotationReference: value,
                    },
                  })
                }
                value={quotationReference}
              />
            }
          /> */}
        </div>

        <TitleResponse title={tr("intercom")} response={intercom} />
      </div>
      <div className="col-md-6 col-12">
        <div className="d-flex justify-between mb-2">
          <TitleResponse title={tr("from")} response={from} />
          <TitleResponse title={tr("to")} response={to} />
        </div>
        <div className="d-flex justify-between mb-2">
          {/* <TitleResponse
            title={tr("ETD")}
            childrenResponse={
              <DatePicker
                onChange={(value) =>
                  (value instanceof Date || value === null) &&
                  onChangeState({
                    ...state,
                    carrierInfo: {
                      ...state.carrierInfo,
                      etd: value,
                    },
                  })
                }
                value={etd}
              />
            }
          /> */}
          {/* <TitleResponse
            title={tr("ETA")}
            childrenResponse={
              <DatePicker
                onChange={(value) =>
                  (value instanceof Date || value === null) &&
                  onChangeState({
                    ...state,
                    carrierInfo: {
                      ...state.carrierInfo,
                      eta: value,
                    },
                  })
                }
                value={eta}
              />
            }
          /> */}
        </div>
        <TitleResponse title={tr("place")} response={place} />
      </div>
    </div>
  );
};
export default CqCarrierInformation;
