import Avatar from "react-avatar";
import { Dropdown, Navbar } from "react-bootstrap";
import { logo, logoMobile } from "../../../assets";
import { Link } from "react-router-dom";
import {
  CustomToggledDropItem,
  NavUserDropItems,
  NotificationBadge,
  SelectLanguages,
  UserAvatar,
} from "../../../components";
import { INavbar } from "../../../interfaces";
import { Data } from "../../../common";
import {
  useAuthentification,
  useFullScreen,
  useLanguages,
  useTranslation,
} from "../../../common/customHooks";
import { NavIconLayout } from "../../../components";

const LayoutNavbar = ({
  auth,
  onChangeToggled,
  isRtl,
}: INavbar): JSX.Element => {
  const { tr } = useTranslation();
  const language = useLanguages();
  const authentification = useAuthentification();
  const { isActive, enter, exit } = useFullScreen();
  const dropAlign = isRtl ? "start" : "end";

  return (
    <Navbar className="ds-bg-white d-flex justify-between px-1">
      <Navbar.Brand className="px-2">
        <Link to="/" className="d-none d-md-block sidebar">
          <img alt="app-logo" className="ds-navbar-img" src={logo} />
        </Link>
        <div className="d-md-none d-xs-block d-flex">
          <Link to="/">
            <img alt="app-logo" style={{ height: "30px" }} src={logoMobile} />
          </Link>
          <i
            className="bi bi-list ps-3 ds-grey-blue-color"
            onClick={() => onChangeToggled(true)}
          />
        </div>
      </Navbar.Brand>
      <div className="d-flex pe-1">
        <NavIconLayout className="mx-1">
          <i
            onClick={() => (!isActive ? enter() : exit())}
            className={`bi ${
              !isActive ? "bi-arrows-fullscreen" : "bi-fullscreen-exit"
            } cursor-pointer pt-1 ds-black-color`}
          />
        </NavIconLayout>
        <NavIconLayout className="mx-1">
          <NotificationBadge count={5} />
        </NavIconLayout>
        <Dropdown className="mx-1" drop="down" align={dropAlign}>
          <Dropdown.Toggle
            as={CustomToggledDropItem}
            item={
              <NavIconLayout className="pt-1">
                <Avatar size="20" src={language.getStoredLangFlag()} round />{" "}
              </NavIconLayout>
            }
          />
          <Dropdown.Menu style={{ marginTop: "28px" }} className="p-0">
            <Dropdown.Item className="p-0">
              <SelectLanguages options={Data.getLanguagesDropDataItems(tr)} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          className="px-1"
          drop="down"
          align={dropAlign}
        >
          <Dropdown.Toggle
            className="py-5"
            id="dropdown-toggle"
            as={CustomToggledDropItem}
            item={<UserAvatar size="30" />}
          />
          <Dropdown.Menu style={{ marginTop: "28px" }}>
            <Dropdown.Item className="p-0">
              <NavUserDropItems
                links={Data.getNavUserLinks(tr)}
                onLogOut={() => authentification.logout()}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  );
};
export default LayoutNavbar;
