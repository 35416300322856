import { ISelectSquares } from "../../../interfaces";
import { Label } from "../../atoms";
import { SelectSquareItem } from "../../molecules";

const SelectSquares = ({
  className,
  style,
  options,
  onSelect,
  label,
  isObligatoryLabel,
  selectedIndex,
}: ISelectSquares): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          className="mb-2"
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      <div className="d-flex flex-wrap">
        {options.map((option, index) => (
          <div key={index} className="m-1">
            <SelectSquareItem
              title={option.title}
              icon={option.icon}
              isSelected={selectedIndex === index}
              onSelect={() => onSelect(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default SelectSquares;
