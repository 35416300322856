import { useEffect, useState } from "react";
import { TrackingDetailsTemplate } from "../../templates";
import {
  ITrackingDetailsCommonState,
  ITrackingDetailsReceiverState,
  ITrackingDetailsShippingHistory,
  ItrackingDetailsSenderState,
} from "../../interfaces";
import { useParams } from "react-router-dom";
import { PublicAPI } from "../../apis";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../common/data/DataFetchApi";

const TrackingDetails = () => {
  const { trackNumber } = useParams();
  const publicApi = new PublicAPI();
  const [state, setState] = useState<ITrackingDetailsCommonState | undefined>(
    undefined
  );
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [sender, setSender] = useState<ItrackingDetailsSenderState | undefined>(
    undefined
  );
  const [receiver, setReceiver] = useState<
    ITrackingDetailsReceiverState | undefined
  >(undefined);
  const [shippingHistory, setShippingHistory] = useState<
    Array<ITrackingDetailsShippingHistory>
  >([]);

  useEffect(() => {
    handleFetchApiData();
    handleFetchSenderDataAPI();
    handleFetchReceiverDataAPI();
    handleFetchShippingHistoryDataAPI();
  }, [trackNumber]);

  const handleFetchApiData = async () => {
    try {
      const response = await publicApi.trackShipment(trackNumber!);
      if (response.status === HttpStatusCode.Ok) {
        const { shipment_number, cfg_gen_status_order } = response.data;
        setState({
          shipmentNumber: shipment_number,
          status: {
            text: cfg_gen_status_order?.status_name,
            backgroundColor: cfg_gen_status_order?.background_color,
            color: cfg_gen_status_order?.text_color,
          },
        });
      }
    } catch (err: any) {
      if (err.response && err.response.status === HttpStatusCode.NotFound) {
        setIsNotFound(true);
      }
      console.error(err);
    }
  };

  const handleFetchShippingHistoryDataAPI = async () => {
    try {
      const response = await publicApi.trackShipmentHistory(trackNumber!);
      if (response.status === HttpStatusCode.Ok) {
        setShippingHistory(
          DataFetchAPI.fetchPublicTrackingShippingHistory(response.data)
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchReceiverDataAPI = async () => {
    try {
      const response = await publicApi.trackShipmentReceiver(trackNumber!);
      if (response.status === HttpStatusCode.Ok) {
        setReceiver(
          DataFetchAPI.fetchPublicTrackingDetailsReceiver(response.data)
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchSenderDataAPI = async () => {
    try {
      const response = await publicApi.trackShipmentSender(trackNumber!);
      if (response.status === HttpStatusCode.Ok) {
        setSender(DataFetchAPI.fetchPublicTrackingDetailsSender(response.data));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrintShipment = () => {};
  return (
    <TrackingDetailsTemplate
      trackingNumber={trackNumber}
      isNotFound={isNotFound}
      state={{ receiver, sender, shippingHistory, common: state }}
      onPrintShipment={handlePrintShipment}
    />
  );
};
export default TrackingDetails;
