import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import L from "leaflet";
import { markerIcon } from "../../../assets";
import { IMapTrackingFromTo } from "../../../interfaces";

const CUSTOM_MARKER = L.icon({
  iconUrl: markerIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

const MapTrackingFromTo = ({ className, from, to }: IMapTrackingFromTo) => {
  return (
    <MapContainer
      className={className}
      center={from ? [from.lat, from.lng] : [35.75, -5.7]}
      zoom={4}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {from && to && (
        <>
          <Marker position={from} icon={CUSTOM_MARKER} />
          <Marker position={to} icon={CUSTOM_MARKER} />
          <Polyline positions={[from, to]} pathOptions={{ color: "red" }} />
        </>
      )}
    </MapContainer>
  );
};
export default MapTrackingFromTo;
