import { useEffect, useState } from "react";
import { useTranslation } from "../../../../common/customHooks";
import { DocumentsList, ToggledCard } from "../../../../components";
import { ShipmentAPI } from "../../../../apis";
import { IDocumentListState } from "../../../../interfaces";
import { INIT_DOCUMENT_LIST_STATE } from "../../../../consts";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";

const ShowShDocuments = ({ id }: { id?: number }): JSX.Element => {
  const { tr } = useTranslation();
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<IDocumentListState>(
    INIT_DOCUMENT_LIST_STATE
  );

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentDocumentsPagination({
        ...state,
        id,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data, last_page } = response.data;
        setState({
          ...state,
          pageNumber: last_page,
          documents: (data as Array<any>).map((data) =>
            DataFetchAPI.fetchDocumentModel(data)
          ),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard
      title={tr("documents")}
      bodyStyle={{ padding: 0, overflow: "hidden" }}
      isBlocked
    >
      {state.documents.length > 0 && (
        <DocumentsList
          documents={state.documents}
          isPagination
          onPageChange={(pageNumber) => setState({ ...state, pageNumber })}
          pageCount={state.paginationLength}
          selectedPageNumber={state.pageNumber}
        />
      )}
    </ToggledCard>
  );
};
export default ShowShDocuments;
