import { Stepper, Step } from "react-form-stepper";
import { IStepper } from "../../../interfaces";

const CustomStepper = ({
  className,
  elements,
  selectedIndex,
  onChange,
}: IStepper) => {
  const widowWidth = window.innerWidth;
  return (
    <Stepper
      styleConfig={{
        activeBgColor: "#3AB4A0",
        activeTextColor: "#FFF",
        borderRadius: "50%",
        circleFontSize: widowWidth > 768 ? "15px" : "12px",
        completedBgColor: "#3AB4A0",
        completedTextColor: "#FFF",
        fontWeight: "25px",
        inactiveBgColor: "#FFF",
        inactiveTextColor: "#000000",
        labelFontSize: "25px",
        size: widowWidth > 768 ? "35px" : "25px",
      }}
      className={className}
      activeStep={selectedIndex}
      nonLinear
    >
      {elements.map((element, index) => (
        <Step key={index} label={element} onClick={() => onChange(index)} />
      ))}
    </Stepper>
  );
};
export default CustomStepper;
