import { IVerticalNavItems } from "../../../interfaces";

const VerticalNavItems = ({
  className,
  style,
  items,
  selectedIndex,
  onSelect,
}: IVerticalNavItems): JSX.Element => {
  return (
    <div
      style={{ borderRadius: "15px", ...style }}
      className={`${className} w-100 ds-bg-secondary p-3`}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`px-2 cursor-pointer ds-vertical-nav-item ${
            index === selectedIndex && "ds-selected-vertical-tem"
          } ${index !== items.length - 1 && "mb-1"}`}
          onClick={() => onSelect(index)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
export default VerticalNavItems;
