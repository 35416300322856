import { useEffect, useState } from "react";
import { ShowShipmentTemplate } from "../../templates";
import { IShipmentItemState } from "../../interfaces";
import { useParams } from "react-router-dom";
import { ShipmentAPI } from "../../apis";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../common/data/DataFetchApi";

const ShowShipment = (): JSX.Element => {
  const { id } = useParams();
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<IShipmentItemState | undefined>(undefined);

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(parseInt(id));
    }
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentById(id);
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setState(DataFetchAPI.fetchShipmentModel(data));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ShowShipmentTemplate
      id={id ? parseInt(id) : undefined}
      state={state}
      onSuccessAddedClaim={() => id && handleFetchDataAPI(parseInt(id))}
      onSuccessAddedCancel={() => id && handleFetchDataAPI(parseInt(id))}
    />
  );
};
export default ShowShipment;
