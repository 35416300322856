import { MapTrackingFromTo } from "../../components";
import { IMapPosition } from "../../interfaces";

const ShipmentRequestMapTrackingTemplate = ({
  from,
  to,
}: {
  from?: IMapPosition;
  to?: IMapPosition;
}) => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <MapTrackingFromTo from={from} to={to} />
    </div>
  );
};

export default ShipmentRequestMapTrackingTemplate;
