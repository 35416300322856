import { Data } from "../../common";
import { useTranslation } from "../../common/customHooks";
import {
  Button,
  Card,
  PageTitle,
  TextLink,
  TitleResponse,
} from "../../components";
import { EButtonType } from "../../enums";
import {
  IMultiInterfaceUsedButton,
  ISimulationResultsTemplate,
} from "../../interfaces";

const SimulationResultsTemplate =
  ({}: ISimulationResultsTemplate): JSX.Element => {
    const { tr } = useTranslation();
    const ButtonEditRequest = ({ isMobile }: IMultiInterfaceUsedButton) => {
      return (
        <div className="d-flex justify-end">
          <Button
            className={`ds-save-simulation-btn ${
              isMobile && "ds-bg-secondary"
            }`}
            onClick={() => {}}
            icon={<i className="bi bi-folder-fill" />}
            textClassName="ps-2"
            text={tr("edit")}
            type={EButtonType.secondary}
          />
        </div>
      );
    };
    return (
      <div className="w-100 h-100">
        <PageTitle className="mb-1" title={tr("simulationResults")} />
        <Card className="px-2 py-1 mb-2" style={{ minHeight: "0px" }}>
          <div className="row mb-1">
            <div className="col-6 col-md-12">
              <PageTitle
                titleClassName="ds-title-card"
                title={tr("simulationSummary")}
              />
            </div>
            <div className="col-6 d-md-none d-xs-block">
              <ButtonEditRequest isMobile />
            </div>
          </div>
          <div className="row mx-2">
            <div className="col-md-4 col-12">
              <TitleResponse title={tr("package")} response="" />
              <TitleResponse title={tr("between")} response="" />
              <TextLink isLink={false} text={tr("showMore")} />
            </div>
            <div className="col-md-4 col-12">
              <TitleResponse title={tr("referenceId")} response="" />
              <TitleResponse title={tr("service")} response="" />
            </div>
            <div className="col-md-4 col-12 d-none d-md-block">
              <ButtonEditRequest isMobile={false} />
            </div>
          </div>
        </Card>
        <Card title={tr("requestForQuotation")}>
          {/* <Table
            headerItemClassName="ds-bg-grey"
            columns={Data.getSimulationResultsColumns(tr)}
            data={[]}
            isPagination
            onPageChange={() => {}}
            pageCount={15}
            selectedPageNumber={2}
          /> */}
        </Card>
      </div>
    );
  };
export default SimulationResultsTemplate;
