import { useTranslation } from "../../../common/customHooks";
import { IShowService } from "../../../interfaces";
import { CheckedItem } from "../../atoms";
import { TitleResponse } from "../../molecules";

const ShowService = ({
  className,
  style,
  state,
}: IShowService): JSX.Element => {
  const { tr } = useTranslation();

  return (
    <div style={style} className={`w-100 ${className || ""}`}>
      <div className="ds-rfq-from-to-si-title mb-2">{tr("serviceOptions")}</div>
      <div className="d-flex flex-wrap mb-1">
        {state?.serviceOptions.map(({ name }, index) => (
          <CheckedItem key={index} className="m-1" title={name} />
        ))}
      </div>
      {state?.additionnelService &&
        (state?.additionnelService?.destination ||
          state?.additionnelService?.origin) && (
          <div className="mt-2">
            <div className="ds-rfq-from-to-si-title mb-2">
              {tr("additionnelService")}
            </div>
            <div className="row">
              {state?.additionnelService?.origin && (
                <div className="col-4">
                  <TitleResponse
                    title={tr("origin")}
                    response={state?.additionnelService?.origin}
                  />
                </div>
              )}
              {state?.additionnelService?.destination && (
                <div className="col-4">
                  <TitleResponse
                    title={tr("destination")}
                    response={state?.additionnelService?.destination}
                  />
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};
export default ShowService;
