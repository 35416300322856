import { EAuthType, ERegisterType } from "../enums";
import {
  IAuthReducer,
  IDocumentListState,
  IDrawerProvider,
  IModalProvider,
  IPrivateAppDataProvider,
  IRfqFromToSenderInfo,
} from "../interfaces";
import {
  IAuthState,
  ICommonManageProfileState,
  ICreateDocumentState,
  ICreateItemState,
  ICreateSenderCustomerState,
  ICreateShipmentPackageState,
  IEditProfileState,
  IForgotPasswordState,
  IFromToState,
  IGeneraleInformationState,
  IPaginationState,
  IRegisterState,
  IRequestListState,
  IResetPasswordState,
  IServiceCreateShipmentState,
  ISimulationListState,
  ISecurityPasswordState,
  IPreferenceState,
  IMofCommonProfileState,
  IMofGeneralState,
  IMofGeneralAdressState,
  IDaysTimePickerState,
  IShipmentListState,
  ICreateQuotationState,
  IClaimsListState,
  IManageClaimCancelState,
  ICancelListState,
  IQuotationListState,
  IPackagesState,
} from "../interfaces/states";
import { TDayTimePickerState } from "../types";

const INIT_AUTH_REDUCER_STATE: IAuthReducer = {
  accessToken: undefined,
  refreshToken: undefined,
  userId: undefined,
};
const INIT_AUTH_STATE: IAuthState = {
  email: "",
  password: "",
  isRememberMe: false,
};
const INIT_REGISTER_STATE: IRegisterState = {
  firstName: "",
  lastName: "",
  email: "",
  userName: "",
  country: [],
  city: [],
  password: "",
  isAgreeAllTerms: false,
  registerType: ERegisterType.customer,
  confirmedPassword: "",
  companyName: "",
  vatCode: "",
};
const INIT_RESET_PASSWORD_STATE: IResetPasswordState = {
  password: "",
  confirmedPassword: "",
  type: EAuthType.resetPassword,
};
const INIT_FORGOT_PASSWORD_STATE: IForgotPasswordState = {
  email: "",
  type: EAuthType.forgotPassword,
};
const INIT_CREATE_SENDER_CUSTOMER_STATE: ICreateSenderCustomerState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  adress: "",
  country: "",
  city: "",
  zipCode: 0,
  countriesOptions: [],
  citiesOptions: [],
};
const INIT_CREATE_DOCUMENT_STATE: ICreateDocumentState = {
  type: "",
  documentTypesOptions: [],
  name: "",
  uploadedDate: null,
  issueDate: null,
  files: [],
};
const INIT_CREATE_ITEM_STATE: ICreateItemState = {
  sectionsOptions: [],
  chaptersOptions: [],
  shode: "",
  section: "",
  wUnitsOptions: [],
  wUnit: "",
  weight: 0,
  vUnitsOptions: [],
  length: 0,
  width: 0,
  height: 0,
  declaredValue: 0,
  insuredValue: 0,
  currency: "",
  currenciesOptions: [],
  chapter: "",
  quantity: 0,
  description: "",
  vUnit: undefined,
};
const INIT_GENERALE_INFORMATION_STATE: IGeneraleInformationState = {
  referenceNumber: "",
  instruction: "",
  intercom: "",
  location: "",
  transportationMOde: "",
  shipmentType: "",
};
const INIT_FROM_TO_STATE: IFromToState = {
  sender: {
    isCustomer: false,
    isOriginPickup: false,
    information: "",
    adress: "",
    contact: "",
  },
  recipient: {
    isCustomer: false,
    isDestinationDelivery: false,
    information: "",
    adress: "",
    contact: "",
  },
  firstNotify: {
    isCustomer: false,
    isDestinationDelivery: false,
    information: "",
    adress: "",
    contact: "",
  },
  secondNotify: {
    isCustomer: false,
    isDestinationDelivery: false,
    information: "",
    adress: "",
    contact: "",
  },
  originPickup: {
    isDeposit: false,
    information: "",
    adress: "",
    contact: "",
  },
  destination: {
    information: "",
    adress: "",
    contact: "",
  },
  agent: {
    information: "",
    adress: "",
    contact: "",
  },
  readiness: { date: null, time: "", isFlexible: undefined },
  pickUpDeposit: { date: null, time: "", isFlexible: false },
  boarding: { date: null, time: "", isFlexible: false },
  delivery: { date: null, time: "", isFlexible: false },
};
const INIT_SERVICE_CREATE_SHIPMENT: IServiceCreateShipmentState = {
  isTemperatureControlled: false,
  isInsurance: false,
  isStackable: false,
  isSignature: false,
  isDutyTaxPaid: false,
  isDangerousGoods: false,
  isRamp: false,
  isCustomClearance: false,
  isSecurityStatus: false,
  isProoveOfDelivery: false,
  isCollect: false,
  isExportCustoms: false,
  isLastMileDelivery: false,
  isImportCustoms: false,
  isImportHandeling: false,
  categoryOptions: [],
  selectedCategory: "",
  servicesOptions: [],
  selectedService: "",
  dangerousGoodsOptions: [],
  dangerousGoods: [],
};

const INIT_PAGINATION_STATE: IPaginationState = {
  pageNumber: 1,
  paginationLength: 1,
  perPage: 8,
};
const INIT_CREATE_SHIPMENT_DOCUMENT_LIST: IDocumentListState = {
  ...INIT_PAGINATION_STATE,
  documents: [],
};
const INIT_SIMULATION_LIST_STATE: ISimulationListState = {
  ...INIT_PAGINATION_STATE,
  searchedValue: "",
  searchedDate: null,
  searchFiels: [],
  simulationList: [],
};
const INIT_CREATE_SHIPMENT_PACKAGE_STATE: ICreateShipmentPackageState = {
  packageList: [],
};
const INIT_SENDER_INFO_STATE: IRfqFromToSenderInfo = {
  firstName: "",
  lastName: "",
  adress: "",
  email: "",
  phoneNumber: "",
};

const INIT_REQUEST_LIST_STATE: IRequestListState = {
  ...INIT_PAGINATION_STATE,
  requests: [],
};

const INIT_COMMON_MANAGE_PROFILE_STATE: ICommonManageProfileState = {
  selectedTabIndex: 0,
};
const INIT_MOF_COMMON_STATE: IMofCommonProfileState =
  INIT_COMMON_MANAGE_PROFILE_STATE;
const INIT_EDIT_PROFILE_STATE: IEditProfileState = {
  userName: "",
  userLevel: "",
  userType: "",
  firstName: "",
  lastName: "",
  gender: "",
  function: "",
  fixePhone: "",
  mobilePhone: "",
  whatsappNumber: "",
  email: "",
  image: "",
};

const INIT_SECURITY_PASSWORD_STATE: ISecurityPasswordState = {
  currentPassword: "",
  newPassword: "",
  confirmedPassword: "",
};

const INIT_PREFERENCE_STATE: IPreferenceState = {
  languagesOptions: [],
  timeZoneOptions: [],
  selectedLanguage: "",
  selectedTimeZone: "",
  isNewsLetter: false,
};

const INIT_MOF_GENERAL_STATE: IMofGeneralState = {
  companyLogoSrc: "",
  legalName: "",
  commercialName: "",
  form: "",
  capital: "",
  registrationNumber: "",
  register: "",
  vatCode: "",
  eoriCode: "",
  activityCode: "",
  selectedCountry: "",
  email: "",
  phoneNumber: "",
  companyCountryOptions: [],
};

const INIT_MF_GENERAL_ADRESS: IMofGeneralAdressState = {
  searchFiels: [],
};

const INIT_DAY_TIME_PICKER_STATE: TDayTimePickerState = {
  isSelected: false,
  endTime: "00:00",
  startTime: "00:00",
};

const INIT_DAYS_TIME_PICKER_STATE: IDaysTimePickerState = {
  monday: INIT_DAY_TIME_PICKER_STATE,
  tuesday: INIT_DAY_TIME_PICKER_STATE,
  wednesday: INIT_DAY_TIME_PICKER_STATE,
  thursday: INIT_DAY_TIME_PICKER_STATE,
  friday: INIT_DAY_TIME_PICKER_STATE,
  saturday: INIT_DAY_TIME_PICKER_STATE,
  sunday: INIT_DAY_TIME_PICKER_STATE,
};

const INIT_SHIPMENT_LIST_STATE: IShipmentListState = {
  ...INIT_PAGINATION_STATE,
  shipmentItems: [],
};

const INIT_CREATE_SHIPMENT_STATE: ICreateQuotationState = {
  carrierInfo: {
    quotationNumber: "",
    quotationReference: "",
    intercom: "",
    from: "",
    to: "",
    etd: null,
    eta: null,
    place: "",
  },
  customerInfo: {
    depositDate: null,
    pickupEstimatedDate: null,
    quotationDate: null,
    quotationValidaty: null,
    transiteTime: 0,
  },
  documents: [],
  totalAmountWithoutTax: "",
  totalTax: "",
  totalAmountWithTax: "",
  payment: {
    paymentMode: "",
  },
};

const INIT_DRAWER_PROVIDER_State: IDrawerProvider = {
  children: undefined,
  title: "",
  isOpen: false,
  options: {},
};

const INIT_MODAL_PROVIDER_STATE: IModalProvider = {
  children: undefined,
  isOpen: false,
  options: {},
};

const INIT_PRIVATE_APP_DATA_STATE: IPrivateAppDataProvider = {
  email: undefined,
  avatarSrc: undefined,
  firstName: undefined,
  lastName: undefined,
};

const INIT_DOCUMENT_LIST_STATE: IDocumentListState = {
  ...INIT_PAGINATION_STATE,
  documents: [],
};

const INIT_CLAIMS_LIST_STATE: IClaimsListState = {
  ...INIT_PAGINATION_STATE,
  claims: [],
};

const INIT_MANAGE_CLAIM_CANCEL_STATE: IManageClaimCancelState = {
  attachedFile: undefined,
  details: undefined,
  incidentDateHour: undefined,
  reasonId: undefined,
  request: undefined,
  requestedAmount: undefined,
};

const INIT_CANCEL_LIST_STATE: ICancelListState = {
  ...INIT_PAGINATION_STATE,
  cancels: [],
};

const INIT_QUOTATION_LIST_STATE: IQuotationListState = {
  ...INIT_PAGINATION_STATE,
  quotations: [],
};

const INIT_PACKAGES_STATE: IPackagesState = {
  ...INIT_PAGINATION_STATE,
  perPage: 6,
  packages: [],
};

export {
  INIT_AUTH_STATE,
  INIT_AUTH_REDUCER_STATE,
  INIT_REGISTER_STATE,
  INIT_RESET_PASSWORD_STATE,
  INIT_FORGOT_PASSWORD_STATE,
  INIT_CREATE_SENDER_CUSTOMER_STATE,
  INIT_CREATE_DOCUMENT_STATE,
  INIT_CREATE_ITEM_STATE,
  INIT_FROM_TO_STATE,
  INIT_SERVICE_CREATE_SHIPMENT,
  INIT_GENERALE_INFORMATION_STATE,
  INIT_CREATE_SHIPMENT_DOCUMENT_LIST,
  INIT_SIMULATION_LIST_STATE,
  INIT_CREATE_SHIPMENT_PACKAGE_STATE,
  INIT_REQUEST_LIST_STATE,
  INIT_COMMON_MANAGE_PROFILE_STATE,
  INIT_EDIT_PROFILE_STATE,
  INIT_SECURITY_PASSWORD_STATE,
  INIT_PREFERENCE_STATE,
  INIT_MOF_COMMON_STATE,
  INIT_MOF_GENERAL_STATE,
  INIT_MF_GENERAL_ADRESS,
  INIT_DAY_TIME_PICKER_STATE,
  INIT_DAYS_TIME_PICKER_STATE,
  INIT_SHIPMENT_LIST_STATE,
  INIT_CREATE_SHIPMENT_STATE,
  INIT_DRAWER_PROVIDER_State,
  INIT_MODAL_PROVIDER_STATE,
  INIT_PRIVATE_APP_DATA_STATE,
  INIT_DOCUMENT_LIST_STATE,
  INIT_CLAIMS_LIST_STATE,
  INIT_MANAGE_CLAIM_CANCEL_STATE,
  INIT_CANCEL_LIST_STATE,
  INIT_QUOTATION_LIST_STATE,
  INIT_PACKAGES_STATE
};
