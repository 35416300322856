import { ITextInfo } from "../../../interfaces";

const InfoText = ({ className, style, title }: ITextInfo): JSX.Element => {
  return (
    <div style={style} className={`ds-card-info p-2 ${className || ""}`}>
      <div className="d-flex py-2">
        <i className="bi bi-info-circle ds-primary-color ds-info-icon" />
        <div className="ds-info-text ps-2">{title}</div>
      </div>
    </div>
  );
};
export default InfoText;
