import { ICheckedItem } from "../../../interfaces";

const CheckedItem = ({
  className,
  style,
  title,
}: ICheckedItem): JSX.Element => {
  return (
    <div
      className={`${
        className || ""
      } d-flex ds-primary-color px-2 py-1 ds-cheked-item`}
      style={style}
    >
      <i className="bi bi-check-lg" />
      <div className="ps-2">{title}</div>
    </div>
  );
};
export default CheckedItem;
