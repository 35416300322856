import { useState } from "react";
import { EFileType } from "../../../enums";
import { IFileReader } from "../../../interfaces";
import { Document, Page } from "react-pdf";

const FileReader = ({
  className,
  style,
  fileType,
  path,
}: IFileReader): JSX.Element => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess(): void {
    setNumPages(numPages);
  }
  const renderFileType = () => {
    switch (fileType) {
      case EFileType.img: {
        return <img alt="img" src={path} />;
      }
      case EFileType.pdf: {
        return (
          <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} onChange={(e) => setPageNumber(e)} />
          </Document>
        );
      }
    }
    return <></>;
  };
  return (
    <div style={style} className={`w-100 h-100 ${className || ""}`}>
      {renderFileType()}
    </div>
  );
};
export default FileReader;
