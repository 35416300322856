import { useState, useEffect } from "react";
import { IDaysTimePicker, IDaysTimePickerState } from "../../../interfaces";
import { INIT_DAYS_TIME_PICKER_STATE } from "../../../consts";
import { DayTimePicker } from "../../molecules";
import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
import { TDayTimePickerState } from "../../../types";

const DaysTimePicker = ({
  style,
  className,
  value,
  onChange,
}: IDaysTimePicker) => {
  const { tr } = useTranslation();
  const [state, setState] = useState<IDaysTimePickerState>({
    ...INIT_DAYS_TIME_PICKER_STATE,
    ...value,
  });
  const handleDayChange = (day: string, updatedValue: TDayTimePickerState) => {
    setState({
      ...state,
      [day.toLowerCase()]: updatedValue,
    });
  };
  useEffect(() => {
    onChange && onChange(state);
  }, [state]);
  return (
    <div style={style} className={`w-100 ${className || ""}`}>
      {Object.entries(state).map(([day, value], index) => (
        <DayTimePicker
          className="mb-2"
          key={index}
          day={Data.getWeekDays(tr)[index]}
          value={value}
          onChange={(newValue) => handleDayChange(day, newValue)}
        />
      ))}
    </div>
  );
};
export default DaysTimePicker;
