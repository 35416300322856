import { useEffect, useState } from "react";
import { useTranslation } from "../../../../common/customHooks";
import { Card, PageTitle, ShipmentStatus } from "../../../../components";
import RequestDocumentList from "../documentsList/DocumentsList";
import RequestGeneralInformation from "../generalInformation/GeneralInformation";
import RequestFromTo from "../fromTo/FromTo";
import RequestPackage from "../package/Package";
import RequestService from "../service/Service";
import { Tab, Tabs } from "react-bootstrap";
import { Data } from "../../../../common";
import RequestCancel from "../cancel/Cancel";
import { IShipmentStatusModel } from "../../../../interfaces";
import { HttpStatusCode } from "axios";
import { RequestAPI } from "../../../../apis";

const ShowRequestCard = ({ id }: { id?: number }): JSX.Element => {
  const { tr } = useTranslation();
  const requestApi = new RequestAPI();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [status, setStatus] = useState<IShipmentStatusModel>();
  console.log(status, "status");

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await requestApi.getRequestById(id);
      if (response.status === HttpStatusCode.Ok) {
        const { cfg_gen_status_request } = response.data?.data;
        setStatus({
          id: cfg_gen_status_request?.id,
          backgroundColor: cfg_gen_status_request?.background_color,
          color: cfg_gen_status_request?.text_color,
          text: cfg_gen_status_request?.status_name,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card
      isScroll
      headerClassName="pb-0"
      bodyClassName="py-0"
      header={
        <div className="w-100 h-100">
          <div className="flex justify-between">
            <PageTitle
              titleClassName="ds-rfq-header-title"
              title={tr("showRequest")}
            />
            {status && (
              <div className="py-1">
                <ShipmentStatus {...status} />
              </div>
            )}
          </div>
          <Tabs
            defaultActiveKey={selectedTabIndex}
            className="mb-3"
            onSelect={(key) => setSelectedTabIndex(parseInt(key!))}
          >
            {Data.getRequestForQuotationTabsData(tr).map((tabName, index) => (
              <Tab key={index} eventKey={index} title={tabName} />
            ))}
          </Tabs>
        </div>
      }
    >
      {
        [
          <RequestGeneralInformation id={id} />,
          <RequestFromTo id={id} />,
          <RequestService id={id} />,
          <RequestPackage id={id} />,
          <RequestDocumentList id={id} />,
          <RequestCancel
            id={id}
            isCanceledRequest={status?.id === 5}
            onCancelRequest={() => id && handleFetchDataAPI(id)}
          />,
        ][selectedTabIndex]
      }
    </Card>
  );
};
export default ShowRequestCard;
