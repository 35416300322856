import { useTranslation } from "../../../common/customHooks";
import { ESignWithType } from "../../../enums";
import { ISignWith } from "../../../interfaces";
import { AppleIcon, GoogleIcon } from "../../icons";

const SignWith = ({ className, style, type }: ISignWith): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <button style={style} className={`${className || ""} ds-sign-with w-100`}>
      <div className="d-flex justify-center">
        <div className="pt-1">
          {type === ESignWithType.google ? <GoogleIcon /> : <AppleIcon />}
        </div>
        <div className="ps-1">
          <span className="ds-sign-with-text">
            {type === ESignWithType.google
              ? tr("loginWithGoogle")
              : tr("loginWithApple")}
          </span>
        </div>
      </div>
    </button>
  );
};
export default SignWith;
