import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import { Label } from "../../atoms";
import { IInputPhoneNumber } from "../../../interfaces";
import { Validation } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
const InputPhoneNumber = ({
  className,
  style,
  labelStyle,
  label,
  isObligatoryLabel,
  value,
  placeholder,
  isValidate,
  errorValidateText,
  onChange,
}: IInputPhoneNumber): JSX.Element => {
  const [counter, setCounter] = useState<number>(0);
  const { tr } = useTranslation();
  const { isValide, textError } = Validation.phoneNumberValidation(value, tr);
  const isValidationForm = counter > 0 && isValidate;
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          style={labelStyle}
          className="mb-2"
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      <PhoneInput
        inputClass={`${
          isValidationForm &&
          `${isValide ? "ds-input-valide-border" : "ds-input-danger-border"}`
        } `}
        placeholder={placeholder}
        country="us"
        value={value}
        onChange={(value) => {
          counter === 0 && setCounter(counter + 1);
          onChange(value);
        }}
      />
      <div>
        {isValidationForm && !isValide && (
          <div className="ds-input-error-text w-100 ps-1">
            {errorValidateText || textError}
          </div>
        )}
      </div>
    </div>
  );
};
export default InputPhoneNumber;
