import { useSelector } from "react-redux";
import { IPrivateAppLayout, IRootState } from "../../interfaces";
import LayoutFooter from "./footer/Footer";
import LayoutNavbar from "./navbar/Navbar";
import LayoutSidebar from "./sidebar/Sidebar";
import { useState } from "react";
import { useLanguages } from "../../common/customHooks";

const PrivateAppLayout = ({
  children,
  auth,
}: IPrivateAppLayout): JSX.Element => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const isCollapsed = useSelector(
    (state: IRootState) => state?.setting?.isCollapsedSidebar
  );
  const { isRtl } = useLanguages();

  return (
    <>
      <LayoutNavbar
        auth={auth}
        onChangeToggled={(toggled) => setIsToggled(toggled)}
        isRtl={isRtl}
      />

      <div className="flex row-content ds-bg-secondary p-2 ds-app-layout">
        <LayoutSidebar
          isRtl={isRtl}
          isCollapsedSidebar={isCollapsed === true}
          isToggled={isToggled}
          onChangeToggled={(toggled) => setIsToggled(toggled)}
        />
        <main className="ds-scroll-x ds-main">{children}</main>
      </div>
      <LayoutFooter />
    </>
  );
};
export default PrivateAppLayout;
