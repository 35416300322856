import { ICommonComponent } from "../../../interfaces";

const DashboardIcon = ({ className }: ICommonComponent): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="#849BB3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 9">
        <path
          id="Vector"
          d="M13.7208 0C15.0993 0 16.2823 0.0691321 17.2706 0.278796C18.2716 0.491106 19.1391 0.86122 19.816 1.5072C20.4928 2.15318 20.8806 2.9813 21.1031 3.93661C21.3227 4.88 21.3951 6.00901 21.3951 7.3248V13.6752C21.3951 14.991 21.3227 16.12 21.1031 17.0633C20.8806 18.0187 20.4928 18.8469 19.816 19.4928C19.1392 20.1388 18.2716 20.5089 17.2706 20.7212C16.2823 20.9309 15.0993 21 13.7208 21H13.6944C12.4792 21 11.4941 20.0598 11.4941 18.9V2.1C11.4941 0.940201 12.4792 0 13.6944 0H13.7208Z"
        />
        <path
          id="Vector_2"
          d="M8.27788 0C6.8993 0 5.71641 0.0731557 4.728 0.295022C3.72709 0.519689 2.85946 0.911345 2.18265 1.59492C1.50584 2.2785 1.11806 3.15481 0.895617 4.16572C0.675947 5.16402 0.603516 6.35874 0.603516 7.75111V7.77778C0.603516 9.00511 1.58859 10 2.80374 10H8.30429C9.51947 10 10.5045 9.00511 10.5045 7.77778V2.22222C10.5045 0.994922 9.51947 0 8.30429 0H8.27788Z"
        />
        <path
          id="Vector_3"
          d="M8.27788 21C6.8993 21 5.71641 20.9269 4.728 20.705C3.72709 20.4803 2.85946 20.0887 2.18265 19.4051C1.50584 18.7216 1.11806 17.8452 0.895617 16.8342C0.675947 15.836 0.603516 14.6412 0.603516 13.2489V13.2222C0.603516 11.9949 1.58859 11 2.80374 11H8.30429C9.51947 11 10.5045 11.9949 10.5045 13.2222V18.7778C10.5045 20.0051 9.51947 21 8.30429 21H8.27788Z"
        />
      </g>
    </svg>
  );
};
export default DashboardIcon;
