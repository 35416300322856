import { useState } from "react";
import { CreateQuotationTemplate } from "../../templates";
import { ICreateQuotationState } from "../../interfaces";
import { INIT_CREATE_SHIPMENT_STATE } from "../../consts";

const CreateQuotation = (): JSX.Element => {
  const [createQuotationState, setCreateQuotationState] =
    useState<ICreateQuotationState>(INIT_CREATE_SHIPMENT_STATE);
  const handleSendQuotation = () => {};
  return (
    <CreateQuotationTemplate
      state={createQuotationState}
      onChangeState={setCreateQuotationState}
      onSendQuotation={handleSendQuotation}
    />
  );
};

export default CreateQuotation;
