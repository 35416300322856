import { useEffect, useState } from "react";
import { IQuotationInfoModel } from "../../../interfaces";
import { QuotationAPI } from "../../../apis";
import { DataFetchAPI } from "../../../common/data/DataFetchApi";
import { HttpStatusCode } from "axios";

const ShowQuotation = ({ id }: { id: number }): JSX.Element => {
  const quotationApi = new QuotationAPI();
  const [state, setState] = useState<IQuotationInfoModel>();

  useEffect(() => {
    handleFetchDataAPI(id);
  }, [id]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await quotationApi.getQuotationInfo(id);
      if (response.status === HttpStatusCode.Ok) {
        setState(
          DataFetchAPI.fetchDataQuotationInfoModel({ ...response.data, id })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  return <></>;
};
export default ShowQuotation;
