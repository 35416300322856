import { AxiosInstance, AxiosResponse } from "axios";
import { getApiAxios } from "../../common/functions/GetApiAxios";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import { TokenEnum } from "../../enums";
import Config from "../../common/config/Config";

class UserAPI {
  private _api: AxiosInstance;

  constructor() {
    this._api = getApiAxios({
      baseUrl: Config.getInstance().API_URL + "/user",
      headers: handleHeaders(TokenEnum.accessToken),
      isRefreshToken: true,
    });
  }

  getMyInfo(): Promise<AxiosResponse> {
    return this._api.get("get-my-info");
  }
}
export default UserAPI;
