import { ITitleResponse } from "../../../interfaces";

const TitleResponse = ({
  className,
  style,
  title,
  response,
  titleClassName,
  responseClassName,
  responseStyle,
  titleStyle,
}: ITitleResponse): JSX.Element => {
  return (
    <div style={style} className={className}>
      <div
        style={titleStyle}
        className={`ds-title-text ${titleClassName || ""}`}
      >
        {title} :
      </div>
      <div style={responseStyle} className={`${responseClassName} ds-black-color`}>
        {response}
      </div>
    </div>
  );
};
export default TitleResponse;
