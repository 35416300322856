import {
  useCreateSimulation,
  useTranslation,
} from "../../../../common/customHooks";
import {
  FromToMoreDateInfo,
  FromToSenderCustomer,
  InputSelect,
  ToggledCard,
} from "../../../../components";
import { ICreateShipmentFromTo } from "../../../../interfaces";
import { billToIcon, moreInfoIcon } from "../../../../assets";
const CreateShipmentFromTo = ({
  onAddSenderCustomer,
  state,
  onChangeState,
}: ICreateShipmentFromTo): JSX.Element => {
  const { tr } = useTranslation();
  const createSimulation = useCreateSimulation();
  return (
    <div className="w-100 h-100">
      <div className="row">
        <div className="col-12">
          {createSimulation
            .getFromToOptions({
              onAddSenderCustomer,
              state,
              onChangeState,
            })
            .map((fromTo, index) => (
              <ToggledCard
                key={index}
                iconTitle={<img alt="icon" src={fromTo.iconSrc} />}
                className="mb-2"
                title={fromTo.title}
              >
                <FromToSenderCustomer
                  adress={fromTo.adress}
                  contact={fromTo.contact}
                  information={fromTo.information}
                  conditionOptions={fromTo.conditionOptions}
                  onAddSenderCustomer={onAddSenderCustomer}
                />
              </ToggledCard>
            ))}
          <ToggledCard
            className="mb-2"
            iconTitle={<img alt="icon" src={billToIcon} />}
            title={tr("billTo")}
          >
            <div className="row">
              <div className="col-md-4 col-12">
                <InputSelect
                  label={tr("billTo")}
                  isObligatoryLabel
                  isMulti={false}
                  onChange={() => {}}
                  options={[]}
                />
              </div>
              <div className="col-md-4 col-12">
                <InputSelect
                  label={tr("billToInformation")}
                  isObligatoryLabel
                  isMulti={false}
                  onChange={() => {}}
                  options={[]}
                />
              </div>
            </div>
          </ToggledCard>
          <ToggledCard
            className="mb-2"
            iconTitle={<img alt="icon" src={moreInfoIcon} />}
            title={tr("moreInformation")}
          >
            <div className="row">
              <div className="col-md-3 col-12 ds-mb-xs-2">
                <FromToMoreDateInfo
                  label={tr("readiness")}
                  isObligatoryLabel
                  date={state.readiness.date}
                  isUseFlexible={false}
                  time={state.readiness.time}
                  onChangeTime={(time) =>
                    onChangeState({
                      ...state,
                      readiness: { ...state.readiness, time: time! },
                    })
                  }
                  onChangeDate={(date) =>
                    onChangeState({
                      ...state,
                      readiness: { ...state.readiness, date: date! },
                    })
                  }
                />
              </div>
              <div className="col-md-3 col-12 ds-mb-xs-2">
                <FromToMoreDateInfo
                  label={tr("pickUpDeposit")}
                  date={state.pickUpDeposit.date}
                  isUseFlexible
                  isFlexible={state.pickUpDeposit.isFlexible}
                  onChangeflexible={(isFlexible) =>
                    onChangeState({
                      ...state,
                      pickUpDeposit: { ...state.pickUpDeposit, isFlexible },
                    })
                  }
                  time={state.pickUpDeposit.time}
                  onChangeTime={(time) =>
                    onChangeState({
                      ...state,
                      pickUpDeposit: { ...state.pickUpDeposit, time: time! },
                    })
                  }
                  onChangeDate={(date) =>
                    onChangeState({
                      ...state,
                      pickUpDeposit: { ...state.pickUpDeposit, date: date! },
                    })
                  }
                />
              </div>
              <div className="col-md-3 col-12 ds-mb-xs-2">
                <FromToMoreDateInfo
                  label={tr("boarding")}
                  date={state.boarding.date}
                  isUseFlexible
                  isFlexible={state.boarding.isFlexible}
                  onChangeflexible={(isFlexible) =>
                    onChangeState({
                      ...state,
                      boarding: { ...state.boarding, isFlexible },
                    })
                  }
                  time={state.boarding.time}
                  onChangeTime={(time) =>
                    onChangeState({
                      ...state,
                      boarding: { ...state.boarding, time: time! },
                    })
                  }
                  onChangeDate={(date) =>
                    onChangeState({
                      ...state,
                      boarding: { ...state.boarding, date: date! },
                    })
                  }
                />
              </div>
              <div className="col-md-3 col-12 ds-mb-xs-2">
                <FromToMoreDateInfo
                  label={tr("delivery")}
                  isObligatoryLabel
                  date={state.delivery.date}
                  isUseFlexible
                  isFlexible={state.delivery.isFlexible}
                  onChangeflexible={(isFlexible) =>
                    onChangeState({
                      ...state,
                      delivery: { ...state.delivery, isFlexible },
                    })
                  }
                  time={state.delivery.time}
                  onChangeTime={(time) =>
                    onChangeState({
                      ...state,
                      delivery: { ...state.delivery, time: time! },
                    })
                  }
                  onChangeDate={(date) =>
                    onChangeState({
                      ...state,
                      delivery: { ...state.delivery, date: date! },
                    })
                  }
                />
              </div>
            </div>
          </ToggledCard>
        </div>
      </div>
    </div>
  );
};
export default CreateShipmentFromTo;
