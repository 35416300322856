import { Data } from "../../common";
import { useDrawer, useTranslation } from "../../common/customHooks";
import { ShowQuotation } from "../../components";
import { IQuotationListTemplate } from "../../interfaces";
import { PageLayout } from "../../layouts";
import { useMemo, useCallback } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";

const QuotationListTemplate = ({
  state,
}: IQuotationListTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();

  const data = useMemo(
    () =>
      state.quotations.map((quotation) =>
        Data.getQuotationListRowsData(quotation, (id) =>
          handleShowQuotation(id)
        )
      ),
    [state.quotations]
  );

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getQuotationListColumnsData(tr),
    [tr]
  );

  const handleShowQuotation = useCallback(
    (id: number) => {
      drawer.open({
        title: tr("showQuotation"),
        children: <ShowQuotation id={id} />,
      });
    },
    [drawer, tr]
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  return (
    <PageLayout title={tr("quotationList")}>
      <MaterialReactTable table={table} />
    </PageLayout>
  );
};

export default QuotationListTemplate;
