import { useDispatch, useSelector } from "react-redux";
import { IAuthReducer, IRootState } from "../../../interfaces";
import { resetCredentials, setCredentials } from "../../../redux";

const useAuthentification = () => {
  const dispatch = useDispatch();
  const auth: IAuthReducer = useSelector((state: IRootState) => state?.auth);
  const logout = () => {
    dispatch(resetCredentials());
  };
  const setState = (
    accessToken: string,
    refreshToken: string,
    userId: number
  ) => {
    dispatch(
      setCredentials({
        accessToken,
        refreshToken,
        userId,
      })
    );
  };
  return { ...auth, logout, setState };
};
export default useAuthentification;
