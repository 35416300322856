import { ITotalAmount } from "../../../interfaces";

const TotalAmount = ({
  priceData,
  totalPrice,
  totalPriceLabel,
  className,
  style,
}: ITotalAmount) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(69, 214, 190, 0.20)",
        borderRadius: "20px",
        maxHeight: "160px",
        maxWidth: "370px",
        ...style,
      }}
      className={`w-100 p-3 ${className || ""}`}
    >
      {priceData.length > 0 && (
        <>
          {priceData.map((data, index) => (
            <div key={index} className="row mb-3">
              <div className="col-7 d-flex justify-end">{data.label}</div>
              <div className="col-5 text-center">{data.price}</div>
            </div>
          ))}
          <hr
            className="mb-3"
            style={{
              background: "rgba(69, 214, 190, 0.40)",
            }}
          />
        </>
      )}
      <div className="row mb-4">
        <div className="col-7 d-flex justify-end">{totalPriceLabel}</div>
        <div className="col-5 text-center">{totalPrice}</div>
      </div>
    </div>
  );
};
export default TotalAmount;
