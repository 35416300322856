import { ISelectSquareItem } from "../../../interfaces";

const SelectSquareItem = ({
  className,
  style,
  icon,
  title,
  isSelected,
  onSelect,
}: ISelectSquareItem): JSX.Element => {
  return (
    <div
      style={style}
      className={`${className || ""} ds-select-square-item ${
        isSelected && "ds-bg-second-primary-color"
      }`}
      onClick={onSelect}
    >
      <div className="d-flex justify-content-center py-4">
        <div>
          {icon && <div>{icon}</div>}
          <div
            className={`${
              isSelected ? "ds-primary-color" : "ds-black-color"
            } ds-select-square-item-title`}
          >
            <span>{title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectSquareItem;
