import { useDrawer, useTranslation } from "../../common/customHooks";
import {
  Button,
  InfinityScroll,
  PageTitle,
  SearchFilter,
  ShipmentDetailsTrackingMap,
  ShipmentItem,
  ShipmentTrackingDetails,
} from "../../components";
import { IShipmentListTemplate } from "../../interfaces";
import { useNavigate } from "react-router-dom";

const ShipmentListTemplate = ({
  state,
  selectedShipmentId,
  searchFields,
  onChangeSearchedValue,
  onSelectShipment,
  onChangeState,
}: IShipmentListTemplate) => {
  const navigate = useNavigate();
  const { tr } = useTranslation();
  const drawer = useDrawer();
  return (
    <>
      <div className="w-100 d-flex justify-between mb-2">
        <PageTitle title={tr("shipmentList")} />
        <div className="d-none d-md-block">
          <div className="d-flex">
            <Button
              className="ds-sl-button"
              style={{
                cursor: !selectedShipmentId ? "not-allowed" : "pointer",
              }}
              isDisabled={!selectedShipmentId}
              icon={<i className="bi bi-eye-fill" />}
              onClick={() =>
                typeof selectedShipmentId !== "undefined" &&
                navigate(`/show-shipment/${selectedShipmentId}`)
              }
              text={tr("showShipment")}
            />
            <Button
              className="ds-sl-button mx-2"
              style={{
                cursor: !selectedShipmentId ? "not-allowed" : "pointer",
              }}
              isDisabled={!selectedShipmentId}
              icon={<i className="bi bi-geo-fill" />}
              onClick={() => {
                drawer.open({
                  title: `${tr("referenceId")}: ${
                    state.shipmentItems.find(
                      (shipment) => shipment.id === selectedShipmentId
                    )?.refrenceId
                  }`,
                  children: (
                    <ShipmentTrackingDetails id={selectedShipmentId!} />
                  ),
                });
              }}
              text={tr("tracking")}
            />
          </div>
        </div>
      </div>
      <div className="w-100 container-fluid">
        <div className="row">
          <div className="col-md-6 col-12">
            <SearchFilter
              className="mb-2"
              onChangeSearchedValue={onChangeSearchedValue}
              onSelect={({ id }) => navigate(`/show-shipment/${id}`)}
              searchFields={searchFields}
            />
            <InfinityScroll
              className="ds-shipment-list-infity-scroll-container"
              paginationLength={state.paginationLength}
              pageNumber={state.pageNumber}
              onNext={() =>
                onChangeState({ ...state, pageNumber: state.pageNumber + 1 })
              }
            >
              {state.shipmentItems.map((item, index) => (
                <ShipmentItem
                  className="mb-2"
                  key={index}
                  {...item}
                  isSelected={selectedShipmentId === item.id}
                  onClick={() =>
                    onSelectShipment(state.shipmentItems[index]?.id)
                  }
                />
              ))}
            </InfinityScroll>
          </div>
          {selectedShipmentId && (
            <div className="col-md-6 d-none d-md-block md:ps-5">
              <ShipmentDetailsTrackingMap id={selectedShipmentId} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ShipmentListTemplate;
