import { useEffect, useState } from "react";
import { IFieldOption, IRequestListState } from "../../interfaces";
import { RequestListTemplate } from "../../templates";
import { INIT_REQUEST_LIST_STATE } from "../../consts";
import { RequestAPI } from "../../apis";
import { DataFetchAPI } from "../../common/data/DataFetchApi";
import { HttpStatusCode } from "axios";

const RequestForQuotation = () => {
  const requestApi = new RequestAPI();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchFields, setSearchFields] = useState<Array<IFieldOption>>([]);
  const [selectedRequestId, setSelectedRequestId] = useState<
    number | undefined
  >(undefined);
  const [state, setState] = useState<IRequestListState>(
    INIT_REQUEST_LIST_STATE
  );

  useEffect(() => {
    handleFecthSearchDataAPI();
  }, [searchedValue]);

  useEffect(() => {
    handleFetchDataAPI();
  }, [state.pageNumber]);

  useEffect(() => {
    if (state.requests.length > 0 && !selectedRequestId) {
      setSelectedRequestId(state.requests[0]?.id);
    }
  }, [state.requests]);

  const handleFecthSearchDataAPI = async () => {
    try {
      const response = await requestApi.searchRequests({
        searchedValue,
        perPage: 10,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setSearchFields(
          data.map((item: any) => {
            const { id, simulation_number } = item;
            return { label: simulation_number, value: id };
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchDataAPI = async () => {
    try {
      const response = await requestApi.getRequestsPagination({ ...state });
      if (response.status === HttpStatusCode.Ok) {
        const { last_page, data } = response.data;
        setState({
          ...state,
          paginationLength: last_page,
          requests: state.requests.concat(
            (data as Array<any>).map((data) =>
              DataFetchAPI.fetchRequestItemModel(data)
            )
          ),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <RequestListTemplate
      state={state}
      searchFields={searchFields}
      selectedRequestId={selectedRequestId}
      onChangeState={setState}
      onSelectRequest={(id) => setSelectedRequestId(id)}
      onChangeSearchedValue={(value) => setSearchedValue(value)}
    />
  );
};
export default RequestForQuotation;
