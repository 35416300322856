import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useTranslation } from "../../../common/customHooks";
import { IPackageModel } from "../../../interfaces";

const PackageNestedTable = ({
  packages,
}: {
  packages: Array<IPackageModel>;
}) => {
  const { tr } = useTranslation();
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "packageName",
        header: tr("packageName"),
      },
      {
        accessorKey: "packageType",
        header: tr("packageType"),
      },
    ],
    [tr]
  );

  const data = useMemo(
    () =>
      packages.map(({ name, type }) => ({
        packageName: name,
        packageType: type,
      })),
    [packages]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    renderDetailPanel: ({ row }) => <>...</>,
  });

  return <MaterialReactTable table={table} />;
};
export default PackageNestedTable;
