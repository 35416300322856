import ReactApexChart from "react-apexcharts";
import { useTranslation } from "../../../../common/customHooks";
import { ICommonComponent } from "../../../../interfaces";

const DashboardChart = ({
  className,
  style,
}: ICommonComponent): JSX.Element => {
  const { tr } = useTranslation();
  const state = {
    series: [
      {
        name: tr("totalDelivering"),
        data: [15, 16, 10, 25, 60, 42, 50, 22],
        color: "var(--primary-color)",
      },
      {
        name: tr("totalPending"),
        data: [25, 80, 40, 4, 40, 14, 10, 16],
        color: "#0069D1",
      },
    ],
  };
  return (
    <div
      style={style}
      className={`ds-dashboard-chart-layout p-3 ${className || ""}`}
    >
      <ReactApexChart
        options={{
          chart: {
            type: "line",
            stacked: false,
            height: 350,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: "zoom",
            },
          },
          title: {
            text: tr("shipmentMetrics"),
            align: "left",
            style: {
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "13px",
            },
          },
        }}
        series={state.series}
        type="area"
        height={290}
      />
    </div>
  );
};
export default DashboardChart;
