import { useEffect, useState } from "react";
import { IAnimatedLine } from "../../../interfaces";

const AnimatedLine = ({
  percentage,
  className,
  style,
  lineClassName,
}: IAnimatedLine) => {
  const [lineWidth, setLineWidth] = useState<number>(0);

  useEffect(() => {
    if (percentage > 0 && percentage <= 100) {
      const timer = setTimeout(() => {
        setLineWidth(percentage);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [percentage]);

  return (
    <div style={style} className={`animated-line-container ${className || ""}`}>
      <div
        className={`animated-line ${lineClassName || ""}`}
        style={{ width: `${lineWidth}%` }}
      />
    </div>
  );
};
export default AnimatedLine;
