import { useNavigate } from "react-router-dom";
import { AnimatedLine, Button, UserAvatar } from "../../molecules";
import { IUserAppStatus } from "../../../interfaces";
import { Data } from "../../../common";
import CountUp from "react-countup";
import { useTranslation } from "../../../common/customHooks";

const UserAppStatus = ({
  className,
  style,
  percentage,
}: IUserAppStatus): JSX.Element => {
  const navigate = useNavigate();
  const { tr } = useTranslation();
  const onManageProfile = () => navigate("/manage-profile");
  return (
    <div
      style={style}
      className={`w-100 ds-uas-layout d-flex flex-wrap justify-between py-2 px-3 ${
        className || ""
      }`}
    >
      <div className="d-flex flex-wrap py-1">
        <div>
          <UserAvatar size="40" onClick={onManageProfile} />
        </div>
        <div className="mx-2 px-2">
          <div className="ds-uas-title mb-1">{tr("completeYourProfile")}</div>
          <div className="ds-uas-sub-title">
            {tr("completeProfileDescribtion")}
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-between my-2">
        <div className="mx-5">
          <div
            className={`${Data.getProfileAppStatusClassName(
              percentage
            )} ds-uas-status mb-1`}
          >
            {tr("profileCompletionIsAt")} <CountUp end={percentage} />%
          </div>
          <AnimatedLine
            lineClassName={Data.getProfileLineStatusClassName(percentage)}
            percentage={percentage}
          />
        </div>

        <Button
          style={{ maxWidth: "90px", maxHeight: "30px", fontSize: "11px" }}
          onClick={onManageProfile}
          text={tr("editProfile")}
        />
      </div>
    </div>
  );
};

export default UserAppStatus;
