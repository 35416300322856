import { ICommonComponent } from "../../../interfaces";

const CheckedIcon = ({ className }: ICommonComponent): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <g clip-path="url(#clip0_599_1432)">
        <path
          d="M24.1885 45.9353C18.5036 45.8527 13.0842 43.5151 9.12275 39.4368C5.16126 35.3586 2.98211 29.8736 3.0647 24.1886C3.14729 18.5036 5.48484 13.0843 9.56312 9.12283C13.6414 5.16134 19.1263 2.98219 24.8113 3.06478C30.4963 3.14737 35.9156 5.48492 39.8771 9.5632C43.8386 13.6415 46.0178 19.1264 45.9352 24.8114C45.8526 30.4964 43.515 35.9157 39.4368 39.8772C35.3585 43.8387 29.8735 46.0178 24.1885 45.9353ZM24.1441 48.9974C30.6412 49.0918 36.9097 46.6014 41.5706 42.0739C46.2315 37.5465 48.903 31.353 48.9974 24.8559C49.0917 18.3588 46.6013 12.0903 42.0739 7.42937C37.5465 2.76848 31.3529 0.0969882 24.8558 0.00260414C18.3587 -0.09178 12.0902 2.39867 7.42929 6.92609C2.7684 11.4535 0.0969069 17.647 0.00252282 24.1441C-0.0918613 30.6413 2.39859 36.9098 6.92601 41.5707C11.4534 46.2316 17.6469 48.9031 24.1441 48.9974Z"
          fill="#45D6BE"
        />
        <path
          d="M33.7289 15.3536C33.7068 15.3745 33.686 15.3967 33.6667 15.4201L22.8349 28.8158L16.519 22.3105C16.0895 21.8985 15.5169 21.6692 14.9217 21.6711C14.3266 21.6729 13.7554 21.9057 13.3285 22.3204C12.9016 22.7351 12.6523 23.2993 12.6332 23.8941C12.6141 24.4889 12.8265 25.068 13.2259 25.5092L21.2107 33.7325C21.4258 33.9536 21.6832 34.129 21.9676 34.2484C22.252 34.3678 22.5575 34.4287 22.866 34.4275C23.1744 34.4262 23.4794 34.3629 23.7629 34.2412C24.0463 34.1195 24.3023 33.942 24.5156 33.7193L36.9618 18.6166C37.3844 18.1857 37.6205 17.6059 37.6189 17.0024C37.6174 16.3989 37.3785 15.8203 36.9537 15.3916C36.529 14.9629 35.9526 14.7186 35.3491 14.7115C34.7457 14.7044 34.1637 14.9351 33.7289 15.3536Z"
          fill="#45D6BE"
        />
      </g>
      <defs>
        <clipPath id="clip0_599_1432">
          <rect width="49" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CheckedIcon;
