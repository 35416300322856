import { ISelectCheckBoxes } from "../../../interfaces";
import { Label } from "../../atoms";
import { Checkbox } from "../../molecules";

const SelectCheckBoxes = ({
  className,
  style,
  label,
  selectedIndex,
  isObligatoryLabel,
  options,
  onSelect,
}: ISelectCheckBoxes): JSX.Element => {
  return (
    <div style={style} className={className}>
      {label && (
        <Label
          className="mb-2"
          isObligatory={isObligatoryLabel === true}
          text={label}
        />
      )}
      <div className="row">
        {options.map((option, index) => (
          <Checkbox
            key={index}
            className="col-lg-3 col-md-6 col-12"
            isChecked={selectedIndex === index}
            onChange={() => onSelect(index)}
            text={option}
          />
        ))}
      </div>
    </div>
  );
};
export default SelectCheckBoxes;
