import { Card } from "..";
import { Data } from "../../../common";
import { EAuthType, ESignWithType } from "../../../enums";
import { IAuthForm } from "../../../interfaces";
import { AuthButton, PageTitle } from "../../atoms";
import { AuthFormFooter, SignWith } from "../../molecules";
import { CheckedIcon } from "../../icons";
import { useTranslation } from "../../../common/customHooks";

const AuthForm = ({
  className,
  style,
  childClassName,
  type,
  children,
  isDisabledSubmitButton,
  onSubmit,
}: IAuthForm): JSX.Element => {
  const { tr } = useTranslation();
  const { title, subTitle } = Data.getAuthTitleSubTitle(tr, type);
  const submitText = Data.getAuthButtonText(tr, type);
  return (
    <div style={style} className={`${className} ||" w-100 h-100`}>
      <Card className="px-2 ds-auth-card w-100 h-100">
        <div className="container-fluid py-3">
          {type === EAuthType.emailSentSuccessfully && (
            <div className="d-flex justify-center mb-3">
              <CheckedIcon />
            </div>
          )}
          <PageTitle
            title={title}
            subTitle={subTitle}
            isCentered={type === EAuthType.emailSentSuccessfully}
          />
          <div className="w-100 h-100 mt-3">
            <div className={`${childClassName || ""} w-100 h-100`}>
              {children}
            </div>
            {type !== EAuthType.emailSentSuccessfully && (
              <AuthButton
                isDisabled={isDisabledSubmitButton}
                onClick={onSubmit}
                className="mt-3"
                text={submitText}
              />
            )}
          </div>
          <div className="my-3">
            {type === EAuthType.login && (
              <div className="w-100 h-100">
                <SignWith className="mb-3" type={ESignWithType.google} />
                <SignWith type={ESignWithType.apple} />
              </div>
            )}
          </div>
          {type !== EAuthType.emailSentSuccessfully && (
            <AuthFormFooter type={type} />
          )}
        </div>
      </Card>
    </div>
  );
};
export default AuthForm;
