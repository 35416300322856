import { useState } from "react";
import { IInput } from "../../../interfaces";
import { Label } from "../../atoms";
import { useLanguages, useTranslation } from "../../../common/customHooks";
import { EInputTypes } from "../../../enums";
import { Validation } from "../../../common";
const Input = ({
  className,
  inputClassName,
  style,
  labelStyle,
  label,
  isObligatoryLabel,
  isTwoPoints,
  placeholder,
  value,
  type,
  onChange,
  isValidate,
  errorValidateText,
  validatonOption,
}: IInput): JSX.Element => {
  const { isRtl } = useLanguages();
  const { tr } = useTranslation();
  const [changedType, setChangedType] = useState<EInputTypes | undefined>(type);
  const { isValide, textError } = Validation.inputValidation({
    tr,
    type,
    value,
    option: validatonOption,
  });
  const [counter, setCounter] = useState<number>(0);
  const isValidationForm = isValidate && counter > 0;
  return (
    <div
      style={{ position: "relative", ...style }}
      className={`${className || ""} w-100`}
    >
      {label && (
        <Label
          className="mb-2"
          style={labelStyle}
          text={label}
          isObligatory={isObligatoryLabel === true}
          isTwoPoints={isTwoPoints === true}
        />
      )}
      <input
        className={`${inputClassName} ds-input w-100 ${
          isValidationForm &&
          `${isValide ? "ds-input-valide-border" : "ds-input-danger-border"}`
        } `}
        type={changedType || "text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          counter === 0 && setCounter(counter + 1);
          type === EInputTypes.number
            ? onChange(parseFloat(e.target.value))
            : onChange(e.target.value);
        }}
      />
      {type === EInputTypes.password && (
        <div
          className={`${
            isRtl
              ? "ds-auth-icon-password-rtl"
              : "ds-auth-icon-password-not-rtl"
          } ds-auth-icon-password ds-grey-strok-color`}
        >
          {changedType === EInputTypes.password ? (
            <i
              className="bi bi-eye"
              onClick={() => setChangedType(EInputTypes.text)}
            />
          ) : (
            <i
              className="bi bi-eye-slash"
              onClick={() => setChangedType(EInputTypes.password)}
            />
          )}
        </div>
      )}

      <div
        className={`d-flex ${
          isValidationForm && !isValide ? "justify-between" : "justify-end"
        }`}
      >
        {isValidationForm && !isValide && (
          <div className="ds-input-error-text w-100 ps-1">
            {errorValidateText || textError}
          </div>
        )}
      </div>
    </div>
  );
};
export default Input;
