import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { ILayoutSidebar } from "../../../interfaces";
import { Data } from "../../../common";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSidebarCollapsed } from "../../../redux";
import { useTheme, useTranslation } from "../../../common/customHooks";
import { EUserRole } from "../../../enums";

const LayoutSidebar = ({
  isToggled,
  isCollapsedSidebar,
  onChangeToggled,
  isRtl,
}: ILayoutSidebar): JSX.Element => {
  const { tr } = useTranslation();
  const { isDarkMode, changeTheme } = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  return (
    <Sidebar
      collapsed={window.innerWidth > 992 ? isCollapsedSidebar : false}
      onBackdropClick={() => onChangeToggled(false)}
      toggled={isToggled}
      breakPoint="lg"
      rtl={isRtl}
    >
      <div className="py-3 h-100">
        <Menu className="ds-sidebar-menu">
          {Data.getSidebarNavBarDataItems(tr, EUserRole.customer).map(
            (item, index) => (
              <div key={index}>
                {item.subItems ? (
                  <SubMenu
                    className={`ds-text-side-nav ds-sidebar-link-item ${
                      item.subItems
                        .map((subItem) => subItem.to)
                        .includes(pathname) && "ds-primary-color"
                    }`}
                    label={item.label}
                    icon={Data.getSidebarIcons(item.icon, pathname)}
                  >
                    {item.subItems?.map((subItem, index) => (
                      <MenuItem
                        className={`ds-sidebar-link-item ${
                          subItem.to === pathname && "ds-primary-color"
                        }`}
                        key={index}
                        component={<Link to={subItem.to} />}
                      >
                        {subItem.label}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    className={`ds-text-side-nav ds-sidebar-link-item ${
                      item.to === pathname && "ds-primary-color"
                    }`}
                    icon={Data.getSidebarIcons(item.icon, pathname)}
                    component={<Link to={item.to || "#"} />}
                  >
                    <span className="ds-text-side-nav">{item.label}</span>
                  </MenuItem>
                )}
              </div>
            )
          )}
        </Menu>
        <Menu>
          <MenuItem
            className="ds-sidebar-config-item d-none d-lg-block"
            icon={
              isDarkMode ? (
                <i
                  style={{ fontSize: "22px" }}
                  className="bi bi-brightness-high"
                />
              ) : (
                <i style={{ fontSize: "22px" }} className="bi bi-moon" />
              )
            }
            onClick={() => changeTheme()}
          >
            {!isDarkMode ? tr("darkMode") : tr("lightMode")}
          </MenuItem>
          <MenuItem
            className="ds-sidebar-config-item d-none d-lg-block"
            icon={
              <i
                style={{ fontSize: "22px" }}
                className={`bi ${
                  !isCollapsedSidebar
                    ? "bi-arrow-left-circle"
                    : "bi-arrow-right-circle"
                }`}
              />
            }
            onClick={() => dispatch(setSidebarCollapsed(!isCollapsedSidebar))}
          >
            {tr("collapsePanel")}
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
};
export default LayoutSidebar;
