import { IPageProps } from "../../interfaces";
import { ThemeContext } from "../../contexts";
import { useTheme } from "../../common/customHooks";
import { useEffect } from "react";

const ThemeProvider = (props: IPageProps) => {
  const { isDarkMode, getStoredTheme } = useTheme();

  useEffect(() => {
    document.documentElement.classList.toggle("dark-theme", !isDarkMode);
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={getStoredTheme()}>
      {props.children}
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
