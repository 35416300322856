import { Validation } from "../../common";
import { useTranslation } from "../../common/customHooks";
import { AuthForm, Checkbox, Input, TextLink } from "../../components";
import { EAuthType, EInputTypes } from "../../enums";
import { ILoginTemplate } from "../../interfaces";
import { AuthLayout } from "../../layouts";

const LoginTemplate = ({
  authState,
  isLoading,
  onChangeState,
  onSubmit,
}: ILoginTemplate): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <AuthLayout type={EAuthType.login}>
      <AuthForm
        isDisabledSubmitButton={
          isLoading ||
          !Validation.loginValidation(authState.email, authState.password)
        }
        onSubmit={onSubmit}
        type={EAuthType.login}
      >
        <Input
          label={tr("email")}
          isObligatoryLabel
          type={EInputTypes.email}
          placeholder={tr("enterYouEmail")}
          value={authState.email}
          isValidate
          onChange={(value) =>
            typeof value === "string" &&
            onChangeState({ ...authState, email: value })
          }
        />
        <Input
          label={tr("password")}
          isObligatoryLabel
          className="mt-2 mb-3"
          placeholder={tr("enterYourPassword")}
          type={EInputTypes.password}
          isValidate
          value={authState.password}
          onChange={(value) =>
            typeof value === "string" &&
            onChangeState({ ...authState, password: value })
          }
        />
        <div className="d-flex justify-between px-1">
          <Checkbox
            isChecked={authState.isRememberMe}
            onChange={(cheked) =>
              onChangeState({ ...authState, isRememberMe: cheked })
            }
            text={tr("rememberMe")}
          />
          <TextLink
            isLink={true}
            text={tr("isForgotPassword")}
            href="/forgot-password"
          />
        </div>
      </AuthForm>
    </AuthLayout>
  );
};
export default LoginTemplate;
