import { Tab, Tabs } from "react-bootstrap";
import { Card } from "../../components";
import { IManageProfileTemplate } from "../../interfaces";
import { Data } from "../../common";
import { useTranslation } from "../../common/customHooks";
import { PageLayout } from "../../layouts";
import { Activities, EditProfile, Preference, SecurityPassword } from "./forms";

const ManageProfileTemplate = (props: IManageProfileTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const { commonState, onChangeCommonState } = props;
  const forms = [
    <EditProfile
      onChangeState={props.onChangeEditProfileState}
      state={props.editProfileState}
      onSubmit={props.onSubmitEditProfile}
    />,
    <SecurityPassword
      onChangeState={props.onChangeSecurityPasswordState}
      state={props.securityPasswordState}
      onSubmit={props.onSubmitSecurityPassword}
    />,
    <Preference
      onChangeState={props.onChangePrefenceState}
      state={props.preferenceState}
      onSubmit={props.onSubmitPreference}
    />,
    <></>,
    <Activities
      deviceList={props.deviceList}
      onSignOutDevice={() => {}}
      onSignOutFromAllDevices={props.onSignOutFromAllDevices}
    />,
  ];
  return (
    <PageLayout title={tr("manageProfile")}>
      <Card
        isScroll
        style={{ height: "92%" }}
        bodyClassName="pt-0 ds-ps-md-5"
        headerClassName="pb-0"
        header={
          <Tabs
            defaultActiveKey={commonState.selectedTabIndex}
            className="mb-3"
            onSelect={(key) =>
              onChangeCommonState({ selectedTabIndex: parseInt(key!) })
            }
          >
            {Data.getManageTabsData(tr).map((tabName, index) => (
              <Tab key={index} eventKey={index} title={tabName} />
            ))}
          </Tabs>
        }
      >
        {forms[props.commonState.selectedTabIndex]}
      </Card>
    </PageLayout>
  );
};
export default ManageProfileTemplate;
