import { Card } from "react-bootstrap";
import { ICard } from "../../../interfaces";
import { PageTitle } from "../../atoms";

const CustomCard = ({
  className,
  children,
  header,
  footer,
  isScroll,
  bodyClassName,
  title,
  style,
  headerClassName,
}: ICard): JSX.Element => {
  return (
    <Card style={style} className={`${className || ""} ds-card`}>
      {header && (
        <Card.Header className={`ds-card-header ${headerClassName || ""}`}>
          {header}
        </Card.Header>
      )}
      <Card.Body
        className={`w-100 ${bodyClassName || ""} ${
          isScroll && "ds-scroll-card"
        }`}
      >
        {title && (
          <PageTitle
            titleClassName="ds-title-card"
            className="mb-1"
            title={title}
          />
        )}
        {children}
      </Card.Body>
      {footer && <Card.Footer className="ds-card-footer">{footer}</Card.Footer>}
    </Card>
  );
};
export default CustomCard;
