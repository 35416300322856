import { useState } from "react";
import { Data } from "../../../common";
import { useDrawer, useTranslation } from "../../../common/customHooks";
import { EFileType } from "../../../enums";
import { IClaim } from "../../../interfaces";
import { FileReader } from "../../organisms";
import { ShipmentStatus } from "../../atoms";

const ClaimCancelItem = ({
  className,
  style,
  type,
  date,
  fileUrl,
  name,
  incidentDate,
  content,
  requestedAmount,
  status,
  onClick,
}: IClaim): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const [isExpanded, setIsExpanded] = useState(false);
  const simpleFileName = fileUrl?.split("/").slice(-1)[0];
  const contentPreview =
    content.length > 80 ? content.slice(0, 80) + "..." : content;

  const handleShowDate = (label: string, date: Date) => {
    return (
      <div
        className="flex justify-start md:justify-center"
        style={{ fontSize: "14px" }}
      >
        <i className="bi bi-calendar ds-primary-color"></i>
        <div className="ps-2 ds-black-color">
          <a className="font-bold">{`${label} : `}</a>
          {`${Data.getFormdatedDate(date)} | ${Data.getTimeStringFromDate(
            date
          )}`}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ borderRadius: "5px", padding: "15px", ...style }}
      className={`w-100 ds-font-poppins ds-bg-secondary ${
        onClick && "cursor-pointer"
      } ${className || ""}`}
      onClick={onClick}
    >
      <div className="ds-black-color mb-2">
        <div className="flex justify-between">
          <div
            className="font-bold"
            style={{ fontSize: "16px" }}
          >{name}</div>
          {status && type === "claim" && <ShipmentStatus {...status} />}
        </div>
        <div
          className="font-normal ds-text-color my-2"
          style={{ fontSize: "12px" }}
          dangerouslySetInnerHTML={{
            __html: isExpanded ? content : contentPreview,
          }}
        />
        <div>
          {content.length > 80 && (
            <div
              style={{ textDecoration: "underline", fontSize: "14px" }}
              className="ds-primary-color font-bold cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? tr("readLess") : tr("readMore")}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-12">
          {handleShowDate(
            tr(type === "claim" ? "claimDate" : "cancelDate"),
            date
          )}
        </div>
        {incidentDate && (
          <div className="col-md-3 col-12">
            {handleShowDate(tr("incidentDate"), incidentDate)}
          </div>
        )}
        {fileUrl && simpleFileName && (
          <div className="col-md-3 col-12">
            <div
              className="flex justify-center cursor-pointer"
              style={{ fontSize: "14px" }}
              onClick={() =>
                drawer.open({
                  title: simpleFileName,
                  children: (
                    <FileReader fileType={EFileType.img} path={fileUrl} />
                  ),
                })
              }
            >
              <i className="bi bi-link-45deg ds-primary-color"></i>
              <div className="font-bold ps-2 ds-dark-blue-color">
                {simpleFileName}
              </div>
            </div>
          </div>
        )}

        <hr style={{ height: "3px" }} className="ds-bg-secondary my-3 px-3" />
        <div className="flex ds-text-color" style={{ fontSize: "14px" }}>
          {`${tr("requestedAmount")} :`}
          <div className="font-bold ds-black-color ps-1">{requestedAmount}</div>
        </div>
      </div>
    </div>
  );
};
export default ClaimCancelItem;
