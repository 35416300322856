import { ICommonComponent } from "../../../interfaces";

const ShipmentIcon = ({ className }: ICommonComponent): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
      fill="#849BB3"
    >
      <path
        id="Vector"
        d="M9.93492 9.54553V16.7065C9.93492 16.8826 10.0596 17 10.2466 17C10.309 17 10.3713 17 10.4025 16.9707C11.8986 16.1782 16.4494 13.7423 16.4494 13.7423C17.0416 13.4195 17.4157 12.8325 17.4157 12.1869V5.7596C17.4157 5.58351 17.291 5.46612 17.104 5.46612C17.0416 5.46612 16.9793 5.46612 16.9481 5.49547L10.2466 9.04661C10.0596 9.13466 9.93492 9.34009 9.93492 9.54553ZM9.31152 7.54985L16.0442 3.99871C16.2 3.91066 16.2312 3.73457 16.1377 3.58783C16.1065 3.55848 16.0754 3.52913 16.0442 3.49978C14.548 2.70738 9.96609 0.242123 9.96609 0.242123C9.37386 -0.0807078 8.62579 -0.0807078 8.03356 0.242123C8.03356 0.242123 3.4516 2.67803 1.95545 3.49978C1.79961 3.58783 1.76844 3.76392 1.86195 3.91066C1.89312 3.94001 1.92428 3.96936 1.95545 3.99871L8.68813 7.54985C8.87515 7.63789 9.12451 7.63789 9.31152 7.54985ZM7.75303 9.04661L1.05153 5.49547C0.895682 5.40742 0.708664 5.46612 0.615154 5.61286C0.583984 5.64221 0.583984 5.70091 0.583984 5.7596V12.1575C0.583984 12.8032 0.958022 13.3902 1.55025 13.713C1.55025 13.713 6.10104 16.1489 7.59719 16.9413C7.75303 17.0294 7.94005 16.9707 8.03356 16.8239C8.06473 16.7652 8.06473 16.7065 8.06473 16.6772V9.54553C8.06473 9.34009 7.94005 9.13466 7.75303 9.04661Z"
      />
    </svg>
  );
};
export default ShipmentIcon;
