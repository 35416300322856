import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { IInputSearch } from "../../../interfaces";
import { useTranslation } from "../../../common/customHooks";

const InputSearch = ({
  placeholder,
  onClear,
  searchFields,
  onSearch,
  onSelect,
  onChange,
}: IInputSearch): JSX.Element => {
  const { tr } = useTranslation();
  const formatResult = (item: any) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
    );
  };
  return (
    <ReactSearchAutocomplete
      className="w-100"
      items={searchFields.map((field, index) => ({
        id: index,
        name: field.label,
      }))}
      onSearch={(keyWorld, result) => {
        onChange && onChange(keyWorld);
        onSearch && onSearch(result);
      }}
      onSelect={onSelect}
      autoFocus
      formatResult={formatResult}
      onClear={onClear}
      placeholder={placeholder || tr("search")}
    />
  );
};
export default InputSearch;
