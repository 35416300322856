import { ICommonComponent } from "../../../interfaces";

const LowerArrowIcon = ({
  className,
  style,
}: ICommonComponent): JSX.Element => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle
        cx="7"
        cy="7.00195"
        r="7"
        transform="rotate(180 7 7.00195)"
        fill="#FA8334"
        fillOpacity="0.2"
      />
      <path
        d="M6.50049 10.299C6.565 10.3619 6.64106 10.4118 6.72449 10.446C6.81082 10.4827 6.90366 10.5018 6.99749 10.502H7.06749C7.13242 10.4988 7.19633 10.4846 7.25649 10.46C7.3045 10.4412 7.34936 10.4153 7.38949 10.383C7.42144 10.3644 7.45187 10.3433 7.48049 10.32L9.58049 8.31795C9.71509 8.18985 9.79329 8.01353 9.79788 7.82778C9.80248 7.64202 9.73309 7.46205 9.60499 7.32745C9.47689 7.19286 9.30057 7.11466 9.11482 7.11006C8.92906 7.10547 8.74909 7.17485 8.61449 7.30295L7.69749 8.16395L7.69749 4.20195C7.69749 4.0163 7.62374 3.83825 7.49247 3.70698C7.36119 3.5757 7.18314 3.50195 6.99749 3.50195C6.81184 3.50195 6.63379 3.5757 6.50252 3.70698C6.37124 3.83825 6.29749 4.0163 6.29749 4.20195L6.29749 8.11495L5.39449 7.20495C5.32942 7.13934 5.252 7.08727 5.1667 7.05173C5.0814 7.01619 4.9899 6.99789 4.89749 6.99789C4.80509 6.99789 4.71359 7.01619 4.62829 7.05173C4.54299 7.08727 4.46557 7.13934 4.40049 7.20495C4.33488 7.27003 4.28281 7.34745 4.24727 7.43275C4.21173 7.51805 4.19344 7.60954 4.19344 7.70195C4.19344 7.79436 4.21173 7.88586 4.24727 7.97116C4.28281 8.05646 4.33488 8.13388 4.40049 8.19895L6.50049 10.299Z"
        fill="#FA8334"
      />
    </svg>
  );
};
export default LowerArrowIcon;
