import { EInputTypes } from "../../enums";
import {
  IManageClaimCancelState,
  IUseFormValidator,
  IValidationInputOptions,
} from "../../interfaces";
import { TTranslateFunction } from "../../types";
import validator from "validator";

class Validation {
  static emailValidation(email: string): boolean {
    return validator.isEmail(email);
  }

  static passwordValidation(password: string): boolean {
    return validator.isStrongPassword(password, {
      minLength: 8,
      minSymbols: 1,
      minUppercase: 1,
      minLowercase: 1,
    });
  }

  static loginValidation(email: string, password: string): boolean {
    return this.emailValidation(email) && this.passwordValidation(password);
  }

  static inputValidation({
    option,
    type,
    value,
    tr,
  }: {
    type?: EInputTypes;
    value?: string | number;
    option?: IValidationInputOptions;
    tr: TTranslateFunction;
  }): IUseFormValidator {
    const length: number = option?.minLength || 1;
    let returnedValue = {
      isValide: value!.toString().length > length ? true : false,
      textError: tr("fieldRequired"),
    };
    if (option?.confirmedPassword) {
      const isValide =
        this.passwordValidation(value!.toString()) &&
        option?.confirmedPassword.password === value;
      return { isValide, textError: tr("confirmPasswordValidation") };
    }
    switch (type) {
      case EInputTypes.email: {
        const isValide = this.emailValidation(value!.toString());
        return {
          isValide,
          textError: tr("emailFieldRequired"),
        };
      }
      case EInputTypes.password: {
        const isValide = this.passwordValidation(value!.toString());
        return {
          isValide,
          textError: !isValide
            ? tr("passwordFieldRequired")
            : returnedValue.textError,
        };
      }
    }
    return returnedValue;
  }

  static phoneNumberValidation(
    phoneNumber: string,
    tr: TTranslateFunction
  ): IUseFormValidator {
    return {
      isValide: validator.isMobilePhone(phoneNumber),
      textError: tr("phoneNumberValidatorError"),
    };
  }
  static manageCancelValidation(state: IManageClaimCancelState): boolean {
    return (
      typeof state.reasonId !== "undefined" &&
      typeof state.requestedAmount !== "undefined" &&
      typeof state.details !== "undefined"
    );
  }
  static manageClaimValidation(state: IManageClaimCancelState): boolean {
    return (
      this.manageCancelValidation(state) &&
      typeof state.incidentDateHour !== "undefined" &&
      typeof state.request !== "undefined"
    );
  }
}
export default Validation;
