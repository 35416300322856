import { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import {
  ICreateShipmentPackageState,
  IDocumentListState,
  IFromToState,
  IGeneraleInformationState,
  IServiceCreateShipmentState,
} from "../../interfaces";
import { CreateSimulationTemplate } from "../../templates";
import {
  INIT_CREATE_SHIPMENT_DOCUMENT_LIST,
  INIT_CREATE_SHIPMENT_PACKAGE_STATE,
  INIT_FROM_TO_STATE,
  INIT_GENERALE_INFORMATION_STATE,
  INIT_SERVICE_CREATE_SHIPMENT,
} from "../../consts";
import { useTranslation } from "../../common/customHooks";

const CreateShipment = (): JSX.Element => {
  const { tr } = useTranslation();
  const [generalInformationState, setGeneralInformationState] =
    useState<IGeneraleInformationState>(INIT_GENERALE_INFORMATION_STATE);
  const [fromToState, setFromToState] =
    useState<IFromToState>(INIT_FROM_TO_STATE);
  const [serviceState, setServiceState] = useState<IServiceCreateShipmentState>(
    INIT_SERVICE_CREATE_SHIPMENT
  );
  const [packageState, setPackageState] = useState<ICreateShipmentPackageState>(
    INIT_CREATE_SHIPMENT_PACKAGE_STATE
  );
  const [documentListState, setDocumentListState] =
    useState<IDocumentListState>(INIT_CREATE_SHIPMENT_DOCUMENT_LIST);

  const handleAddDangerousGoods = async () => {};
  const handleSubmit = async () => {};
  const handleAddPackage = async () => {};
  
  return (
    <Beforeunload onBeforeunload={() => tr("areYouSurToLeave")}>
      <CreateSimulationTemplate
        fromToState={fromToState}
        onChangeFromToState={setFromToState}
        serviceState={serviceState}
        onChangeServiceState={setServiceState}
        generalInformationState={generalInformationState}
        onChangeGeneralInformationState={setGeneralInformationState}
        documentListState={documentListState}
        onChangeDocumentListStae={setDocumentListState}
        onAddDangerousGoods={handleAddDangerousGoods}
        onSubmit={handleSubmit}
        onAddPackage={handleAddPackage}
        packageState={packageState}
        onChangePackageState={setPackageState}
      />
    </Beforeunload>
  );
};
export default CreateShipment;
