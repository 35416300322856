import { Modal } from "react-bootstrap";
import { CancleIcon } from "../../icons";
import { IModal } from "../../../interfaces";
import { ClearSaveButtons } from "../../molecules";
import { useTranslation } from "../../../common/customHooks";

const CustomModal = ({
  style,
  className,
  title,
  isOpen,
  onClose,
  children,
  bodyClassName,
  isFooterSubmit,
  footerClassName,
  onSubmit,
  onCancel,
  footer,
  cancelFooterBtnTitle,
  submitFooterBtnTitle,
}: IModal) => {
  const { tr } = useTranslation();
  const isFooterCondition = isFooterSubmit || footer;
  return (
    <Modal
      contentClassName={`${className || ""} ds-modal`}
      style={style}
      show={isOpen}
      onExit={onClose}
      centered
    >
      <Modal.Header
        className={`d-flex ${
          title ? "justify-between" : "justify-end"
        } px-3 ds-modal-header`}
      >
        {title && <div className="ds-modal-title"> {title} </div>}
        <CancleIcon onCancle={onClose} />
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      {isFooterCondition && (
        <Modal.Footer
          className={`ds-modal-footer ${footerClassName || ""} px-3 pt-0`}
        >
          {footer || (
            <ClearSaveButtons
              clearBtnClassName="ds-cancel-modal-btn"
              saveBtnClassName="ds-submit-modal-btn"
              clearText={cancelFooterBtnTitle || tr("cancel")}
              saveText={submitFooterBtnTitle || tr("submit")}
              onSave={() => onSubmit && onSubmit()}
              isClear
              onClear={() => {
                onClose();
                onCancel && onCancel();
              }}
            />
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default CustomModal;
