import DatePicker from "react-date-picker";
import { Label } from "../../atoms";
import { IDatePicker } from "../../../interfaces";

const CustomDatePicker = ({
  className,
  label,
  isObligatoryLabel,
  value,
  onChange,
  style,
}: IDatePicker): JSX.Element => {
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          className="mb-2"
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      <DatePicker className="w-100" onChange={onChange} value={value} />
    </div>
  );
};
export default CustomDatePicker;
