import { Data } from "../../common";
import { useTranslation } from "../../common/customHooks";
import {
  Button,
  Card,
  DatePicker,
  InputSearch,
  PageTitle,
} from "../../components";
import { EButtonType } from "../../enums";
import {
  IMultiInterfaceUsedButton,
  ISimulationListTemplate,
} from "../../interfaces";

const SimulationListTemplate = ({
  state,
  onChangeState,
  onCreateSimulation,
}: ISimulationListTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const renderDataTable = () => {
    return state.simulationList.map((item) => ({
      tracking: item.tracking,
      date: Data.getFormdatedDate(item.date),
      pickUpDate: Data.getFormdatedDate(item.pickUpDate),
      sender: item.senderName,
      senderCompany: item.senderCompany,
      origineDeparture: item.origineDeparture,
      receiver: item.receiver,
      receiverCompany: item.receiverCompany,
      arrival: item.arrival,
    }));
  };
  const CreateSimulationButton = ({
    isMobile,
  }: IMultiInterfaceUsedButton): JSX.Element => {
    return (
      <div className="d-flex justify-end">
        <Button
          className={`ds-save-simulation-btn ${isMobile && "bg-transparent"}`}
          type={isMobile ? EButtonType.secondary : undefined}
          icon={<i className="bi bi-plus-lg" />}
          onClick={onCreateSimulation}
          text={isMobile ? tr("create") : tr("createSimulation")}
        />
      </div>
    );
  };
  return (
    <div className="w-100 h-100 container-fluid">
      <div className="row">
        <div className="col-6 col-md-12">
          <PageTitle className="mb-2" title={tr("simulationList")} />
        </div>
        <div className="col-6 d-md-none d-xs-block">
          <CreateSimulationButton isMobile={true} />
        </div>
      </div>
      <Card
        bodyClassName="ds-body-card-simulation-list"
        className="mt-2"
        isScroll
      >
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="row mb-2">
              <div className="col-12 col-md-6 ds-mb-xs-2">
                <DatePicker
                  className="mr-2"
                  onChange={(date: any) => {
                    onChangeState({ ...state, searchedDate: date });
                  }}
                  value={state.searchedDate}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputSearch
                  onClear={() => onChangeState({ ...state, searchedValue: "" })}
                  searchFields={state.searchFiels}
                  onSelect={(result) =>
                    onChangeState({ ...state, searchedValue: result.name })
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-md-block">
            <CreateSimulationButton isMobile={false} />
          </div>
        </div>

        {/* <Table
          headerItemClassName="ds-bg-grey"
          columns={Data.getSimulationListColumns(tr)}
          data={renderDataTable()}
          isPagination
          selectedPageNumber={state.pageNumber}
          pageCount={state.paginationLength}
          onPageChange={(pageNumber) => onChangeState({ ...state,pageNumber })}
        /> */}
      </Card>
    </div>
  );
};
export default SimulationListTemplate;
