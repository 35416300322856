import { useTranslation } from "../../common/customHooks";
import {
  AuthForm,
  Checkbox,
  Input,
  InputSelect,
  SelectType,
  TextLink,
} from "../../components";
import { EAuthType, EInputTypes, ERegisterType } from "../../enums";
import { IRegisterTemplate } from "../../interfaces";
import { AuthLayout } from "../../layouts";

const RegisterTemplate = ({
  state,
  onSubmit,
  onChangeState,
}: IRegisterTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const selectRegisterTypeOptions = [
    { label: tr("person"), value: ERegisterType.carrier },
    { label: tr("company"), value: ERegisterType.customer },
  ];
  return (
    <AuthLayout type={EAuthType.register}>
      <AuthForm onSubmit={onSubmit} type={EAuthType.register}>
        {state.registerType === ERegisterType.customer && (
          <div className="d-flex justify-center mb-4 ds-mb-xs-2">
            <div className="d-flex justify-between flex-wrap">
              <div>{tr("doYouWantToBeCarrier")}</div>
              <TextLink
                className="px-1"
                isLink={false}
                text={tr("joinUs")}
                onClick={() =>
                  onChangeState({
                    ...state,
                    registerType: ERegisterType.carrier,
                  })
                }
              />
            </div>
          </div>
        )}
        <SelectType
          className="mb-3"
          label={tr("customerType")}
          isObligatoryLabel
          options={selectRegisterTypeOptions}
          onChange={(index, value) =>
            onChangeState({
              ...state,
              registerType: Object.values(ERegisterType).find(
                (type) => type === value
              )!,
            })
          }
          selectedIndex={selectRegisterTypeOptions.indexOf(
            selectRegisterTypeOptions.find(
              (option) => option.value === state.registerType
            )!
          )}
        />
        {state.registerType === ERegisterType.customer && (
          <div className="row ds-mb-md-2">
            <div className="col-md-6 col-12 ds-mb-xs-2">
              <Input
                isObligatoryLabel
                isValidate
                label={tr("companyNameUper")}
                value={tr("companyName")}
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({ ...state, companyName: value })
                }
                placeholder={tr("enterYourCompanyName")}
              />
            </div>
            <div className="col-md-6 col-12 ds-mb-xs-2">
              <Input
                isObligatoryLabel
                isValidate
                label={tr("vatCode")}
                placeholder={tr("enterYourVatCode")}
                value={state.vatCode}
                onChange={(value) =>
                  typeof value === "string" &&
                  onChangeState({ ...state, vatCode: value })
                }
              />
            </div>
          </div>
        )}
        <div className="row ds-mb-md-2">
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              isObligatoryLabel
              isValidate
              label={tr("firstName")}
              value={tr("firstName")}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, firstName: value })
              }
              placeholder={tr("enterYourFirstName")}
            />
          </div>
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              isObligatoryLabel
              isValidate
              label={tr("lastName")}
              placeholder={tr("enterYourLastName")}
              value={state.lastName}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, lastName: value })
              }
            />
          </div>
        </div>
        <div className="row ds-mb-md-2">
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <InputSelect
              isObligatoryLabel
              isValidate
              isMulti={false}
              label={tr("country")}
              options={state.country}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, country: value })
              }
              placeholder={tr("selectYourCountry")}
            />
          </div>
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <InputSelect
              isObligatoryLabel
              isValidate
              isMulti={false}
              label={tr("city")}
              placeholder={tr("selectYourCity")}
              options={state.city}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, city: value })
              }
            />
          </div>
        </div>
        <div className="row ds-mb-md-2">
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              isObligatoryLabel
              isValidate
              type={EInputTypes.email}
              label={tr("email")}
              placeholder={tr("enterYouEmail")}
              value={state.email}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, email: value })
              }
            />
          </div>
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              isObligatoryLabel
              isValidate
              label={tr("userName")}
              placeholder={tr("enterYourUsername")}
              value={state.userName}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, userName: value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              type={EInputTypes.password}
              isValidate
              isObligatoryLabel
              label={tr("password")}
              placeholder={tr("enterYourPassword")}
              value={state.password}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, password: value })
              }
            />
          </div>
          <div className="col-md-6 col-12 ds-mb-xs-2">
            <Input
              type={EInputTypes.password}
              isValidate
              isObligatoryLabel
              label={tr("confirmPassword")}
              placeholder={tr("enterYourConfirmPassword")}
              value={state.confirmedPassword}
              validatonOption={{
                confirmedPassword: { password: state.password },
              }}
              onChange={(value) =>
                typeof value === "string" &&
                onChangeState({ ...state, confirmedPassword: value })
              }
            />
          </div>
        </div>
        <div className="w-100 d-flex mt-3">
          <Checkbox
            isChecked={state.isAgreeAllTerms}
            onChange={(cheked) =>
              onChangeState({ ...state, isAgreeAllTerms: cheked })
            }
          />
          <div className="ps-1 pt-1 d-flex flex-wrap ds-agree-term-auth">
            <div className="text-nowrap">{tr("iAgreeToAll")}</div>
            <TextLink
              className="ps-1"
              isLink={false}
              text={tr("terms").toLowerCase()}
            />
            <span>,</span>
            <TextLink
              className="ps-1"
              isLink={false}
              text={tr("privacyPolicy")}
            />
            <span className="px-1">{tr("and").toLowerCase()}</span>
            <TextLink
              className="ps-1 ds-pre-line-xs"
              isLink={false}
              text={tr("cgu").toUpperCase()}
            />
          </div>
        </div>
      </AuthForm>
    </AuthLayout>
  );
};
export default RegisterTemplate;
