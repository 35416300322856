import { useDispatch, useSelector } from "react-redux";
import { IKeys, IRootState } from "../../../interfaces";
import { Elang } from "../../../enums";
import { setLang } from "../../../redux";
import { arFlag, enFlag, frFlag } from "../../../assets";
import { EnLang, FrLang, ArLang } from "../../../lang";

const useLanguages = () => {
  const dispatch = useDispatch();
  const lang: Elang = useSelector((state: IRootState) => state.setting.lang);
  const isRtl = lang === Elang.ar;

  const geUsedLanguage = (): Elang => {
    return lang;
  };
  const changeLanguage = (lang: Elang) => {
    dispatch(setLang(lang));
  };
  const getStoredLangFlag = () => {
    switch (lang) {
      case Elang.fr: {
        return frFlag;
      }
      case Elang.en: {
        return enFlag;
      }
      case Elang.ar: {
        return arFlag;
      }
    }
  };
  const getLangData = (): IKeys => {
    switch (lang) {
      case Elang.en: {
        return EnLang;
      }
      case Elang.fr: {
        return FrLang;
      }
      case Elang.ar: {
        return ArLang;
      }
    }
  };
  return {
    geUsedLanguage,
    changeLanguage,
    getStoredLangFlag,
    isRtl,
    getLangData,
  };
};
export default useLanguages;
