import { useEffect, useState } from "react";
import { ShipmentRequestMapTrackingTemplate } from "../../templates";
import { IMapTrackingState } from "../../interfaces";
import { RequestAPI, ShipmentAPI } from "../../apis";
import { useParams } from "react-router-dom";
import { HttpStatusCode } from "axios";

const ShipmentRequestMapTracking = ({
  type,
}: {
  type: "shipment" | "request";
}): JSX.Element => {
  const shipmentApi = new ShipmentAPI();
  const requestApi = new RequestAPI();
  const { id, accessToken } = useParams();
  const [state, setState] = useState<IMapTrackingState>();

  useEffect(() => {
    if (id && accessToken) {
      handleFetchAPILocationData(parseInt(id), accessToken);
    }
  }, [id, accessToken]);

  const handleFetchAPILocationData = async (
    id: number,
    accessToken: string
  ) => {
    try {
      const response =
        type === "shipment"
          ? await shipmentApi.getShipmentFromTo(id, accessToken)
          : await requestApi.getRequestFromTo(id, accessToken);
      if (response.status === HttpStatusCode.Ok) {
        const { from, to } = response.data;
        setState({
          from: {
            lat: parseFloat(from?.latitude),
            lng: parseFloat(from?.longitude),
          },
          to: {
            lat: parseFloat(to?.latitude),
            lng: parseFloat(to?.longitude),
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return <ShipmentRequestMapTrackingTemplate {...state} />;
};
export default ShipmentRequestMapTracking;
