import React from "react";
import { ToggledLine } from "../../../../components";
import {
  CqCarrierInformation,
  CqCustomerInformation,
  CqDocuments,
  CqPackages,
  CqPayments,
  CqServices,
} from "./forms";
import { Data } from "../../../../common";
import { useTranslation } from "../../../../common/customHooks";
import { ICreateQuotationTemplate } from "../../../../interfaces";

const CreateQuotationForm = (props: ICreateQuotationTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const forms: Array<React.ReactNode> = [
    <CqCarrierInformation {...props} />,
    <CqCustomerInformation {...props} />,
    <CqPackages />,
    <CqDocuments {...props} />,
    <CqServices />,
    <CqPayments {...props} />,
  ];
  const handleFormIndexAdd = (index: number) => {};
  return (
    <div className="w-100 h-100">
      {Data.getCreateQuotationToggledLinesData(tr).map((data, index) => (
        <div key={index}>
          {index === 0 && <hr />}
          <ToggledLine
            isAdd={data.isAddBtn}
            className="my-3"
            title={data.label}
            onAdd={() => handleFormIndexAdd(index)}
            buttonAddTitle={data.addButtonText}
          >
            {forms[index]}
          </ToggledLine>
          {index !== forms.length - 1 && <hr />}
        </div>
      ))}
    </div>
  );
};
export default CreateQuotationForm;
