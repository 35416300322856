import { Data } from "../../../../../../common";
import { useTranslation } from "../../../../../../common/customHooks";
import { InputSelect, TotalAmount } from "../../../../../../components";
import { ICreateQuotationTemplate } from "../../../../../../interfaces";

const CqPayments = ({ state }: ICreateQuotationTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const amountPriceData = [state.totalAmountWithoutTax, state.totalTax];
  return (
    <div className="w-100">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="row mb-2">
            <div className="col-md-6 col-12">
              <InputSelect
                isMulti={false}
                onChange={() => {}}
                options={[]}
                placeholder={tr("paymentMode")}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputSelect isMulti={false} onChange={() => {}} options={[]} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <InputSelect
                isMulti={false}
                onChange={() => {}}
                options={[]}
                placeholder={tr("paymentConditions")}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputSelect
                isMulti={false}
                onChange={() => {}}
                options={[]}
                placeholder={tr("paymentStatements")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="d-flex justify-end">
            <TotalAmount
              priceData={Data.getCreateQuotationPriceData(tr).map(
                (label, index) => ({
                  label,
                  price: amountPriceData[index],
                })
              )}
              totalPrice={state.totalAmountWithTax}
              totalPriceLabel={tr("totalTax")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CqPayments;
