import { Card } from "react-bootstrap";
import { IToggledCard, IToggledIcon } from "../../../interfaces";
import { useState } from "react";
import { useTranslation } from "../../../common/customHooks";

const ToggledCard = ({
  className,
  style,
  title,
  titleClassName,
  iconTitle,
  children,
  isBlocked,
  isAdd,
  onAdd,
  bodyStyle,
}: IToggledCard): JSX.Element => {
  const { tr } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(isBlocked || false);
  const handleToggled = () => !isBlocked && setIsShow(!isShow);
  return (
    <Card style={style} className={`${className || ""} ds-toggled-card`}>
      <Card.Header
        style={{ cursor: !isBlocked ? "pointer" : "" }}
        className={`ds-toggled-default-header ${
          isShow ? "ds-toggled-card-header ds-bg-secondary" : "ds-card-header"
        }`}
        onClick={handleToggled}
      >
        <div className="row">
          <div className="col-9 d-flex">
            <div>{iconTitle}</div>
            <span
              className={`${iconTitle && "ps-2"} ${
                titleClassName || ""
              } ds-toggled-card-title text-nowrap ${
                isBlocked && "ds-primary-color"
              }`}
            >
              {title}
            </span>
          </div>
          {isBlocked && isAdd && (
            <div className="col-2">
              <div
                className="d-flex justify-end ds-toggled-card-add-text"
                onClick={onAdd}
              >
                <i className="bi bi-plus-circle-fill" />
                <span className="ml-2">{tr("add")}</span>
              </div>
            </div>
          )}
          {!isBlocked && (
            <div className="col-3">
              <div className="d-flex justify-end">
                <ToggledIcon onToggled={handleToggled} isShow={isShow} />
              </div>
            </div>
          )}
        </div>
      </Card.Header>
      {isShow && <Card.Body style={bodyStyle}>{children}</Card.Body>}
    </Card>
  );
};
const ToggledIcon = ({ className, style, isShow, onToggled }: IToggledIcon) => {
  const commonClassName = `bi ${isShow ? "bi-chevron-up" : "bi-chevron-down"} ${
    className || ""
  } ds-primary-color`;
  return (
    <i
      onClick={() => {
        onToggled(!isShow);
      }}
      style={{ cursor: "pointer", fontSize: "18px", ...style }}
      className={commonClassName}
    />
  );
};
export { ToggledIcon };
export default ToggledCard;
