import { useState } from "react";
import Joyride, { Step } from "react-joyride";
import { IJoyrideContextProps, IPageProps } from "../../interfaces";
import { JoyrideContext } from "../../contexts";

const JoyrideProvider = ({ children }: IPageProps): JSX.Element => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [run, setRun] = useState(false);

  const handleStart = (newSteps: Step[], callback?: () => void) => {
    setSteps(newSteps);
    setRun(true);
    if (callback) {
      callback();
    }
  };

  const handleEnd = () => {
    setRun(false);
  };

  const joyrideContextValue: IJoyrideContextProps = {
    steps,
    run,
    start: handleStart,
    end: handleEnd,
  };

  return (
    <JoyrideContext.Provider value={joyrideContextValue}>
      {children}
      <Joyride
        showProgress={true}
        disableScrolling={true}
        continuous={true}
        disableOverlayClose={true}
        showSkipButton={true}
        steps={steps}
        run={run}
      />
    </JoyrideContext.Provider>
  );
};
export default JoyrideProvider;
