import { Link } from "react-router-dom";
import { logo } from "../../assets";
import { IPageProps } from "../../interfaces";

const TrackingLayout = ({ children }: IPageProps) => {
  return (
    <div className="w-100 h-100 ds-tracking-layout p-3">
      <Link to="/">
        <img alt="app-logo" className="ds-auth-img mb-4" src={logo} />
      </Link>
      <div className="px-3">{children}</div>
    </div>
  );
};
export default TrackingLayout;
