import React from "react";
import {
  ICheckbox,
  ICreateShipmentFromTo,
  ICreateShipmentService,
  ICreateSimulationTemplate,
} from "../../../interfaces";
import {
  CreateSimulationDocumentsList,
  CreateShipmentFromTo,
  CreateShipmentGeneralInformation,
  CreateShipmentPackage,
  CreateShipmentService,
} from "../../../templates/createSimulation/forms";
import useTranslation from "../useTranslation/useTranslation";
import {
  agentIcon,
  notifyIcon,
  pickupIcon,
  recipientIcon,
  senderIcon,
} from "../../../assets";
import useDrawer from "../useDrawer/useDrawer";
import { ECreateSenderType } from "../../../enums";
import { CreateSenderCustomer } from "../../../components";

const useCreateSimulation = () => {
  const { tr } = useTranslation();
  const drawer = useDrawer();

  const getTemplatePage = (
    payload: ICreateSimulationTemplate
  ): Array<React.ReactNode> => {
    const {
      fromToState,
      onChangeFromToState,
      serviceState,
      onChangeServiceState,
      generalInformationState,
      onChangeGeneralInformationState,
      onAddDangerousGoods,
      onAddPackage,
      packageState,
      onChangePackageState,
    } = payload;

    const handleCreateSendercustomer = () => {
      drawer.open({
        title: tr("addSenderCustomer"),
        children: <CreateSenderCustomer type={ECreateSenderType.full} />,
      });
    };
    return [
      <CreateShipmentGeneralInformation
        state={generalInformationState}
        onChangeState={onChangeGeneralInformationState}
        onAddSenderCustomer={handleCreateSendercustomer}
      />,
      <CreateShipmentFromTo
        state={fromToState}
        onChangeState={onChangeFromToState}
        onAddSenderCustomer={handleCreateSendercustomer}
      />,
      <CreateShipmentService
        state={serviceState}
        onChangeState={onChangeServiceState}
        onAddDangerousGoods={onAddDangerousGoods}
      />,
      <CreateShipmentPackage
        onAddPackage={onAddPackage}
        state={packageState}
        onChangeState={onChangePackageState}
      />,
      <CreateSimulationDocumentsList {...payload} />,
    ];
  };
  const getFromToOptions = (payload: ICreateShipmentFromTo) => {
    const { state, onAddSenderCustomer, onChangeState } = payload;
    return [
      {
        title: tr("senderDetails"),
        iconSrc: senderIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("senderAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("senderContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("senderInformation"),
        },
        conditionOptions: [
          {
            text: tr("isCustomer"),
            isChecked: state.sender.isCustomer,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                sender: { ...state.sender, isCustomer: checked },
              }),
          },
          {
            text: tr("isOriginPickup"),
            isChecked: state.sender.isOriginPickup,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                sender: { ...state.sender, isOriginPickup: checked },
              }),
          },
        ],
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("recipientDetail"),
        iconSrc: recipientIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("recipientAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("recipientContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("recipientInformation"),
        },
        conditionOptions: [
          {
            text: tr("isCustomer"),
            isChecked: state.recipient.isCustomer,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                recipient: { ...state.recipient, isCustomer: checked },
              }),
          },
          {
            text: tr("isDestinationDelivery"),
            isChecked: state.recipient.isDestinationDelivery,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                recipient: {
                  ...state.recipient,
                  isDestinationDelivery: checked,
                },
              }),
          },
        ],
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("firstNotify"),
        iconSrc: notifyIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("firstNotifyAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("firstNotifyContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("firstNotifyInformation"),
        },
        conditionOptions: [
          {
            text: tr("isCustomer"),
            isChecked: state.firstNotify.isCustomer,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                firstNotify: { ...state.firstNotify, isCustomer: checked },
              }),
          },
          {
            text: tr("isDestinationDelivery"),
            isChecked: state.firstNotify.isDestinationDelivery,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                firstNotify: {
                  ...state.firstNotify,
                  isDestinationDelivery: checked,
                },
              }),
          },
        ],
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("secondNotify"),
        iconSrc: notifyIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("secondNotifyAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("secondNotifyContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("secondNotifyInformation"),
        },
        conditionOptions: [
          {
            text: tr("isCustomer"),
            isChecked: state.secondNotify.isCustomer,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                secondNotify: { ...state.secondNotify, isCustomer: checked },
              }),
          },
          {
            text: tr("isDestinationDelivery"),
            isChecked: state.secondNotify.isDestinationDelivery,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                secondNotify: {
                  ...state.secondNotify,
                  isDestinationDelivery: checked,
                },
              }),
          },
        ],
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("originPickupDeposit"),
        iconSrc: pickupIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("pickUpAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("pickUpContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("pickUpInformation"),
        },
        conditionOptions: [
          {
            text: tr("isDeposit"),
            isChecked: state.originPickup.isDeposit,
            onChange: (checked: boolean) =>
              onChangeState({
                ...state,
                originPickup: {
                  ...state.originPickup,
                  isDeposit: checked,
                },
              }),
          },
        ],
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("destinationDelivery"),
        iconSrc: senderIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("deliveryAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("deliveryContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("deliveryInformation"),
        },
        onAddSenderCustomer: onAddSenderCustomer,
      },
      {
        title: tr("agent"),
        iconSrc: agentIcon,
        adress: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("agentAdress"),
        },
        contact: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("agentContact"),
        },
        information: {
          onChange: () => {},
          options: [],
          selectedValue: "",
          text: tr("agentInformation"),
        },
        onAddSenderCustomer: onAddSenderCustomer,
      },
    ];
  };

  const getFirstServiceOptions = (
    payload: ICreateShipmentService
  ): Array<ICheckbox> => {
    const { state, onChangeState } = payload;
    return [
      {
        text: tr("temperatureControlled"),
        isChecked: state?.isTemperatureControlled,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isTemperatureControlled: checked }),
      },
      {
        text: tr("insurance"),
        isChecked: state?.isInsurance,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isInsurance: checked }),
      },
      {
        text: tr("stackable"),
        isChecked: state?.isStackable,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isStackable: checked }),
      },
      {
        text: tr("signature"),
        isChecked: state?.isSignature,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isSignature: checked }),
      },
      {
        text: tr("dutyTaxPaid"),
        isChecked: state?.isDutyTaxPaid,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isDutyTaxPaid: checked }),
      },
    ];
  };
  const getSecondServiceOptions = (
    payload: ICreateShipmentService
  ): Array<ICheckbox> => {
    const { state, onChangeState } = payload;
    return [
      {
        text: tr("dangerousGoods"),
        isChecked: state?.isDangerousGoods,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isDangerousGoods: checked }),
      },
      {
        text: tr("ramp"),
        isChecked: state?.isRamp,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isRamp: checked }),
      },
      {
        text: tr("customClearance"),
        isChecked: state?.isCustomClearance,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isCustomClearance: checked }),
      },
      {
        text: tr("securityStatus"),
        isChecked: state?.isSecurityStatus,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isSecurityStatus: checked }),
      },
      {
        text: tr("prooveOfDelivery"),
        isChecked: state?.isProoveOfDelivery,
        onChange: (checked: boolean) =>
          onChangeState({ ...state, isProoveOfDelivery: checked }),
      },
    ];
  };
  const getAdditionalService = (
    payload: ICreateShipmentService
  ): {
    origin: Array<ICheckbox>;
    destination: Array<ICheckbox>;
  } => {
    const { state, onChangeState } = payload;
    return {
      origin: [
        {
          text: tr("collect"),
          isChecked: state?.isCollect,
          onChange: (checked: boolean) =>
            onChangeState({ ...state, isCollect: checked }),
        },
        {
          text: tr("exportCustoms"),
          isChecked: state?.isExportCustoms,
          onChange: (checked: boolean) =>
            onChangeState({ ...state, isExportCustoms: checked }),
        },
      ],
      destination: [
        {
          text: tr("lastMileDelivery"),
          isChecked: state?.isLastMileDelivery,
          onChange: (checked: boolean) =>
            onChangeState({ ...state, isLastMileDelivery: checked }),
        },
        {
          text: tr("importCustoms"),
          isChecked: state?.isImportCustoms,
          onChange: (checked: boolean) =>
            onChangeState({ ...state, isImportCustoms: checked }),
        },
        {
          text: tr("importHandeling"),
          isChecked: state?.isImportHandeling,
          onChange: (checked: boolean) =>
            onChangeState({ ...state, isImportHandeling: checked }),
        },
      ],
    };
  };
  return {
    getFirstServiceOptions,
    getFromToOptions,
    getSecondServiceOptions,
    getAdditionalService,
    getTemplatePage,
  };
};
export default useCreateSimulation;
