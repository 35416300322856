import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
import { IShipmentItem, ITitleResponse } from "../../../interfaces";
import { ShipmentStatus } from "../../atoms";
import { TitleResponse } from "../../molecules";

const ShipmentItem = ({
  style,
  className,
  isSelected,
  refrenceId,
  status,
  departure,
  arrival,
  departureDate,
  arrivalDate,
  price,
  onClick,
  isDisabled,
}: IShipmentItem) => {
  const { tr } = useTranslation();

  const CustomerTitleResponse = (props: ITitleResponse) => {
    return <TitleResponse responseClassName="ds-text-color" {...props} />;
  };

  return (
    <div
      style={style}
      className={`w-100 ds-shit-layout p-3 ${!isDisabled && "cursor-pointer"}
       ${className || ""} ${isSelected && "ds-shit-selected-layout"}`}
      onClick={onClick}
    >
      <div className="d-flex justify-between mb-2">
        <div
          style={{ fontSize: "14px" }}
          className={`ds-font-poppins font-bold ${
            isSelected ? "ds-primary-color" : "ds-black-color"
          }`}
        >
          {tr("refrenceId") + ": " + refrenceId}
        </div>
        <div className="flex">
          {status && <ShipmentStatus {...status} />}
          <div className="ms-2 ds-shit-price text-center ds-shit-price ds-font-poppins">
            {price}
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-6">
          <CustomerTitleResponse title={tr("departure")} response={departure} />
        </div>
        <div className="col-6">
          <CustomerTitleResponse title={tr("arrival")} response={arrival} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <CustomerTitleResponse
            title={tr("date")}
            response={
              departureDate !== null ? Data.getFormdatedDate(departureDate) : ""
            }
          />
        </div>
        <div className="col-6">
          <CustomerTitleResponse
            title={tr("date")}
            response={
              arrivalDate !== null ? Data.getFormdatedDate(arrivalDate) : ""
            }
          />
        </div>
      </div>
    </div>
  );
};
export default ShipmentItem;
