import { IRectangleCard } from "../../../interfaces";

const RectangleCard = ({
  className,
  bodyClassName,
  style,
  title,
  children,
}: IRectangleCard) => {
  return (
    <div
      style={style}
      className={`w-100 ds-box ds-font-poppins ${className || ""}`}
    >
      <div className="p-2">
        <div style={{ fontSize: "14px" }} className="ds-black-color font-bold">
          {title}
        </div>
      </div>
      <hr className="ds-bg-grey" />
      <div className={`p-2 ${bodyClassName || ""}`}>{children}</div>
    </div>
  );
};
export default RectangleCard;
