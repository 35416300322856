import { useState } from "react";
import { useTranslation } from "../../../common/customHooks";
import { INIT_CREATE_SENDER_CUSTOMER_STATE } from "../../../consts";
import {
  ICreateSenderCustomer,
  ICreateSenderCustomerState,
} from "../../../interfaces";
import { ECreateSenderType, EInputTypes } from "../../../enums";
import { TextInfo } from "../../atoms";
import { Input, InputPhoneNumber, InputSelect } from "../../molecules";

const CreateSenderCustomer = ({
  id,
  type,
}: ICreateSenderCustomer): JSX.Element => {
  const { tr } = useTranslation();
  const [state, setState] = useState<ICreateSenderCustomerState>(
    INIT_CREATE_SENDER_CUSTOMER_STATE
  );

  return (
    <div className="w-100 h-100">
      {(type === ECreateSenderType.full ||
        type === ECreateSenderType.contact) && (
        <div className="w-100">
          <TextInfo className="mb-3" title={tr("information")} />
          <div className="my-4 px-2">
            <div className="row my-4 ">
              <div className="col-md-6 col-12">
                <Input
                  label={tr("firstName")}
                  isObligatoryLabel
                  placeholder={tr("firstName")}
                  isValidate
                  value={state.firstName}
                  onChange={(value) =>
                    typeof value === "string" &&
                    setState({ ...state, firstName: value })
                  }
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={tr("lastName")}
                  isObligatoryLabel
                  isValidate
                  placeholder={tr("lastName")}
                  value={state.lastName}
                  onChange={(value) =>
                    typeof value === "string" &&
                    setState({ ...state, lastName: value })
                  }
                />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-6 col-12">
                <Input
                  label={tr("email")}
                  isObligatoryLabel
                  type={EInputTypes.email}
                  isValidate
                  placeholder={tr("email")}
                  value={state.email}
                  onChange={(value) =>
                    typeof value === "string" &&
                    setState({ ...state, email: value })
                  }
                />
              </div>
              <div className="col-md-6 col-12">
                <InputPhoneNumber
                  placeholder={tr("phone")}
                  isValidate
                  onChange={(value) => setState({ ...state, phone: value })}
                  value={state.phone}
                  label={tr("phone")}
                  isObligatoryLabel
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(type === ECreateSenderType.full ||
        type === ECreateSenderType.adress) && (
        <div className="w-100">
          <TextInfo title={tr("adress")} />
          <div className="my-3 px-2">
            <Input
              isValidate
              className="mb-3"
              label={tr("adress")}
              isObligatoryLabel
              placeholder={tr("adress")}
              onChange={(value) =>
                typeof value === "string" &&
                setState({ ...state, adress: value })
              }
              value={state.adress}
            />
            <div className="row mb-3">
              <div className="col-md-6 col-12">
                <InputSelect
                  isMulti={false}
                  value={state.countriesOptions.find(
                    (country) => country.value === state.country
                  )}
                  onChange={(event: any) =>
                    setState({ ...state, country: event?.value })
                  }
                  options={state.countriesOptions}
                  label={tr("country")}
                  isObligatoryLabel
                  placeholder={tr("country")}
                />
              </div>
              <div className="col-md-6 col-12">
                <InputSelect
                  isMulti={false}
                  value={state.citiesOptions.find(
                    (city) => city.value === state.city
                  )}
                  onChange={(event: any) =>
                    setState({ ...state, city: event?.value })
                  }
                  options={state.citiesOptions}
                  label={tr("city")}
                  isObligatoryLabel
                  placeholder={tr("city")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <Input
                  label={tr("zipCode")}
                  isObligatoryLabel
                  isValidate
                  placeholder={tr("zipCode")}
                  onChange={(value) =>
                    typeof value === "number" &&
                    setState({ ...state, zipCode: value })
                  }
                  value={state.zipCode}
                  type={EInputTypes.number}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CreateSenderCustomer;
