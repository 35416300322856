import { FileReader } from "..";
import { Data } from "../../../common";
import { IDocumentsList } from "../../../interfaces";
import { useDrawer, useTranslation } from "../../../common/customHooks";
import { EFileType } from "../../../enums";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";

const DocumentsList = ({ documents, isEnableActions }: IDocumentsList) => {
  const { tr } = useTranslation();
  const drawer = useDrawer();

  const data = useMemo(
    () =>
      documents.map((document) =>
        Data.getDocumentsListRowsData(document, (document) =>
          drawer.open({
            title: document.name,
            children: (
              <FileReader fileType={EFileType.img} path={document.filePath} />
            ),
          })
        )
      ),
    [documents]
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getDocumentListColumns(tr, isEnableActions),
    [tr, isEnableActions]
  );
  const table = useMaterialReactTable({
    columns,
    data,
  });

  return <MaterialReactTable table={table} />;
};
export default DocumentsList;
