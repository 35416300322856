import {
  Button,
  CreateDocument,
  DocumentsList,
  InputSearch,
} from "../../../../components";
import { ICreateSimulationTemplate } from "../../../../interfaces";
import { useDrawer, useTranslation } from "../../../../common/customHooks";

const CreateSimulationDocumentsList = ({
  documentListState,
  onChangeDocumentListStae,
}: ICreateSimulationTemplate): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const ButtonAddDocument = (): JSX.Element => {
    return (
      <div className="d-flex justify-end">
        <Button
          className="ds-create-package-button ds-add-document-button"
          icon={<i className="bi bi-plus-lg ds-white-color" />}
          onClick={() =>
            drawer.open({
              title: tr("addDocument"),
              children: <CreateDocument />,
            })
          }
          text={tr("addDocument")}
        />
      </div>
    );
  };
  return (
    <div className="w-100">
      <div className="row mb-3 container-fluid px-0">
        <div className="col-md-10 col-12">
          <div className="row">
            <div className="col-12 col-md-2 mb-2">
              <div className="row">
                <div className="col-6 col-md-12">
                  <div
                    style={{ lineHeight: "30px" }}
                    className="ds-card-page-title"
                  >
                    {tr("documentList")}
                  </div>
                </div>
                <div className="col-6 d-md-none d-xs-block">
                  <ButtonAddDocument />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <InputSearch
                onClear={() => {}}
                onSelect={() => {}}
                searchFields={[]}
              />
            </div>
          </div>
        </div>
        <div className="col-md-2 col-12 d-none d-md-block">
          <ButtonAddDocument />
        </div>
      </div>
      <div className="w-100">
        <DocumentsList
          documents={documentListState.documents}
          onPageChange={(selectedPageNumber) =>
            onChangeDocumentListStae({
              ...documentListState,
              pageNumber: selectedPageNumber,
            })
          }
          pageCount={documentListState.paginationLength}
          selectedPageNumber={documentListState.pageNumber}
          isPagination
        />
      </div>
    </div>
  );
};
export default CreateSimulationDocumentsList;
