import { useEffect, useState } from "react";
import { ShipmentAPI } from "../../../../apis";
import { useTranslation } from "../../../../common/customHooks";
import { ShowService, ToggledCard } from "../../../../components";
import { IServiceState } from "../../../../interfaces";
import { HttpStatusCode } from "axios";

const ShowShService = ({ id }: { id?: number }): JSX.Element => {
  const shipmentApi = new ShipmentAPI();
  const { tr } = useTranslation();
  const [state, setState] = useState<IServiceState>();

  useEffect(() => {
    if (id) {
      handleFetchApiData(id);
    }
  }, [id]);

  const handleFetchApiData = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentServices(id);

      if (response.status === HttpStatusCode.Ok) {
        const { services, services_addionnal } = response.data;
        const arrayOfServices =
          typeof services !== "undefined" ? Object.values(services) : [];
        setState({
          serviceOptions: arrayOfServices?.map(({ id, label }: any) => ({
            id,
            name: label,
          })),
          additionnelService: {
            destination: services_addionnal?.destination,
            origin: services_addionnal?.origin,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard title={tr("service")} isBlocked>
      <ShowService state={state} />
    </ToggledCard>
  );
};
export default ShowShService;
