import { EButtonType } from "../../../enums";
import { IButton } from "../../../interfaces";

const Button = (props: IButton): JSX.Element => {
  const { className, onClick, type, style, isDisabled } = props;
  return (
    <button
      className={`${className || ""} ${
        type === EButtonType.secondary
          ? "ds-secondary-button"
          : "ds-primary-button"
      } ds-button ${isDisabled && "ds-button-disabled"} `}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
    >
      <ButtonBody {...props} />
    </button>
  );
};

const ButtonBody = ({ isRtl, icon, text, textClassName }: IButton) => {
  return (
    <div className="d-flex justify-center px-1">
      {!isRtl ? (
        <>
          {icon}
          <span className={`${icon && "px-1"} ${textClassName || ""}`}>
            {text}
          </span>
        </>
      ) : (
        <>
          <span className={`${icon && "px-1"} ${textClassName || ""}`}>
            {text}
          </span>
          {icon}
        </>
      )}
    </div>
  );
};
export default Button;
