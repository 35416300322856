import {
  ICancelModel,
  IClaimModel,
  IPackageModel,
  IPrivateAppDataProvider,
  IQuotationInfoModel,
  IQuotationModel,
  IRequestGeneralInfoState,
  IRequestModel,
  IRfqFromToSenderInfo,
  IShipmentItemState,
  ITrackingDetailsReceiverState,
  ITrackingDetailsShippingHistory,
  ItrackingDetailsSenderState,
} from "../../interfaces";
import { TDocumentState } from "../../types";
import Data from "./Data";

class DataFetchAPI {
  static fetchPrivateAppData(data: any): IPrivateAppDataProvider {
    const { user, company } = data;
    return {
      email: user?.email,
      firstName: user?.firstname,
      lastName: user?.lastname,
      userName: user?.username,
      avatarSrc: user?.avatar,
      companyRegistrationNumber: company?.company_registration_number,
      companyCommercialName: company?.office_commercial_name,
      companyName: company?.office_legal_name,
      companyCapital: company?.office_capital,
      companyCountry: company?.office_country,
      companyEmail: company?.office_email,
      companyForm: company?.office_form,
      companyPhoneNumber: company?.office_phone,
      currency: company?.office_currency,
      companyAvatarSrc: company?.office_logo,
    };
  }
  static fetchPublicTrackingDetailsSender(
    data: any
  ): ItrackingDetailsSenderState {
    const {
      origin_country,
      origin_city,
      contact_address,
      contact_name,
      shipping_quantity,
      total_weight,
      shipping_time,
      shipping_date,
    } = data;
    return {
      contactAdress: contact_address,
      contactName: contact_name,
      originCity: origin_city,
      originCountry: origin_country,
      shippingDate: Data.getFormdatedDate(new Date(shipping_date)),
      shippingQuantity: shipping_quantity,
      totalWeight: total_weight,
      shippingTime: Data.getTimeStringFromDate(new Date(shipping_time)),
    };
  }
  static fetchPublicTrackingDetailsReceiver(
    data: any
  ): ITrackingDetailsReceiverState {
    const {
      origin_country,
      origin_city,
      shipping_date,
      estimated_delivery_date,
      contact_name,
      contact_address,
    } = data;
    return {
      contactAdress: contact_address,
      contactName: contact_name,
      estimatedDeliveryDate: estimated_delivery_date,
      shippingTime: new Date(shipping_date),
      destinationCity: origin_city,
      destinationCountry: origin_country,
    };
  }
  static fetchPublicTrackingShippingHistory(
    data: any
  ): Array<ITrackingDetailsShippingHistory> {
    const { trackings } = data;
    return (trackings as Array<any>).map(({ date, status }) => ({
      date: new Date(date),
      shippingStatus: status,
      time: new Date(date),
    }));
  }

  static fetchShipmentModel(data: any): IShipmentItemState {
    const {
      id,
      order_prefix,
      order_number,
      pickup_date,
      delivery_date,
      cfg_gen_status_order,
      departure,
      arrival,
      add_order_package,
    } = data;
    const orderPackageList = add_order_package as Array<any>;
    return {
      id,
      refrenceId: `#${order_prefix}${order_number}`,
      arrival: arrival?.address_country + ", " + arrival?.address_city,
      arrivalDate: new Date(delivery_date),
      departure: departure?.address_country + ", " + departure?.address_city,
      departureDate: new Date(pickup_date),
      isCanceled: cfg_gen_status_order?.id === 19,
      status: {
        id: cfg_gen_status_order?.id,
        backgroundColor: cfg_gen_status_order?.background_color,
        color: cfg_gen_status_order?.text_color,
        text: cfg_gen_status_order?.status_name,
      },
      packageDetails: `${orderPackageList.length} container`,
      volume: orderPackageList[0]?.order_package_total_volume,
      weight: orderPackageList[0]?.order_package_total_weight,
      price: "Price ??",
    };
  }

  static fetchDocumentModel(data: any): TDocumentState {
    const { file_name, url, file_type, issue_date, updated_at, created_at } =
      data;
    return {
      filePath: url,
      issueDate: new Date(issue_date),
      name: file_name,
      type: file_type,
      uploadDate: new Date(updated_at || created_at),
    };
  }

  static fetchClaimModel(data: any): IClaimModel {
    const {
      id,
      claim_description,
      claim_no,
      claim_date_hour_request,
      claim_date_hour_incident,
      approved_at,
      claim_amount_approved,
      claim_proof,
      claim_amount_requested,
      claim_raison,
      claim_status,
    } = data;
    return {
      id,
      raison: claim_raison,
      content: claim_description,
      number: claim_no,
      fileUrl: claim_proof,
      date: new Date(claim_date_hour_request),
      incidentDate: new Date(claim_date_hour_incident),
      approvedDate: approved_at && new Date(approved_at),
      approvedAmount: claim_amount_approved,
      requestedAmount: claim_amount_requested,
      status: {
        backgroundColor: claim_status?.background_color,
        color: claim_status?.text_color,
        text: claim_status?.claim_status_name,
      },
    };
  }
  static fetchQuotationItemModel(data: any): IQuotationModel {
    const {
      id,
      customer,
      request_number,
      quotation_number,
      carrier_currency,
      carrier,
      total_amount_quotation,
      cfg_gen_status_quotation,
      quotation_date,
      request_date,
    } = data;
    return {
      id,
      carrier: carrier?.office_name,
      customer: customer?.office_name,
      price: `${total_amount_quotation} ${carrier_currency}`,
      quotationDate: new Date(quotation_date),
      quotationNumber: quotation_number,
      requestDate: request_date ? new Date(request_date) : undefined,
      requestNumber: request_number,
      status: {
        backgroundColor: cfg_gen_status_quotation?.background_color,
        color: cfg_gen_status_quotation?.text_color,
        text: cfg_gen_status_quotation?.status_name,
      },
    };
  }
  static fetchCancelModel(data: any): ICancelModel {
    const {
      id,
      cancel_prefix,
      cancel_description,
      cancel_raison_name,
      cancel_proof,
      cancel_no,
      cancel_date_hour_request,
      cancel_amount_requested,
    } = data;
    return {
      id,
      name: `#${cancel_prefix}${cancel_no} - ${cancel_raison_name}`,
      content: cancel_description,
      date: new Date(cancel_date_hour_request),
      fileUrl: cancel_proof,
      requestedAmount: cancel_amount_requested,
    };
  }

  static fetchRequestItemModel(data: any): IRequestModel {
    const {
      id,
      request_number,
      simulation_number,
      cfg_gen_status_request,
      created_at,
      carrier,
    } = data;
    return {
      id,
      requestNumber: request_number,
      simulationNumber: simulation_number,
      carrier: carrier?.office_legal_name,
      status: {
        backgroundColor: cfg_gen_status_request?.background_color,
        color: cfg_gen_status_request?.text_color,
        text: cfg_gen_status_request?.status_name,
      },
      date: new Date(created_at),
    };
  }

  static fetchRequestGeneralInfoState(data: any): IRequestGeneralInfoState {
    const {
      reference_number,
      transport_mode,
      shipment_type,
      incoterm,
      location,
      instruction,
    } = data;
    return {
      referenceNumber: reference_number,
      transportMode: transport_mode,
      shipmentType: shipment_type,
      intercom: incoterm,
      location,
      instruction,
    };
  }

  static fetchDataFromToModel(data: any): IRfqFromToSenderInfo {
    const { firstname, lastname, address, mail, phone } = data;
    return {
      firstName: firstname,
      lastName: lastname,
      adress: address,
      email: mail,
      phoneNumber: phone,
    };
  }

  static fetchDataQuotationInfoModel(data: any): IQuotationInfoModel {
    const {
      id,
      status,
      quotation_number,
      head_office,
      email,
      phone,
      nit,
      vat_code,
      iban,
      bic,
      quotation_date,
      quotation_validity_period,
      billto_company,
      billto_address,
      billto_vatcode,
      client_code,
      sender,
      receiver,
      pickup,
      delivery,
      total,
      total_vat,
      total_amount_payable,
    } = data;
    return {
      id,
      quotationNumber: quotation_number,
      status: {
        id: status?.id,
        backgroundColor: status?.background_color_customer,
        color: status?.text_color_customer,
        text: status?.status_name_customer,
      },
      generalInfo: {
        headOffice: head_office,
        vatCode: vat_code,
        email,
        phone,
        nit,
      },
      bankDetails: {
        bic,
        iban,
      },
      quotationDate: {
        quotationDate: new Date(quotation_date),
        quotationValidityPeriod: quotation_validity_period,
      },
      billTo: {
        company: billto_company,
        address: billto_address,
        vatCode: billto_vatcode,
        clientCode: client_code,
        sender,
        receiver,
        pickup,
        delivery,
      },
      amount: {
        total,
        totalVat: total_vat,
        totalAmoutPayable: total_amount_payable,
      },
    };
  }

  static fetchShipmentPackagesData(data: any): Array<IPackageModel> {
    return (data as Array<any>).map((item) => ({
      id: item?.id,
      name: item.order_package_name,
      type: item.packaging?.packaging_name,
    }));
  }

  static fetchRequestPackagesData(data: any): Array<IPackageModel> {
    return (data as Array<any>).map((item) => ({
      id: item?.id,
      name: item.sim_package_name,
      type: item.cfg_gen_packaging?.packaging_name,
    }));
  }
}
export { DataFetchAPI };
