const PdfIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <g clipPath="url(#clip0_165_1460)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9937 0C15.5268 0 16.0504 0.216464 16.4278 0.593939L20.3716 4.53777C20.7545 4.92065 20.9656 5.4307 20.9656 5.97188V23H2.02441V0H14.9937ZM14.2009 1.35294H3.37734V21.6471H19.6126V6.76471H14.2009V1.35293L14.2009 1.35294ZM15.5538 1.633V5.41177H19.3326L15.5538 1.633Z"
          fill="#849BB3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7874 8.11792C11.1081 8.11792 11.3908 8.26946 11.6019 8.57115C12.1038 9.27874 12.0687 10.0323 11.8833 11.047C11.8197 11.3961 11.8847 11.7492 12.0781 12.0428C12.3514 12.4595 12.7451 13.0332 13.1321 13.5189C13.4162 13.8774 13.8532 14.0682 14.2956 14.0086C14.5797 13.9694 14.922 13.9369 15.2738 13.9369C16.8432 13.9369 16.7255 14.497 16.8675 15.0112C16.946 15.3223 16.9068 15.5943 16.7458 15.7959C16.5455 16.0678 16.1329 16.2085 15.5295 16.2085C14.8611 16.2085 14.6406 16.0949 14.0588 15.9095C13.7301 15.804 13.3891 15.7823 13.0563 15.8703C12.392 16.0434 11.2961 16.3073 10.3815 16.4331C9.89313 16.5007 9.44938 16.7524 9.15443 17.1623C8.49013 18.0823 7.87455 18.9414 6.89907 18.9414C6.64743 18.9414 6.41607 18.8508 6.22532 18.6695C5.9439 18.3881 5.82349 18.0756 5.88302 17.713C6.04807 16.5995 7.41726 15.9785 8.23173 15.5253C8.42384 15.4184 8.57538 15.2547 8.67007 15.0517L9.96215 12.2579C10.0541 12.0617 10.0825 11.8439 10.0487 11.6288C9.89855 10.6817 9.67666 9.08933 10.2733 8.36957C10.4045 8.20857 10.5859 8.11792 10.7874 8.11792ZM11.0337 12.1903C10.5155 13.7069 9.59955 15.3562 9.47643 15.5483C10.5818 15.0287 12.7817 14.7663 12.7817 14.7663C11.675 13.7002 11.0337 12.1903 11.0337 12.1903Z"
          fill="#849BB3"
        />
      </g>
      <defs>
        <clipPath id="clip0_165_1460">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PdfIcon;
