import { useTranslation } from "../../../common/customHooks";
import { ISearchFilter } from "../../../interfaces";
import { InputSearch } from "../../atoms";
import Button from "../button/Button";

const SearchFilter = ({
  className,
  style,
  searchFields,
  onChangeSearchedValue,
  onSelect,
  onFilter,
}: ISearchFilter): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className={`${className} ds-sl-filter-box py-2 px-1`}>
      <div className="row px-1">
        <div className="col-9">
          <InputSearch
            style={{ height: "35px" }}
            onClear={() => onChangeSearchedValue("")}
            onChange={onChangeSearchedValue}
            onSelect={onSelect}
            searchFields={searchFields}
          />
        </div>
        <div style={{ lineHeight: "36px" }} className="col-3">
          <Button
            style={{
              borderRadius: "40px",
              height: "35px",
              minWidth: "100%",
            }}
            icon={<i className="bi bi-funnel-fill ds-white-color" />}
            text={tr("filter")}
            onClick={onFilter}
          />
        </div>
      </div>
    </div>
  );
};
export default SearchFilter;
