import { useTranslation } from "../../../../common/customHooks";
import { Button, Input, PageTitle } from "../../../../components";
import { EInputTypes } from "../../../../enums";
import { ISecurityPassword } from "../../../../interfaces";

const SecurityPassword = ({
  state,
  onChangeState,
  onSubmit,
}: ISecurityPassword): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 h-100">
      <PageTitle
        titleStyle={{ fontSize: "16px" }}
        className="mb-4"
        title={tr("changePassword")}
      />
      <div className="row mb-1">
        <div className="col-md-3 col-12">
          <Input
            className="mb-1"
            label={tr("currentPassword")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, currentPassword: value })
            }
            value={state.currentPassword}
            type={EInputTypes.password}
          />
          <Input
            className="mb-1"
            label={tr("newPassword")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, newPassword: value })
            }
            value={state.newPassword}
            type={EInputTypes.password}
            isValidate
          />
          <Input
            label={tr("confirmPassword")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "string" &&
              onChangeState({ ...state, confirmedPassword: value })
            }
            value={state.confirmedPassword}
            type={EInputTypes.password}
            isValidate
            validatonOption={{
              confirmedPassword: { password: state.newPassword },
            }}
          />
        </div>
      </div>
      <div className="ds-manage-profile-security-conditions my-3">
        {tr("securityPasswordConditions")}
      </div>
      <Button className="mb-3" onClick={onSubmit} text={tr("saveChange")} />
    </div>
  );
};

export default SecurityPassword;
