import { DashboardTemplate } from "../../templates";
import {
  useJoyride,
  usePrivateAppData,
  useTranslation,
} from "../../common/customHooks";
import { Data } from "../../common";
import { useEffect } from "react";

const Dashboard = (): JSX.Element => {
  const { tr } = useTranslation();
  const joyride = useJoyride();
  const context = usePrivateAppData();

  useEffect(() => {
    joyride.start(
      Data.getDashboardJoyrideData(tr),
      async () => await disabledFirstDashboardVue()
    );

    return () => {
      joyride.end();
    };
  }, []);

  const disabledFirstDashboardVue = async () => {};

  return (
    <DashboardTemplate
      userName={`${context?.state?.firstName} ${context?.state?.lastName}`}
    />
  );
};
export default Dashboard;
