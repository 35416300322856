import { Link } from "react-router-dom";
import { IPrivacyPolicy } from "../../../interfaces";
import { useTranslation } from "../../../common/customHooks";

const PrivacyPolicy = ({
  className,
  href,
  style,
}: IPrivacyPolicy): JSX.Element => {
  const { tr } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <div style={style} className={`${className || ""} w-100 h-100`}>
      <div className="d-flex justify-center">
        <span className="ds-privacy-policy-text">
          © {year} Colisnet, {tr("allRightsReserved")}.
          <Link className="ds-privacy-policy-link ps-1" to={href}>
            {tr("privacyPolicy")}
          </Link>
        </span>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
