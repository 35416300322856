import { useEffect, useState } from "react";
import { IRegisterState, IRegister } from "../../interfaces";
import { RegisterTemplate } from "../../templates";
import { INIT_REGISTER_STATE } from "../../consts";

const Register = ({ type }: IRegister): JSX.Element => {
  const [registerState, setRegisterState] = useState<IRegisterState>({
    ...INIT_REGISTER_STATE,
    registerType: type,
  });

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    try {
      // api fetch data
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = async () => {};
  return (
    <RegisterTemplate
      state={registerState}
      onSubmit={handleSubmit}
      onChangeState={setRegisterState}
    />
  );
};
export default Register;
