import { IButton } from "../../../interfaces";

const AuthButton = ({
  className,
  style,
  text,
  onClick,
  isDisabled,
}: IButton): JSX.Element => {
  return (
    <button
      style={style}
      className={`${className || ""} ds-auth-button ${
        isDisabled && "ds-button-disabled"
      } w-100 `}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span className="ds-auth-button-text">{text}</span>
    </button>
  );
};
export default AuthButton;
