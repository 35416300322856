import { useEffect, useState } from "react";
import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
import { IUploadFile } from "../../../interfaces";
import { Label } from "../../atoms";
import { FileUploader } from "react-drag-drop-files";
import UploadedFileItem from "../UploadedFileItem/UploadedFileItem";

const UploadFile = ({
  className,
  style,
  files,
  labelStyle,
  title,
  label,
  isObligatoryLabel,
  isMulti,
  onUpload,
}: IUploadFile): JSX.Element => {
  const { tr } = useTranslation();
  const [commonFiles, setCommonFiles] = useState<Array<File>>(files);
  useEffect(() => {
    onUpload(commonFiles);
  }, [commonFiles]);  

  return (
    <div style={style} className={`${className || ""} w-100 h-100`}>
      {!isMulti && commonFiles.length > 0 ? undefined : (
        <div className={files.length >0 ?"mb-2":undefined}>
          {label && (
            <Label
              style={labelStyle}
              className="mb-2"
              text={label}
              isObligatory={isObligatoryLabel === true}
            />
          )}
          <FileUploader
            multiple={isMulti === true}
            handleChange={(files: any) => {
              if (isMulti) {                
                setCommonFiles(commonFiles.concat(Array.from(files)));
              } else {
                setCommonFiles([files]);
              }
            }}
            name="file"
            types={Data.getFileUploaderType()}
          >
            <div className="ds-upload-file">
              <div className="d-flex justify-content-center py-4">
                <div>
                  <div className="d-flex justify-content-center">
                    <i className="bi bi-upload ds-black-color" />
                  </div>
                  <div>
                    <span className="ds-upload-file-title">
                      {title || tr("uploadFile")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </FileUploader>
        </div>
      )}
      {commonFiles.map((file, index) => (
        <UploadedFileItem
        className="mb-2"
          key={index}
          file={file}
          onDelete={() => {
            const updatedFiles = [...commonFiles];
            updatedFiles.splice(index, 1);
            setCommonFiles(updatedFiles);
          }}
        />
      ))}
    </div>
  );
};
export default UploadFile;
