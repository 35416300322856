import { EFileType } from "../../../enums";
import { IFileUploadedItem } from "../../../interfaces";
import { DeleteIcon, ImgIcon, PdfIcon, WordIcon } from "../../icons";

const UploadedFileItem = ({
  className,
  style,
  file,
  onDelete,
}: IFileUploadedItem): JSX.Element => {
  const { type, name } = file;
  const fileType = type.split("/")[0];
  return (
    <div
      style={style}
      className={`${className || ""} ds-file-uploaded-item w-100 py-4`}
    >
      <div className="row px-2">
      <div className="col-1">
        <div className="d-flex justify-center">
          {fileType === EFileType.word ? (
            <WordIcon />
          ) : fileType === EFileType.img ? (
            <ImgIcon />
          ) : fileType === EFileType.pdf ? (
            <PdfIcon />
          ) : null}
        </div>
      </div>
      <div className="col-11">
        <div className="d-flex justify-between">
          <div className="ds-file-uploaded-item-title">{name}</div>
          <div className="me-3">
            <DeleteIcon onDelete={onDelete} />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
export default UploadedFileItem;
