import { useState } from "react";
import { Drawer } from "../../components";
import { DrawerContext } from "../../contexts";
import {
  IDrawerContextProps,
  IDrawerProvider,
  IOpenDrawerProvider,
  IPageProps,
} from "../../interfaces";
import { INIT_DRAWER_PROVIDER_State } from "../../consts";

const DrawerProvider = ({ children }: IPageProps) => {
  const [state, setState] = useState<IDrawerProvider>(
    INIT_DRAWER_PROVIDER_State
  );

  const handleOpen = (payload: IOpenDrawerProvider) =>
    setState({ ...payload, isOpen: true });

  const handleClose = () => setState(INIT_DRAWER_PROVIDER_State);

  const props: IDrawerContextProps = {
    open: handleOpen,
    close: handleClose,
  };

  return (
    <DrawerContext.Provider value={props}>
        <Drawer
          headerTitle={state.title}
          isEnableOverlay
          isOpen={state.isOpen}
          onClose={handleClose}
          {...state.options}
        >
          {state.children}
        </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};
export default DrawerProvider;
