import { useEffect, useState } from "react";
import { useDrawer, useTranslation } from "../../../../common/customHooks";
import {
  Button,
  ClaimCancelItem,
  InfinityScroll,
  ManageClaimCancel,
  ToggledCard,
} from "../../../../components";
import { ShipmentAPI } from "../../../../apis";
import { IClaimsListState, IManageClaimCancelState } from "../../../../interfaces";
import { HttpStatusCode } from "axios";
import { INIT_CLAIMS_LIST_STATE } from "../../../../consts";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import { toast } from "react-toastify";

const ShowShClaim = ({
  id,
  isCanceledShipment,
  onSuccessAddedClaim,
}: {
  id?: number;
  isCanceledShipment: boolean;
  onSuccessAddedClaim: () => void;
}): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const shipmentApi = new ShipmentAPI();
  const [state, setState] = useState<IClaimsListState>(INIT_CLAIMS_LIST_STATE);

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [id, state.pageNumber]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await shipmentApi.getShipmentClaimsPagination({
        ...state,
        id,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data, last_page } = response.data?.data;
        setState({
          ...state,
          claims: state.claims.concat(
            (data as Array<any>)?.map((data) =>
              DataFetchAPI.fetchClaimModel(data)
            )
          ),
          paginationLength: last_page,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateClaim = async (state: IManageClaimCancelState) => {
    try {
      await shipmentApi.createShipmentClaim({
        ...state,
        id: id!,
      });
      drawer.close();
      toast.success(tr("claimAddedSuccessfully"));
      onSuccessAddedClaim();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard title={tr("claim")} isBlocked>
      <div className="d-flex justify-end mb-2">
        {!isCanceledShipment && (
          <Button
            style={{ borderRadius: "4px", width: "100px", height: "28px" }}
            icon={<i className="bi bi-plus ds-white-color" />}
            text={tr("newClaim")}
            onClick={() =>
              drawer.open({
                title: tr("addClaim"),
                children: (
                  <ManageClaimCancel
                    type="claim"
                    onSubmit={handleCreateClaim}
                  />
                ),
              })
            }
          />
        )}
      </div>
      <InfinityScroll
        onNext={() => setState({ ...state, pageNumber: state.pageNumber + 1 })}
        {...state}
      >
        {state.claims.length > 0 &&
          state.claims.map((claim) => (
            <ClaimCancelItem
              type="claim"
              className="mb-2"
              name={`#${claim.number} - ${claim.raison}`}
              {...claim}
            />
          ))}
      </InfinityScroll>
    </ToggledCard>
  );
};
export default ShowShClaim;
