import { useTranslation } from "../../../common/customHooks";
import { ICreateTemperature } from "../../../interfaces";
import { Checkbox, Input, InputSelect } from "../../molecules";

const CreateTemperature = ({
  isActiveCooling,
  onChangeActiveCooling,
  minTemp,
  maxTemp,
  onChangeMaxTemp,
  onChangeMinTemp,
  temperatureOptions,
  onChangeTemp,
}: ICreateTemperature): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100">
      <div className="row mb-2">
        <div className="col-12 col-md-4">
          <InputSelect
            label={tr("unit")}
            isObligatoryLabel
            isMulti={false}
            onChange={onChangeTemp}
            options={temperatureOptions}
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            label={tr("minTemp")}
            isObligatoryLabel
            value={minTemp}
            onChange={(value) =>
              typeof value === "number" && onChangeMinTemp(value)
            }
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            label={tr("maxTemp")}
            isObligatoryLabel
            value={maxTemp}
            onChange={(value) =>
              typeof value === "number" && onChangeMaxTemp(value)
            }
          />
        </div>
      </div>
      <div className="row">
        <Checkbox
          isChecked={isActiveCooling}
          onChange={onChangeActiveCooling}
          text={tr("activeCooling")}
        />
      </div>
    </div>
  );
};
export default CreateTemperature;
