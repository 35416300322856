import { ITextArea } from "../../../interfaces";
import { useState } from "react";
import { Label } from "../../atoms";
import { EInputTypes } from "../../../enums";
import ReactQuill from "react-quill";
import { useTranslation } from "../../../common/customHooks";
import { Validation } from "../../../common";

const TextArea = ({
  label,
  isObligatoryLabel,
  style,
  labelStyle,
  className,
  value,
  onChange,
  isQuill,
  placeholder,
  isValidate,
  errorValidateText,
}: ITextArea): JSX.Element => {
  const { tr } = useTranslation();
  const [counter, setCounter] = useState<number>(0);
  const { isValide, textError } = Validation.inputValidation({
    tr,
    value,
    type: EInputTypes.text,
  });
  const isValidationForm = isValidate && counter > 0;
  const validationClassName =
    isValidationForm &&
    `${isValide ? "ds-input-valide-border" : "ds-input-danger-border"}`;
    
  return (
    <div style={style} className={`${className || ""} w-100`}>
      {label && (
        <Label
          className="mb-2"
          style={labelStyle}
          text={label}
          isObligatory={isObligatoryLabel === true}
        />
      )}
      {isQuill ? (
        <ReactQuill
          className={`ds-text-area-quill ${validationClassName}`}
          placeholder={placeholder}
          value={value}
          onChange={(value) => {
            counter === 0 && setCounter(counter + 1);
            onChange(value);
          }}
        />
      ) : (
        <textarea
          className={`ds-text-area w-100 ${validationClassName}`}
          value={value}
          onChange={(e) => {
            counter === 0 && setCounter(counter + 1);
            onChange(e.target.value);
          }}
          rows={4}
          cols={50}
          placeholder={placeholder}
        />
      )}
      {isValidationForm && !isValide && (
        <div className="ds-input-error-text w-100 ps-1">
          {errorValidateText || textError}
        </div>
      )}
    </div>
  );
};
export default TextArea;
