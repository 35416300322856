import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../interfaces";
import { ETheme } from "../../../enums";
import { setTheme } from "../../../redux";

const useTheme = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state: IRootState) => state.setting.theme);
  const isDarkMode = theme === ETheme.dark;

  const getStoredTheme = (): ETheme => {
    return theme;
  };
  const changeTheme = () => {
    dispatch(setTheme(theme === ETheme.light ? ETheme.dark : ETheme.light));
  };
  
  return { getStoredTheme, changeTheme, isDarkMode };
};
export default useTheme;
