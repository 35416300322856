import { Data } from "../../../common";
import { useTranslation } from "../../../common/customHooks";
import { EDeviceType } from "../../../enums";
import { IDeviceActivity } from "../../../interfaces";

const DeviceActivity = ({
  className,
  style,
  ipAdress,
  lastActivity,
  localistation,
  isMyDevice,
  name,
  type,
  onDelete,
}: IDeviceActivity): JSX.Element => {
  const { tr } = useTranslation();
  const RenderIcon = () => {
    let iconClassName = "";
    switch (type) {
      case EDeviceType.pc:
        iconClassName = "bi bi-pc-display";
        break;
      case EDeviceType.phone:
        iconClassName = "bi bi-phone";
        break;
      case EDeviceType.tablette:
        iconClassName = "bi bi-tablet";
        break;
    }
    return <i style={{ fontSize: "25px" }} className={iconClassName} />;
  };
  return (
    <div
      style={style}
      className={`${className || ""} w-100 ds-device-card py-4`}
    >
      <div className="row">
        <div className="col-1 d-flex justify-center">
          <RenderIcon />
        </div>
        <div className="col-8">
          <div className="d-flex">
            <div className="ds-device-name-text">{name}</div>
            {isMyDevice && (
              <div className="ps-2 ds-my-device-text-check">
                {tr("thisDevice").toUpperCase()}
              </div>
            )}
          </div>
          <div className="ds-device-info-text">
            {ipAdress}, {Data.getDeviceLastConnexionText(lastActivity, tr)} •{" "}
            {localistation}
          </div>
        </div>
        <div className="col-3">
          <div
            className="d-flex justify-end cursor-pointer ds-device-signout-text pe-4 py-2"
            onClick={onDelete}
          >
            {tr("signOut")}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeviceActivity;
