import { PageTitle } from "../../components";
import { IPageLayout } from "../../interfaces";

const PageLayout = ({ className, children, style, ...props }: IPageLayout) => {
  return (
    <div style={style} className={`${className || ""} w-100 h-100`}>
      <PageTitle className="mb-1" {...props} />
      {children}
    </div>
  );
};
export default PageLayout;
