import { useTranslation } from "../../../common/customHooks";
import { IFromToSenderCustomer } from "../../../interfaces";
import { Checkbox, InputSelect, TextLink } from "../../molecules";

const FromToSenderCustomer = ({
  className,
  style,
  conditionOptions,
  onAddSenderCustomer,
  adress,
  contact,
  information,
}: IFromToSenderCustomer): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className={`w-100 h-100 ${className || ""}`}>
      {conditionOptions && (
        <div className="row mb-3">
          {conditionOptions.map((condition, index) => (
            <div
              key={index}
              className={`col-12 col-md-6 col-lg-2 ${
                index !== 0 && "ds-md-ms-2"
              }`}
            >
              <Checkbox
                isChecked={condition.isChecked}
                onChange={condition.onChange}
                text={condition.text}
              />
            </div>
          ))}
        </div>
      )}
      <div className="row mb-3">
        <div className="col-12 col-md-4 col-lg-4">
          <InputSelect
            label={information.text}
            isObligatoryLabel
            isMulti={false}
            onChange={information.onChange}
            options={information.options}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-4">
          <InputSelect
            label={adress.text}
            isObligatoryLabel
            isMulti={false}
            onChange={adress.onChange}
            options={adress.options}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-4">
          <InputSelect
            label={contact.text}
            isObligatoryLabel
            isMulti={false}
            onChange={contact.onChange}
            options={contact.options}
          />
        </div>
      </div>
      <div className="d-flex justify-start">
        <TextLink
          isLink={false}
          icon={<i className="bi bi-plus-circle-fill ds-primary-color pb-2" />}
          text={tr("addSenderCustomer")}
          onClick={onAddSenderCustomer}
        />
      </div>
    </div>
  );
};
export default FromToSenderCustomer;
