import { useContext } from "react";
import { LangContext } from "../../../contexts";
const useTranslation = () => {
  const lang = useContext(LangContext);
  const translate = (value: string): string => {
    return lang[value] ? lang[value] : value;
  };
  return { tr: translate, lang };
};
export default useTranslation;
