import { useState } from "react";
import { ISimulationListState } from "../../interfaces";
import { SimulationListTemplate } from "../../templates";
import { INIT_SIMULATION_LIST_STATE } from "../../consts";
import { useNavigate } from "react-router-dom";

const SimulationList = (): JSX.Element => {
  const navigate = useNavigate();
  const [simulationListState, setSimulationListState] =
    useState<ISimulationListState>(INIT_SIMULATION_LIST_STATE);
  return (
    <SimulationListTemplate
      state={simulationListState}
      onChangeState={setSimulationListState}
      onCreateSimulation={() => navigate("/create-simulation")}
    />
  );
};
export default SimulationList;
