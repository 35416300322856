import { FullScreenContext } from "../../contexts";
import { IFullScreenProps, IPageProps } from "../../interfaces";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const FullScreenProvider = ({ children }: IPageProps) => {
  const fullScreen = useFullScreenHandle();

  const props: IFullScreenProps = {
    enter: () => fullScreen.enter(),
    isActive: fullScreen.active,
    exit: () => fullScreen.exit(),
  };

  return (
    <FullScreenContext.Provider value={props}>
      <FullScreen handle={fullScreen}>{children}</FullScreen>
    </FullScreenContext.Provider>
  );
};
export default FullScreenProvider;
