import { ICancleIcon } from "../../../interfaces";

const CancleIcon = ({ className, onCancle }: ICancleIcon): JSX.Element => {
  return (
    <svg
      className={className}
      onClick={onCancle}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M17.409 15.9991L23.7044 9.71303C23.8926 9.52485 23.9983 9.26961 23.9983 9.00347C23.9983 8.73733 23.8926 8.4821 23.7044 8.29391C23.5162 8.10572 23.261 8 22.9949 8C22.7288 8 22.4736 8.10572 22.2854 8.29391L16 14.59L9.71456 8.29391C9.52639 8.10572 9.27118 8 9.00507 8C8.73896 8 8.48375 8.10572 8.29558 8.29391C8.10742 8.4821 8.00171 8.73733 8.00171 9.00347C8.00171 9.26961 8.10742 9.52485 8.29558 9.71303L14.591 15.9991L8.29558 22.2853C8.20192 22.3782 8.12758 22.4887 8.07685 22.6105C8.02612 22.7323 8 22.8629 8 22.9948C8 23.1268 8.02612 23.2574 8.07685 23.3792C8.12758 23.5009 8.20192 23.6115 8.29558 23.7044C8.38848 23.7981 8.499 23.8724 8.62077 23.9231C8.74254 23.9739 8.87315 24 9.00507 24C9.13699 24 9.2676 23.9739 9.38937 23.9231C9.51114 23.8724 9.62166 23.7981 9.71456 23.7044L16 17.4083L22.2854 23.7044C22.3783 23.7981 22.4889 23.8724 22.6106 23.9231C22.7324 23.9739 22.863 24 22.9949 24C23.1268 24 23.2575 23.9739 23.3792 23.9231C23.501 23.8724 23.6115 23.7981 23.7044 23.7044C23.7981 23.6115 23.8724 23.5009 23.9232 23.3792C23.9739 23.2574 24 23.1268 24 22.9948C24 22.8629 23.9739 22.7323 23.9232 22.6105C23.8724 22.4887 23.7981 22.3782 23.7044 22.2853L17.409 15.9991Z"
        fill="#849BB3"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#849BB3" />
    </svg>
  );
};
export default CancleIcon;
