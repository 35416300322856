import Avatar from "react-avatar";
import { ISelectLanguages } from "../../../interfaces";
import { useLanguages } from "../../../common/customHooks";

const SelectLanguages = ({ options, className, style }: ISelectLanguages) => {
  const language = useLanguages();
  return (
    <div className={className} style={style}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`ps-3 d-flex cursor-pointer ds-nav-user-link ${
            index !== options.length - 1 && "mb-1"
          } ${option.type === language.geUsedLanguage() && "ds-primary-color"}`}
          onClick={() => language.changeLanguage(option.type)}
        >
          <Avatar className="mt-1" src={option.flagSrc} size="25" round />
          <div style={{ lineHeight: "40px" }} className="ps-3">
            {option.label}
          </div>
        </div>
      ))}
    </div>
  );
};
export default SelectLanguages;
