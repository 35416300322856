import { useEffect, useState } from "react";
import { useTranslation } from "../../../common/customHooks";
import { EInputTypes } from "../../../enums";
import {
  ClearSaveButtons,
  DatePicker,
  Input,
  InputSelect,
  TextArea,
  UploadFile,
} from "../../molecules";
import {
  IInputSelectOption,
  IManageClaimCancelState,
} from "../../../interfaces";
import { INIT_MANAGE_CLAIM_CANCEL_STATE } from "../../../consts";
import { PublicAPI } from "../../../apis";
import { HttpStatusCode } from "axios";
import { Validation } from "../../../common";

const ManageClaimCancel = ({
  type,
  onSubmit,
}: {
  type: "claim" | "cancel";
  onSubmit?: (state: IManageClaimCancelState) => void;
}): JSX.Element => {
  const { tr } = useTranslation();
  const publicApi = new PublicAPI();
  const [reasonOptions, setReasonOptions] = useState<Array<IInputSelectOption>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, setState] = useState<IManageClaimCancelState>(
    INIT_MANAGE_CLAIM_CANCEL_STATE
  );

  useEffect(() => {
    handleFetchDataReasonOptionsAPI();
  }, []);

  const handleFetchDataReasonOptionsAPI = async () => {
    try {
      const response = await publicApi.claimReasons();
      if (response.status === HttpStatusCode.Ok) {
        setReasonOptions(
          (response.data as Array<any>).map(({ claim_type_name, id }) => ({
            label: claim_type_name,
            value: id,
          }))
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12">
          <InputSelect
            label={tr("reason")}
            isObligatoryLabel
            isMulti={false}
            onChange={(options) =>
              setState({ ...state, reasonId: parseInt(options[0]?.value) })
            }
            options={reasonOptions}
          />
        </div>
        <div className="col-md-6 col-12">
          <Input
            label={tr("requestedAmount")}
            isObligatoryLabel
            onChange={(value) =>
              typeof value === "number" &&
              setState({ ...state, requestedAmount: value })
            }
            value={state?.requestedAmount || ""}
            type={EInputTypes.number}
          />
        </div>
        <TextArea
          className="mt-3"
          label={tr("details")}
          isObligatoryLabel
          isQuill
          onChange={(value) => setState({ ...state, details: value })}
          value={state.details || ""}
        />
        {type === "claim" && (
          <>
            <TextArea
              className="mt-3"
              label={tr("request")}
              isObligatoryLabel
              isQuill
              onChange={(value) => setState({ ...state, request: value })}
              value={state.request || ""}
            />
            <DatePicker
              label={tr("incidentDateHour")}
              isObligatoryLabel
              onChange={(date) =>
                date instanceof Date &&
                setState({ ...state, incidentDateHour: date })
              }
              value={state.incidentDateHour || null}
            />
          </>
        )}
        <UploadFile
          className="mt-3"
          label={tr("attachedFile")}
          onUpload={(files) => setState({ ...state, attachedFile: files[0] })}
          files={state.attachedFile ? [state.attachedFile] : []}
        />
        <ClearSaveButtons
          onSave={async () => {
            setIsLoading(true);
            onSubmit && onSubmit(state);
            setIsLoading(false);
          }}
          isDisabled={
            isLoading ||
            !(type === "cancel"
              ? Validation.manageCancelValidation(state)
              : Validation.manageClaimValidation(state))
          }
          isClear
          onClear={() => setState(INIT_MANAGE_CLAIM_CANCEL_STATE)}
        />
      </div>
    </>
  );
};
export default ManageClaimCancel;
