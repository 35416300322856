import { vectorImg } from "../../../../assets";
import { useTranslation } from "../../../../common/customHooks";
import { Button } from "../../../../components";
import { ICreateShipmentPackage } from "../../../../interfaces";

const CreateShipmentPackage = ({
  state,
  onAddPackage,
}: ICreateShipmentPackage) => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 h-100">
      <div className="d-flex mb-3 justify-between">
        <div className="ds-card-page-title">{tr("packageList")}</div>
        <Button
          className="ds-create-package-button"
          icon={<i className="bi bi-plus-lg ds-white-color" />}
          textClassName="ps-2"
          onClick={onAddPackage}
          text={tr("addBoxesOrPackage")}
        />
      </div>
      {state.packageList.length === 0 ? (
        <>
          <div className="d-flex justify-center">
            <div className="ds-package-not-found-box d-flex justify-center mb-2">
              <img
                className="my-3"
                style={{ height: "100px" }}
                alt="imlg"
                src={vectorImg}
              />
            </div>
          </div>
          <div
            style={{ fontSize: "14px", lineHeight: "29px" }}
            className="d-flex justify-center ds-font-poppins"
          >
            {tr("noPackageFound")}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CreateShipmentPackage;
