import { useTranslation } from "../../../../common/customHooks";
import { PrevNextButton } from "../../../../components";
import { EPrevNextType } from "../../../../enums";
import { ICreateShipmentFooter } from "../../../../interfaces";

const CreateSimulationFooter = ({
  index,
  maxIndex,
  onChange,
}: ICreateShipmentFooter): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div className="w-100 pb-2 ds-px-res-3 pt-1">
      <div
        className={`d-flex ${index !== 0 ? "justify-between" : "justify-end"} `}
      >
        {index !== 0 && (
          <PrevNextButton
            isDisabled={index === 0}
            text={tr("previous")}
            onClick={() => onChange(index - 1)}
            type={EPrevNextType.prev}
          />
        )}
        {index !== maxIndex && (
          <PrevNextButton
            isDisabled={index === maxIndex}
            text={tr("continue")}
            onClick={() => onChange(index + 1)}
            type={EPrevNextType.next}
          />
        )}
      </div>
    </div>
  );
};
export default CreateSimulationFooter;
