import { IPageProps } from "../../../interfaces";

const NavIconLayout = ({
  children,
  className,
  style,
}: IPageProps): JSX.Element => {
  return (
    <div
      className={`${className || ""} ds-bg-secondary d-flex justify-center`}
      style={{ width: "30px", height: "30px", borderRadius: "50%", ...style }}
    >
      {children}
    </div>
  );
};
export default NavIconLayout;
