import { useState } from "react";
import { ManageProfileTemplate } from "../../templates";
import {
  INIT_COMMON_MANAGE_PROFILE_STATE,
  INIT_EDIT_PROFILE_STATE,
  INIT_PREFERENCE_STATE,
  INIT_SECURITY_PASSWORD_STATE,
} from "../../consts";
import {
  ICommonManageProfileState,
  IDevice,
  IEditProfileState,
  IPreferenceState,
  ISecurityPasswordState,
} from "../../interfaces";

const ManageProfile = (): JSX.Element => {
  const [commonManageProfileState, setCommonManageProfileState] =
    useState<ICommonManageProfileState>(INIT_COMMON_MANAGE_PROFILE_STATE);
  const [editProfileState, setEditProfileState] = useState<IEditProfileState>({
    ...INIT_EDIT_PROFILE_STATE,
  });
  const [securityPasswordState, setSecurityPasswordState] =
    useState<ISecurityPasswordState>(INIT_SECURITY_PASSWORD_STATE);
  const [preferenceState, setPreferenceState] = useState<IPreferenceState>(
    INIT_PREFERENCE_STATE
  );
  const [deviceListState] = useState<Array<IDevice>>([]);
  const handleSubmitEditProfile = () => {};
  const handleSubmitSecurityPassword = () => {};
  const handleSubmitPreference = () => {};
  const handleSignoutDevice = (index: number, isMyDevice: boolean) => {};
  const handleSignoutFromAllDevices = () => {};
  return (
    <ManageProfileTemplate
      commonState={commonManageProfileState}
      onChangeCommonState={setCommonManageProfileState}
      editProfileState={editProfileState}
      onChangeEditProfileState={setEditProfileState}
      securityPasswordState={securityPasswordState}
      onChangeSecurityPasswordState={setSecurityPasswordState}
      preferenceState={preferenceState}
      onChangePrefenceState={setPreferenceState}
      onSubmitEditProfile={handleSubmitEditProfile}
      onSubmitPreference={handleSubmitPreference}
      onSubmitSecurityPassword={handleSubmitSecurityPassword}
      deviceList={deviceListState}
      onSignOutDevice={handleSignoutDevice}
      onSignOutFromAllDevices={handleSignoutFromAllDevices}
    />
  );
};
export default ManageProfile;
