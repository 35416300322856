import { AxiosInstance, AxiosResponse } from "axios";
import { TokenEnum } from "../../enums";
import { getApiAxios } from "../../common/functions/GetApiAxios";
import { handleHeaders } from "../../common/functions/HandleHeaders";
import Config from "../../common/config/Config";

class PublicAPI {
  private _api: AxiosInstance;

  constructor() {
    this._api = getApiAxios({
      baseUrl: Config.getInstance().API_URL + "/public",
      headers: handleHeaders(TokenEnum.noToken),
      isRefreshToken: false,
    });
  }

  trackShipment(shipmentNumber: string): Promise<AxiosResponse> {
    return this._api.get(`track-shipment-general/${shipmentNumber}`);
  }

  trackShipmentSender(shipmentNumber: string): Promise<AxiosResponse> {
    return this._api.get(`track-shipment-sender/${shipmentNumber}`);
  }

  trackShipmentReceiver(shipmentNumber: string): Promise<AxiosResponse> {
    return this._api.get(`track-shipment-receiver/${shipmentNumber}`);
  }

  trackShipmentHistory(shipmentNumber: string): Promise<AxiosResponse> {
    return this._api.get(`shipment-tracking/${shipmentNumber}`);
  }

  claimReasons(): Promise<AxiosResponse> {
    return this._api.get("claim-reasons", {
      headers: handleHeaders(TokenEnum.accessToken),
    });
  }
}
export default PublicAPI;
