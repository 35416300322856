import { Card } from "..";
import { useTranslation } from "../../../common/customHooks";
import { EInputTypes } from "../../../enums";
import { IDangerousCard } from "../../../interfaces";
import { DeleteIcon } from "../../icons";
import { Input, InputSelect } from "../../molecules";

const DangerousCard = ({
  className,
  index,
  onDelete,
  selectedValue,
  numValue,
  onChangeNumValue,
  selectOptions,
  onChangeSelect,
}: IDangerousCard): JSX.Element => {
  const { tr } = useTranslation();
  const dgIndex = `${tr("dg")} ${index + 1}`;
  const inputSelectlabel = `${dgIndex} ${tr("class")}`;
  const inputLabel = `${dgIndex} ${tr("unNum")}`;
  return (
    <Card style={{ minHeight: "0px" }} className={className}>
      <div className="row">
        <div className="col-md-4 col-12">
          <InputSelect
            label={inputSelectlabel}
            isMulti={false}
            value={selectOptions.find(
              (option) => option.value === selectedValue
            )}
            onChange={(option: any) => onChangeSelect(option?.value)}
            options={selectOptions}
          />
        </div>
        <div className="col-md-4 col-12">
          <Input
            label={inputLabel}
            isObligatoryLabel
            type={EInputTypes.number}
            onChange={(value) =>
              typeof value === "number" && onChangeNumValue(value)
            }
            value={numValue}
          />
        </div>
        <div className="col-md-4 col-12">
          <div className="d-flex justify-end">
            <DeleteIcon onDelete={onDelete} />
          </div>
        </div>
      </div>
    </Card>
  );
};
export default DangerousCard;
