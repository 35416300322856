import { ISelectType } from "../../../interfaces";
import { Label } from "../../atoms";

const SelectAuthType = ({
  className,
  style,
  options,
  selectedIndex,
  onChange,
  label,
  isObligatoryLabel,
  selectedOptionClassName,
  unselectedOptionClassName,
  selectedOptionStyle,
  unSelectedOptionStyle,
}: ISelectType) => {
  const persentage = `${parseInt(`${100 / options.length}`)}%`;
  return (
    <div style={style} className="row">
      {label && (
        <div className="col-12 col-md-3">
          <Label
            className="me-4 pt-2 ds-mb-xs-2"
            text={label}
            isObligatory={isObligatoryLabel === true}
          />
        </div>
      )}
      <div className={`${label ? "col-12 col-md-4" : "w-100"}`}>
        <div
          className={`ds-select-auth-type-layout d-flex ${!label && "w-100"} ${
            className || ""
          }`}
        >
          {options.map((option, index) => (
            <div
              key={option.value}
              style={{
                width: persentage,
                ...(index === selectedIndex
                  ? selectedOptionStyle
                  : unSelectedOptionStyle),
              }}
              onClick={() => onChange(index, options[index].value)}
              className={`d-flex justify-center px-2 pt-2 ${
                index === selectedIndex
                  ? `ds-selected-auth-type ${selectedOptionClassName || ""}`
                  : `ds-not-selected-auth-type ${
                      unselectedOptionClassName || ""
                    }`
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SelectAuthType;
