import Avatar from "react-avatar";
import { IUserAvatar } from "../../../interfaces";
import { usePrivateAppData } from "../../../common/customHooks";

const UserAvatar = ({ onClick, size }: IUserAvatar): JSX.Element => {
  const context = usePrivateAppData()
  const fullName = `${context?.state?.firstName}  ${context?.state?.lastName}`;
  return (
    <Avatar
      size={size || "88"}
      onClick={onClick}
      src={context?.state?.avatarSrc}
      name={fullName}
      round
    />
  );
};
export default UserAvatar;
