import { useEffect, useState } from "react";
import { Data } from "../../common";
import { useCreateSimulation, useTranslation } from "../../common/customHooks";
import { Button, Card, PageTitle, Stepper } from "../../components";
import { EButtonType } from "../../enums";
import {
  ICreateSimulationTemplate,
  IMultiInterfaceUsedButton,
} from "../../interfaces";
import { CreateSimulationFooter } from "./forms";

const CreateSimulationTemplate = (
  payload: ICreateSimulationTemplate
): JSX.Element => {
  const { onSubmit } = payload;
  const { tr } = useTranslation();
  const createSimulation = useCreateSimulation();
  const [pageIndex, setPageIndex] = useState<number>(0);

  const templatePages = createSimulation.getTemplatePage(payload);

  const ButtonSubmit = ({
    isMobile,
  }: IMultiInterfaceUsedButton): JSX.Element => {
    return (
      <div className="d-flex justify-end">
        <Button
          className="bg-transparent ds-save-simulation-btn"
          icon={<i className="bi bi-folder-fill" />}
          onClick={onSubmit}
          textClassName="ps-2"
          text={isMobile ? tr("save") : tr("saveSimulation")}
          type={EButtonType.secondary}
        />
      </div>
    );
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-2">
        <div className="col-8 col-md-12">
          <PageTitle className="mb-1" title={tr("createSimulation")} />
        </div>
        <div className="col-4 d-md-none d-xs-block">
          <ButtonSubmit isMobile />
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 col-12 ds-px-xs-0">
          <Stepper
            elements={Data.getCreaterShipmentStepperData(tr)}
            selectedIndex={pageIndex}
            onChange={(index) => setPageIndex(index)}
          />
        </div>
        <div className="col-md-2 col-12 d-none d-md-block">
          <ButtonSubmit isMobile={false} />
        </div>
      </div>
      <Card
        footer={
          <CreateSimulationFooter
            index={pageIndex}
            maxIndex={templatePages.length - 1}
            onChange={(index) => setPageIndex(index)}
          />
        }
        className="mt-2"
        isScroll
      >
        {templatePages[pageIndex]}
      </Card>
    </div>
  );
};
export default CreateSimulationTemplate;
