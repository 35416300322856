import DatePicker from "react-date-picker";
import { Label } from "../../atoms";
import { Checkbox, TimePicker } from "../../molecules";
import { IFromToMoreDateInfo } from "../../../interfaces";
import { useTranslation } from "../../../common/customHooks";

const FromToMoreDateInfo = ({
  className,
  style,
  label,
  date,
  time,
  isFlexible,
  isUseFlexible,
  onChangeTime,
  onChangeflexible,
  onChangeDate,
  isObligatoryLabel,
}: IFromToMoreDateInfo): JSX.Element => {
  const { tr } = useTranslation();
  return (
    <div style={style} className={`${className || ""} w-100`}>
      <div className={`d-flex mb-2 ${isUseFlexible && "justify-between"}`}>
        {label && (
          <Label text={label} isObligatory={isObligatoryLabel === true} />
        )}
        {isUseFlexible && (
          <Checkbox
            isChecked={isFlexible === true}
            onChange={(checked) =>
              onChangeflexible && onChangeflexible(checked)
            }
            text={tr("flexible")}
          />
        )}
      </div>
      <DatePicker
        className="mb-2"
        onChange={(date: any) => onChangeDate(date)}
        value={date}
      />
      <TimePicker onChange={onChangeTime} value={time} />
    </div>
  );
};
export default FromToMoreDateInfo;
