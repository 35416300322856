import {
  Button,
  DeviceActivity,
  InfoText,
  PageTitle,
} from "../../../../components";
import { EButtonType } from "../../../../enums";
import { IActivities } from "../../../../interfaces";
import { useModal, useTranslation } from "../../../../common/customHooks";

const Activities = ({
  deviceList,
  onSignOutDevice,
  onSignOutFromAllDevices,
}: IActivities): JSX.Element => {
  const { tr } = useTranslation();
  const modal = useModal();
  return (
    <div className="w-100 h-100">
      <PageTitle
        titleStyle={{ fontSize: "16px" }}
        className="mb-3"
        title={tr("connectedDevices")}
      />
      <div className="row">
        <div className="col-12 col-md-8">
          <InfoText className="mb-3" title={tr("activitiesInfo")} />
          <div className="w-100 mb-3">
            {deviceList.map((device, index) => (
              <DeviceActivity
                key={index}
                className="mb-2"
                ipAdress={device.ipAdress}
                isMyDevice={device.isMyDevice}
                lastActivity={device.lastActivity}
                localistation={device.localistation}
                name={device.name}
                type={device.type}
                onDelete={() => onSignOutDevice(index, device.isMyDevice)}
              />
            ))}
          </div>
          {deviceList.length > 0 && (
            <div className="d-flex justify-end">
              <Button
                className="ds-activities-logout-btn"
                onClick={() =>
                  modal.open({
                    children: (
                      <div className="d-flex justify-center ds-manage-profile-modal-text">
                        {tr("signOutAllDevicesSubmit")}
                      </div>
                    ),
                    options: {
                      onSubmit: () => {
                        onSignOutFromAllDevices();
                      },
                      isFooterSubmit: true,
                    },
                  })
                }
                text={tr("logoutFromAllDevices")}
                type={EButtonType.secondary}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Activities;
