import { useState } from "react";
import { INIT_CREATE_DOCUMENT_STATE } from "../../../consts";
import { ICreateDocumentState } from "../../../interfaces";
import { useTranslation } from "../../../common/customHooks";
import { DatePicker, Input, InputSelect, UploadFile } from "../../molecules";

const CreateDocument = (): JSX.Element => {
  const { tr } = useTranslation();
  const [state, setState] = useState<ICreateDocumentState>(
    INIT_CREATE_DOCUMENT_STATE
  );
  const handleSubmit = async () => {};
  return (
    <div className="w-100 h-100">
      <div className="mb-4">
        <div className="row mb-2">
          <div className="col-12 col-md-6">
            <InputSelect
              label={tr("documentType")}
              isObligatoryLabel
              isMulti={false}
              value={state.documentTypesOptions.find(
                (type) => type.value === state.type
              )}
              options={state.documentTypesOptions}
              onChange={(event: any) =>
                setState({ ...state, type: event?.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              label={tr("documentName")}
              isValidate
              isObligatoryLabel
              value={state.name}
              onChange={(value) =>
                typeof value === "string" && setState({ ...state, name: value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <DatePicker
              label={tr("uploadDate")}
              isObligatoryLabel
              value={state.uploadedDate}
              onChange={(value: any) =>
                setState({ ...state, uploadedDate: value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <DatePicker
              label={tr("issueDate")}
              isObligatoryLabel
              value={state.issueDate}
              onChange={(value: any) =>
                setState({ ...state, issueDate: value })
              }
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <UploadFile
          label={tr("simpleUploadFile")}
          isObligatoryLabel
          isMulti
          files={state.files}
          onUpload={(uploadFiles) => {
            let files = state.files;
            uploadFiles.map((file) => files.push(file));
            setState({ ...state, files });
          }}
        />
      </div>
    </div>
  );
};
export default CreateDocument;
