import { Link } from "react-router-dom";
import { Data } from "../../common";
import { PrivacyPolicy } from "../../components";
import { IAuthLayout } from "../../interfaces/layouts";
import { logoWhite } from "../../assets";

const AuthLayout = ({ className, children, type }: IAuthLayout) => {
  const authLayoutClassName = Data.getAuthLayoutClasssName(type);
  return (
    <div style={{ minHeight: "100vh" }} className="w-100 ds-auth-layout">
      <Link className="d-flex ds-auth-logo" to="/login">
        <img alt="app-logo" className="ds-auth-img" src={logoWhite} />
      </Link>
      <div className="d-flex justify-center">
        <div
          className={`${className || ""} w-100 h-100 ${authLayoutClassName}`}
        >
          {children}
          <PrivacyPolicy className="mt-3" href="/privacy-policy" />
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
