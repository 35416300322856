import { ToastContainer } from "react-toastify";
import { useLanguages, useTheme } from "../../../common/customHooks";

const Toast = () => {
  const { isDarkMode } = useTheme();
  const { isRtl } = useLanguages();
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={isRtl}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={!isDarkMode ? "light" : "dark"}
    />
  );
};
export default Toast;
