import { Outlet } from "react-router-dom";
import { PrivateAppLayout } from "../../layouts";
import { IPrivateRoutes } from "../../interfaces";
import { JoyrideProvider, PrivateAppProvider } from "../../providers";

const PrivateRoute = ({ auth }: IPrivateRoutes): JSX.Element => {
  return (
    <PrivateAppProvider>
      <JoyrideProvider>
        <PrivateAppLayout auth={auth}>
          <Outlet />
        </PrivateAppLayout>
      </JoyrideProvider>
    </PrivateAppProvider>
  );
};

export default PrivateRoute;
