import { IKeys } from "../../interfaces";

const lang: IKeys = {
  helloWorld: "Hello world",
  uploadFile: "Click or drag file to this area to upload",
  loginWithApple: "Login with apple",
  loginWithGoogle: "Login with google",
  login: "Login",
  startTheAdventure: "Start the adventure",
  signUp: "Sign up",
  createYourAccount: "Create your account in just a couple of steps",
  forgotPassword: "Forgot password",
  resetPassword: "Reset password",
  youDontHaveAccount: "You don't have an account?",
  youHaveAccount: "You have an account ?",
  registerFree: "Register free",
  changePassword: "Change password",
  rememberMe: "Remember me",
  isForgotPassword: "Forgot password ?",
  doYouWantToBeCarrier: "Do you want to be carrier ?",
  joinUs: "Join us here",
  enterYouEmail: "Enter your email",
  enterYourPassword: "Enter your password",
  iAgreeToAll: "I agree to all",
  emailAdress: "Email Adress",
  firstName: "First name",
  lastName: "Last name",
  email: "E-mail",
  userName: "Username",
  country: "Country",
  city: "City",
  password: "Password",
  enterYourCity: "Enter your city",
  enterYourCountry: "Enter your country",
  enterYourUsername: "Enter your username",
  enterYourFirstName: "Enter your First name",
  enterYourLastName: "Enter your Last name",
  and: "And",
  terms: "Terms",
  cgu: "Cgu",
  privacyPolicy: "Privacy Policy",
  allRightsReserved: "All rights reserved",
  confirmedPassword: "Confirm Password",
  resetPasswordSubTitle:
    "Your New password must be different  from previous used passwords.",
  resetEmailSent: "Reset email sent",
  weHaveSentResetEmail:
    "We have sent a password recover instructions to your email",
  registerASCustomer: "Register as customer",
  registerAsCarrier: "Register as carrier",
  or: "Or",
  createSimulation: "Create Simulation",
  myRefNumber: "My reference number / Customer reference",
  transportationMode: "Transportantion Mode",
  shipmentType: "Shipment Type",
  selectInterCom: "Select Intercom",
  intercom: "Intercom",
  addSenderCustomer: "Add Sender /Customer Adress",
  selectLocation: "Select Location",
  instruction: "Instruction",
  location: "Location",
  clear: "Clear",
  save: "Save",
  documentType: "Document Type",
  documentName: "Document Name",
  uploadDate: "Upload Date",
  issueDate: "Issue Date",
  simpleUploadFile: "Upload File",
  shCode: "SH Code",
  quantity: "Quantity",
  section: "Section",
  chapter: "Chapter",
  wUnit: "W.Unit",
  weight: "Weight",
  length: "Length",
  vUnit: "V.Unit",
  width: "Width",
  declaredValue: "Declared value",
  insuredValue: "Insured value",
  currency: "Currency",
  description: "Description",
  phone: "Phone",
  adress: "Adress",
  information: "Information",
  zipCode: "Zip Code",
  confidentiality: "Confidentiality",
  conditions: "Conditions",
  cookies: "Cookies",
  senderDetails: "Sender Details",
  senderAdress: "Sender adress",
  senderContact: "Sender contact",
  senderInformation: "Sender information",
  isCustomer: "IS Customer",
  isOriginPickup: "Is Origin / Is pickup",
  recipientDetail: "Recipient Details",
  recipientAdress: "Recipient adress",
  recipientContact: "Recipirnt contact",
  recipientInformation: "Recipient information",
  isDestinationDelivery: "Is Destination / Is Delivery",
  firstNotify: "Notify 1",
  firstNotifyAdress: "Notify 1 Adress",
  firstNotifyContact: "Notify 1 Contact",
  firstNotifyInformation: "Notify 1 Information",
  secondNotify: "Notify 2",
  secondNotifyAdress: "Notify 2 Adress",
  secondNotifyContact: "Notify 2 Contact",
  secondNotifyInformation: "Notify 2 Information",
  originPickupDeposit: "Origin / Pickup / Deposit",
  pickUpAdress: "Pick-up Adress",
  pickUpContact: "Pick-up Contact",
  pickUpInformation: "Pick-up Information",
  isDeposit: "Is deposit",
  destinationDelivery: "Destination / Delivery",
  deliveryAdress: "Delivery Adress",
  deliveryContact: "Delivery Contact",
  deliveryInformation: "Delivery Information",
  agent: "Agent",
  agentAdress: "Agent Adress",
  agentContact: "Agent Contact",
  agentInformation: "Agent Information",
  servicesOption: "Services Option",
  temperatureControlled: "Temperature Controlled",
  insurance: "Insurance",
  stackable: "Stackable",
  signature: "Signature",
  dutyTaxPaid: "Duty Tax Paid",
  dangerousGoods: "Dangerous Goods (ADR)",
  ramp: "Ramp",
  customClearance: "Custom Clearance",
  securityStatus: "Security Status",
  prooveOfDelivery: "Proove Of Delivery",
  additionalServices: "Additional Services",
  origin: "Origin",
  collect: "Collect",
  exportCustoms: "Export Customs",
  destination: "Destination",
  lastMileDelivery: "Last Mile Delivery",
  importCustoms: "Import Customs",
  importHandeling: "Import Handeling",
  addAdditionalService: "Add Additional Service",
  category: "Category",
  services: "Services",
  unit: "Unit",
  minTemp: "Min Temperature",
  maxTemp: "Max Temperature",
  activeCooling: "Active Cooling",
  temperature: "Temperature",
  continue: "Continue",
  previous: "Previous",
  generalInformation: "General information",
  fromTo: "From/To",
  service: "Service",
  package: "Package",
  document: "Document",
  addDocument: "Add Document",
  addItem: "Add Item",
  documentList: "Document List",
  paginateNext: "Next >",
  paginatePrevious: "< previous",
  documentFile: "Document File",
  actions: "Actions",
  simulationList: "Simulation List",
  tracking: "Tracking",
  date: "Date",
  pickUpDate: "Pickup Date",
  sender: "Sender",
  senderCompany: "Sender Company",
  origineDeparture: "Origine / Departure",
  receiver: "Receiver",
  receiverCompany: "Receiver Company",
  arrival: "Arrival",
  createShipment: "Create Shipment",
  simulationResults: "Simulation Results",
  showMore: "Show more",
  editRequest: "Edit Request",
  between: "Between",
  referenceId: "Reference Id",
  requestForQuotation: "Request For Quotation",
  companyName: "Company name",
  deliveryTime: "Delivery time",
  serviceAmount: "Service amount",
  statut: "Statut",
  additionalService: "Additional service",
  totalAmount: "Total amount",
  billTo: "Bill to",
  billToInformation: "Bill to information",
  moreInformation: "More information",
  flexible: "Flexible",
  readiness: "Readiness",
  pickUpDeposit: "Pickup / Deposit",
  boarding: "Boarding",
  delivery: "Delivery",
  add: "Add",
  dg: "DG",
  class: "Class",
  unNum: "UN N°",
  saveSimulation: "Save Simulation",
  areYouSurToLeave: "Are you sure you want to leave?",
  dashboard: "Dashboard",
  bookingShipment: "Booking Shipment",
  shipmentList: "Shipment List",
  settings: "Settings",
  myProfile: "My Profile",
  myOffice: "My Office",
  manageUsers: "Manage Users",
  collapsePanel: "Collapse panel",
  packageList: "Package List",
  addBoxesOrPackage: "Add boxes or packages",
  simulationOnMap: "Simulation On Map",
  person: "Person",
  company: "Company",
  companyNameUper: "Company Name",
  enterYourCompanyName: "Enter your company name",
  vatCode: "VAT Code",
  enterYourVatCode: "Enter your VAT code",
  fieldRequired: "The field is required",
  emailFieldRequired:
    "The email field is required and must be a valid email form !",
  passwordFieldRequired:
    "The password field is required and should be strong !",
  confirmPasswordValidation:
    "The confirm Password field is both mandatory and meets the same strength criteria as the original password.",
  confirmPassword: "Confirm password",
  enterYourConfirmPassword: "Enter your confirm password",
  generatePassword: "Generate password",
  phoneNumberValidatorError:
    "The phone number field is required and should be valid",
  filterRequests: "Filter Requests",
  logOut: "Log Out",
  userSettings: "User Settings",
  prefereneces: "Preferences",
  security: "Security",
  notifications: "Notifications",
  french: "French",
  english: "English",
  edit: "Edit",
  delete: "Delete",
  manageProfile: "Manage profile",
  editProfile: "Edit profile",
  preference: "Preference",
  notification: "Notification",
  activities: "Activities",
  documents: "Documents",
  profilePicture: "Profile picture",
  uploadNewPicture: "Upload new picture",
  weSupportImages: "We support PNGs, JPEGs and GIFs under 10MB",
  editAvatar: "Edit Avatar",
  chooseAvatarImage: "Choose avatar image !",
  userLevel: "User level",
  userType: "User type",
  gender: "Gender",
  function: "Function",
  fixPhone: "Fixe phone",
  mobilePhone: "Mobile phone",
  whatsappPhone: "WhatsApp Number",
  saveChange: "Save change",
  darkMode: "Dark mode",
  lightMode: "Light mode",
  create: "Create",
  oops: "Oops",
  passwordTokenExpired: "Your password reset token has expired.",
  currentPassword: "Current password",
  newPassword: "New password",
  securityPasswordConditions:
    "8 characters or longer. Combine upper and lowercase letters and numbers.",
  languages: "Languages",
  timeZone: "TimeZone",
  newsLetterSubscriber: "Newsletter Subscriber",
  connectedDevices: "Connected devices",
  activitiesInfo:
    "if necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.",
  thisDevice: "This device",
  signOut: "Sign Out",
  lastActivityJustNow: "Last Activity Just now",
  lastActive: "Last active",
  ago: "Ago",
  minutes: "Minute(s)",
  hours: "Hours(s)",
  days: "Days(s)",
  logoutFromAllDevices: "Log Out from all devices",
  cancel: "Cancel",
  submit: "Submit",
  signOutAllDevicesSubmit: "Sign out from all your devices? Are you Sure ?",
  requestDetail: "Request detail",
  decline: "Decline",
  accept: "Accept",
  referenceNumber: "Reference number",
  transportMode: "Transport mode",
  manageOffice: "Manage Office",
  general: "General",
  branche: "Branche",
  partner: "Partner",
  adresses: "Adresses",
  contact: "contact",
  companyPicture: "Company picture",
  companyLegalName: "Company legal name",
  companyCommercialName: "Company commercial name",
  companyForm: "Company form",
  companyCapital: "Company capital",
  companyRegistrationNumber: "Company registration number",
  companyRegister: "Company register",
  companyVatCode: "Company VAT code",
  companyeoriCode: "Company EORI/IOSS code",
  companyActivityCode: "Company activity code",
  companyCountry: "Company country",
  selectCountry: "Select country...",
  exampleEmail: "example@email.com",
  companyEmail: "Company email",
  companyPhone: "Company phone",
  recipientDetails: "Recipient details",
  recipient: "Recipient",
  arabic: "Arabic",
  serviceOptions: "Service options",
  tempUnit: "Temp unit",
  tempMin: "Temp min",
  tempMax: "Temp max",
  addionalServices: "Addional services",
  packageName: "Package Name",
  packageType: "Package Type",
  item: "Item",
  height: "Height",
  customerType: "Customer Type",
  requestList: "Request list",
  quotationList: "Quotation list",
  manageOtherUsers: "Manage others users",
  manageUserProfile: "Manage user profile",
  simpleManageOffice: "Manage office",
  simpleRequestList: "Request list",
  simpleQuotationList: "Quotation list",
  simpleShipmentList: "Shipment list",
  simpleCreateSimulation: "Create simulation",
  simpleSimulationList: "Simulation list",
  adressesList: "Adresses list",
  addAnotherAdress: "Add another adress",
  until: "Until",
  editAdressCompany: "Edit Address Company",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  adressesType: "Adresses Type",
  openningDay: "Openning Day",
  totalShipments: "Total Shipments",
  deliveredShipments: "Delivered Shipments",
  rejected: "Rejected",
  shippingSpend: "Shipping Spend",
  shipmentMetrics: "Shipments Metrics",
  totalDelivering: "Total delivering",
  totalPending: "Total pending",
  amount: "Amount",
  seeAll: "See all",
  recentQuotation: "Recent quotation",
  trackingId: "Tracking ID",
  status: "Status",
  pickupDate: "Pickup Date",
  simulationSummary: "Simulation summary",
  noPackageFound:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet est id quam tincidunt volutpat.",
  completeYourProfile: "Complete your profile",
  completeProfileDescribtion:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet tortor laoreet",
  profileCompletionIsAt: "Your Profile completion is at ",
  internalServerError: "Internal Server Error !",
  selectYourCountry: "Select your country",
  selectYourCity: "Select your city",
  filter: "Filter",
  refrenceId: "ID Reference",
  showShipment: "Show Shipment",
  shipment: "Shipments",
  transaction: "Transaction",
  claim: "Claim",
  pageNotFound: "Page not found",
  goBack: "Go Back",
  createQuotation: " Create Quotation",
  totalAmountExcl: "Total amount(excl.tax)",
  totalTax: "Total tax",
  addPackages: "Add packages",
  addDocuments: "Add documents",
  addServices: "Add services",
  invalidEmailOrPassword: "Email ou mot de passe invalide",
  name: "Name",
};
export default lang;
