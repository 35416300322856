import { useEffect, useState } from "react";
import { useDrawer, useTranslation } from "../../../../common/customHooks";
import {
  Button,
  ClaimCancelItem,
  InfinityScroll,
  ManageClaimCancel,
  ToggledCard,
} from "../../../../components";
import { INIT_CANCEL_LIST_STATE } from "../../../../consts";
import { ICancelListState } from "../../../../interfaces";
import { RequestAPI } from "../../../../apis";
import { HttpStatusCode } from "axios";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";

const RequestCancel = ({
  id,
  isCanceledRequest,
  onCancelRequest,
}: {
  id?: number;
  isCanceledRequest: boolean;
  onCancelRequest: () => void;
}): JSX.Element => {
  const { tr } = useTranslation();
  const drawer = useDrawer();
  const requestApi = new RequestAPI();
  const [state, setState] = useState<ICancelListState>(INIT_CANCEL_LIST_STATE);

  useEffect(() => {
    if (id) {
      handleFetchDataAPI(id);
    }
  }, [state?.pageNumber]);

  const handleFetchDataAPI = async (id: number) => {
    try {
      const response = await requestApi.getRequestCancelsPagination({
        ...state,
        requestId: id,
      });

      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setState({
          ...state,
          cancels: state.cancels.concat(
            (data?.data as Array<any>).map((data) =>
              DataFetchAPI.fetchCancelModel(data)
            )
          ),
          paginationLength: data?.last_page,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateCancel = async () => {
    try {
      //api
      drawer.close();
      onCancelRequest();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ToggledCard title={tr("cancel")} isBlocked>
      <div className="d-flex justify-end mb-2">
        {!isCanceledRequest && (
          <Button
            style={{ borderRadius: "4px", width: "100px", height: "28px" }}
            icon={<i className="bi bi-plus ds-white-color" />}
            text={tr("newCancel")}
            onClick={() =>
              drawer.open({
                title: tr("addCancel"),
                children: (
                  <ManageClaimCancel
                    type="cancel"
                    onSubmit={handleCreateCancel}
                  />
                ),
              })
            }
          />
        )}
      </div>
      <InfinityScroll
        onNext={() => setState({ ...state, pageNumber: state.pageNumber + 1 })}
        {...state}
      >
        {state.cancels.length > 0 &&
          state.cancels.map((cancel) => (
            <ClaimCancelItem type="cancel" className="mb-2" {...cancel} />
          ))}
      </InfinityScroll>
    </ToggledCard>
  );
};
export default RequestCancel;
