import { ICommonComponent } from "../../../interfaces";

const Spinner = ({ size }: ICommonComponent & { size?: number }) => {
  const commonSize = `${size || 35}px`;
  return (
    <div
      style={{ width: commonSize, height: commonSize }}
      className="spinner"
    />
  );
};
export default Spinner;