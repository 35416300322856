import { useState } from "react";
import { ToggledIcon } from "../toggledCard/ToggledCard";
import { IToggledLine } from "../../../interfaces";
import { Button } from "../../molecules";
import { useTranslation } from "../../../common/customHooks";

const ToggledLine = ({
  className,
  style,
  title,
  children,
  isAdd,
  buttonAddTitle,
  onAdd,
}: IToggledLine): JSX.Element => {
  const { tr } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <div style={style} className={`w-100 my-2 ${className || ""}`}>
      <div className="d-flex justify-between ds-primary-color px-2">
        <div
          style={{ fontSize: "16x", fontWeight: "600" }}
          className="ds-font-poppins d-flex"
        >
          <div
            className="cursor-pointer"
            style={{ minWidth: isAdd ? "100px" : "auto" }}
            onClick={() => setIsShow(!isShow)}
          >
            {title}
          </div>
          {isAdd && (
            <Button
              className="ms-2 ds-toggled-line-btn-add"
              icon={<i className="bi bi-plus ds-white-color" />}
              text={buttonAddTitle || tr("add")}
              onClick={onAdd}
            />
          )}
        </div>
        <ToggledIcon isShow={isShow} onToggled={() => setIsShow(!isShow)} />
      </div>
      {isShow && (
        <div className="mx-3 my-3 ds-toggled-line-content p-3">{children}</div>
      )}
    </div>
  );
};
export default ToggledLine;
