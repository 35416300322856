import { IRequestCard } from "../../../interfaces";

const RequestCard = ({
  date,
  className,
  style,
  onClick,
  isSelected,
}: IRequestCard): JSX.Element => {
  return (
    <div
      style={style}
      className={`${className || ""} cursor-pointer ds-request-card-body ${
        isSelected ? "ds-selected-request-card" : "ds-unselected-request-card"
      }`}
      onClick={onClick}
    >
      ...
    </div>
  );
};
export default RequestCard;
