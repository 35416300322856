import { useNavigate } from "react-router-dom";
import { HeaderTitleSeeAll } from "../../../../components";
import { Data } from "../../../../common";
import {
  IDashboardRecentQuotation,
  IQuotationModel,
} from "../../../../interfaces";
import { useTranslation } from "../../../../common/customHooks";
import { useEffect, useMemo, useState } from "react";
import { HttpStatusCode } from "axios";
import { QuotationAPI } from "../../../../apis";
import { DataFetchAPI } from "../../../../common/data/DataFetchApi";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const RecentQuotation = ({
  style,
  className,
}: IDashboardRecentQuotation): JSX.Element => {
  const navigate = useNavigate();
  const { tr } = useTranslation();
  const quotationApi = new QuotationAPI();
  const [quotations, setQuotations] = useState<Array<IQuotationModel>>([]);

  const data = useMemo(
    () =>
      quotations.map(({ carrier, price }) => ({
        companyName: carrier,
        deliveryTime: "?",
        amount: price,
      })),
    [quotations]
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => Data.getDashboardRecentQuotationDataColumns(tr),
    [tr]
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
  });

  useEffect(() => {
    handleFetchDataAPI();
  }, []);

  const handleFetchDataAPI = async () => {
    try {
      const response = await quotationApi.getQuotationsPagination({
        pageNumber: 1,
        perPage: 4,
      });
      if (response.status === HttpStatusCode.Ok) {
        const { data } = response.data;
        setQuotations(
          (data?.data as Array<any>).map((data) =>
            DataFetchAPI.fetchQuotationItemModel(data)
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        height: "320px",
        borderRadius: "10px",
        overflow: "hidden",
        ...style,
      }}
      className={`w-100 ds-bg-white py-2 ${className || ""}`}
    >
      <HeaderTitleSeeAll
        className="mb-2"
        onClick={() => navigate("/quotation-list")}
        title={tr("recentQuotation")}
      />
      <MaterialReactTable table={table} />
    </div>
  );
};
export default RecentQuotation;
